const globalProductsActions = {
  // API Actions
  FETCH_GLOBAL_PRODUCTS: "/koala-cms/globalProducts/FETCH_GLOBAL_PRODUCTS",
  FETCH_GLOBAL_PRODUCT: "/koala-cms/globalProducts/FETCH_GLOBAL_PRODUCT",
  UPDATE_GLOBAL_PRODUCT: "koala-cms/globalProducts/UPDATE_GLOBAL_PRODUCT",
  UPDATE_GLOBAL_PRODUCT_IMAGE:
    "koala-cms/globalProducts/UPDATE_GLOBAL_PRODUCT_IMAGE",
  FETCH_ALLERGIES: "/koala-cms/globalProducts/FETCH_ALLERGIES",

  // Cosmetic Actions
  STOP_LOADING_STATE: "koala-cms/globalProducts/STOP_LOADING_STATE",
  CLEAR_GLOBAL_PRODUCT_DETAIL:
    "/koala-cms/globalProducts/CLEAR_GLOBAL_PRODUCT_DETAIL",
  ATTEMPT_GLOBAL_PRODUCT_LIST_MODIFICATION:
    "koala-cms/globalProducts/ATTEMPT_GLOBAL_PRODUCT_LIST_MODIFICATION",
  GLOBAL_PRODUCTS_SUCCESS: "/koala-cms/globalProducts/GLOBAL_PRODUCTS_SUCCESS",
  GLOBAL_PRODUCT_SUCCESS: "/koala-cms/globalProducts/GLOBAL_PRODUCT_SUCCESS",
  GLOBAL_PRODUCTS_FAIL: "/koala-cms/globalProducts/GLOBAL_PRODUCTS_FAIL",
  ALLERGIES_SUCCESS: "/koala-cms/globalProducts/ALLERGIES_SUCCESS",

  fetchGlobalProducts: (magicBox) => ({
    type: globalProductsActions.FETCH_GLOBAL_PRODUCTS,
    magicBox,
  }),

  fetchGlobalProduct: (id) => ({
    type: globalProductsActions.FETCH_GLOBAL_PRODUCT,
    id,
  }),

  fetchGlobalMenuSuccess: (data, magicBox) => ({
    data,
    magicBox,
    type: globalProductsActions.GLOBAL_PRODUCTS_SUCCESS,
  }),
};

export default globalProductsActions;
