import React, { useCallback } from "react";
import styled from "styled-components";
import { Editable, useSlate } from "slate-react";
import { EditorActions } from "./actions";
import { Elements, Leaf } from "./elements";
import { elementTypes } from "./types";

interface Props {
  supports: string[];
}

export const Editor = ({ supports }: Props) => {
  const editor = useSlate();

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case elementTypes.orderedList:
        return <Elements.OrderedList {...props} />;
      case elementTypes.unorderedList:
        return <Elements.UnorderedList {...props} />;
      case elementTypes.listItem:
        return <Elements.ListItem {...props} />;
      case elementTypes.anchor:
        return <Elements.Anchor {...props} />;
      default:
        return <Elements.Paragraph {...props} />;
    }
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  return (
    <>
      <EditableContainer
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            EditorActions.maybeRemoveListItem(editor, event);
          }

          if (event.key === "Backspace") {
            EditorActions.maybeRemoveList(editor, event);
          }

          if (event.ctrlKey || event.metaKey) {
            if (event.shiftKey && supports.includes("lists")) {
              switch (event.key) {
                case "7": {
                  event.preventDefault();
                  EditorActions.toggleList(editor, elementTypes.orderedList);
                  break;
                }

                case "8": {
                  event.preventDefault();
                  EditorActions.toggleList(editor, elementTypes.unorderedList);
                  break;
                }
              }
            } else {
              switch (event.key) {
                case "b": {
                  if (supports.includes("bold")) {
                    event.preventDefault();
                    EditorActions.toggleMark(editor, "bold");
                  }
                  break;
                }

                case "i": {
                  if (supports.includes("italic")) {
                    event.preventDefault();
                    EditorActions.toggleMark(editor, "italic");
                  }
                  break;
                }
              }
            }
          }
        }}
      />
    </>
  );
};

const EditableContainer = styled(Editable)`
  max-height: 200px;
  overflow: scroll;
  padding: 0.5rem;
`;
