import { AnyAction } from "redux";
import actions from "./actions";
import { IConfigurations } from "./types";

const initialState: IConfigurations = {};

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.FETCH_CONFIG:
    case actions.UPDATE_CONFIG:
      return Object.assign({}, state, {
        [action.label]: {
          ...state[action.label],
          loading: true,
        },
      });
    case actions.FETCH_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        [action.label]: {
          ...action.data,
          loading: false,
        },
      });
    case actions.UPDATE_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        [action.label]: {
          ...state[action.label],
          config: action.data,
          loading: false,
        },
      });
    case actions.FETCH_CONFIG_FAILURE:
    case actions.UPDATE_CONFIG_FAILURE:
      return Object.assign({}, state, {
        [action.label]: {
          ...state[action.label],
          loading: false,
        },
      });
    case actions.CLEAR_ALL_CONFIGS:
      return initialState;
    default:
      return state;
  }
}
