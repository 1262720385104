import React from "react";
import styled from "styled-components";
import { Menu } from "antd";
import { DeletePage } from "components/web-pages/delete/delete";
import { PageStatus } from "components/web-pages/types/page";
import { DuplicatePage } from "components/web-pages/duplicate/duplicate";

interface Props {
  onUpdate: (status: PageStatus) => void;
  onDuplicate: () => void;
  onDelete: () => void;
}

export function PageOptions({ onDelete, onUpdate, onDuplicate }: Props) {
  return (
    <Menu>
      <Menu.Item key="save-as-draft">
        <Action onClick={() => onUpdate(PageStatus.draft)}>
          Save as draft
        </Action>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="duplicate">
        <DuplicatePage onClick={onDuplicate} />
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="delete">
        <DeletePage onClick={onDelete} />
      </Menu.Item>
    </Menu>
  );
}

const Action = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: var(--color--blue-400);
  cursor: pointer;
`;
