import { UrlField } from "components/web-pages/modules/types";
import { Editor, Transforms, Text, Range, Element, Node } from "slate";
import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import { elementTypes, markTypes } from "./types";

function hasSelection(editor: BaseEditor & ReactEditor) {
  const { selection } = editor;

  return Boolean(selection?.anchor.offset !== selection?.focus.offset);
}

export const EditorActions = {
  isBlockActive(editor: BaseEditor & ReactEditor, type: elementTypes) {
    const [match] = Editor.nodes(editor, {
      match: (node) => "type" in node && node.type === type,
    });

    return !!match;
  },

  isMarkActive(editor: BaseEditor & ReactEditor, type: markTypes) {
    const [match] = Editor.nodes(editor, {
      match: (node) => node[type] === true,
      universal: true,
    });

    return !!match;
  },

  toggleMark(editor: BaseEditor & ReactEditor, type: markTypes) {
    const isActive = EditorActions.isMarkActive(editor, type);

    Transforms.setNodes(
      editor,
      { [type]: isActive ? null : true },
      { match: (node) => Text.isText(node), split: true }
    );
  },

  setSize(editor: BaseEditor & ReactEditor, size: string) {
    const isSelecting = hasSelection(editor);

    if (isSelecting) {
      Transforms.setNodes(
        editor,
        { size },
        { match: (node) => Text.isText(node), split: true }
      );
    } else {
      Transforms.setNodes(
        editor,
        { size },
        { at: [], match: (node) => Text.isText(node), split: true }
      );
    }
  },

  setColor(editor: BaseEditor & ReactEditor, color: string) {
    const isSelecting = hasSelection(editor);

    if (isSelecting) {
      Transforms.setNodes(
        editor,
        { color },
        { match: (node) => Text.isText(node), split: true }
      );
    } else {
      Transforms.setNodes(
        editor,
        { color },
        { at: [], match: (node) => Text.isText(node), split: true }
      );
    }

    Transforms.collapse(editor, { edge: "focus" });
  },

  toggleList(editor: BaseEditor & ReactEditor, type: elementTypes) {
    const isActive = EditorActions.isBlockActive(editor, type);

    // always attempt to unwrap nodes so we can jump between list types
    Transforms.unwrapNodes(editor, {
      match: (node) => "type" in node && ["ul", "ol"].includes(node.type),
      split: true,
    });

    // set nodes to either a list or paragraph
    Transforms.setNodes(
      editor,
      { type: isActive ? elementTypes.paragraph : elementTypes.listItem },
      { match: (node) => Editor.isBlock(editor, node) }
    );

    if (!isActive) {
      // @ts-expect-error Argument of type '{ type: elementTypes; }' is not assignable to parameter of type 'CustomElement'. Property 'text' is missing in type '{ type: elementTypes; }' but required in type 'FormattedText'
      Transforms.wrapNodes(editor, { type });
    }
  },

  maybeRemoveList(editor: BaseEditor & ReactEditor, event) {
    const { selection } = editor;

    if (!!selection) {
      const selectedElement = Node.descendant(
        editor,
        selection.anchor.path.slice(0, -1)
      );

      if (selectedElement.type === elementTypes.listItem) {
        const selectedLeaf = Node.descendant(editor, selection.anchor.path);

        if (
          // the second item is the position in the list
          selection.anchor.path[1] === 0 &&
          // ensure text exists in the object
          "text" in selectedLeaf &&
          selectedLeaf.text === ""
        ) {
          event.preventDefault();

          Transforms.unwrapNodes(editor, {
            match: (node) => "type" in node && ["ul", "ol"].includes(node.type),
            split: true,
          });

          Transforms.setNodes(
            editor,
            { type: elementTypes.paragraph },
            { match: (n) => Editor.isBlock(editor, n) }
          );
        }
      }
    }
  },

  maybeRemoveListItem(editor: BaseEditor & ReactEditor, event) {
    const { selection } = editor;

    if (!!selection) {
      const selectedElement = Node.descendant(
        editor,
        selection.anchor.path.slice(0, -1)
      );

      if (selectedElement.type === elementTypes.listItem) {
        const selectedLeaf = Node.descendant(editor, selection.anchor.path);

        if ("text" in selectedLeaf && selectedLeaf.text === "") {
          event.preventDefault();

          Transforms.unwrapNodes(editor, {
            match: (node) => "type" in node && ["ul", "ol"].includes(node.type),
            split: true,
          });

          Transforms.setNodes(
            editor,
            { type: elementTypes.paragraph },
            { match: (n) => Editor.isBlock(editor, n) }
          );
        }
      }
    }
  },

  getLinkValue(editor: BaseEditor & ReactEditor) {
    const [match] = Editor.nodes(editor, {
      match: (node) => "type" in node && node.type === elementTypes.anchor,
    });

    if (match) {
      try {
        return JSON.parse(match[0]["data-url"]);
      } catch (error) {
        console.warn(error);
      }

      return undefined;
    }
  },

  removeLink(editor: BaseEditor & ReactEditor) {
    const isActive = EditorActions.isBlockActive(editor, elementTypes.anchor);

    if (isActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          n.type === elementTypes.anchor,
      });
    }
  },

  updateLink(editor: BaseEditor & ReactEditor, value: UrlField) {
    const { selection } = editor;

    if (!!selection) {
      if (!Range.isCollapsed(selection)) {
        Transforms.unwrapNodes(editor, {
          match: (n) =>
            !Editor.isEditor(n) &&
            Element.isElement(n) &&
            n.type === elementTypes.anchor,
        });

        if (value?.reference) {
          const { type, url, newWindow } = value.reference;
          // Wrap the currently selected range of text into a Link
          Transforms.wrapNodes(
            editor,
            {
              type: elementTypes.anchor,
              "data-url": JSON.stringify(value),
              href: type === "local" ? `/${url}` : url,
              target: newWindow ? "_blank" : "_self",
              children: [
                {
                  type: "text",
                  text: "",
                },
              ],
            },
            { split: true }
          );

          // Remove the highlight and move the cursor to the end of the highlight
          Transforms.collapse(editor, { edge: "end" });
        }
      }
    }
  },
};

export function withLinks(editor: ReactEditor) {
  const { isInline } = editor;

  editor.isInline = (element) =>
    element.type === elementTypes.anchor ? true : isInline(element);

  return editor;
}
