import React from "react";
import type * as Stitches from "@stitches/react";
import { Base, Field } from "./base";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  required?: boolean;
  type?: string;
  error?: string;
  css?: Stitches.CSS;
}

export function Input({
  label,
  name,
  required = false,
  error,
  ...otherProps
}: Props) {
  const baseProps = { label, name, required, error };

  return (
    <Base {...baseProps}>
      <Field
        id={name}
        placeholder=" "
        required={required}
        aria-label={label}
        {...otherProps}
      />
    </Base>
  );
}
