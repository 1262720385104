import React, { useState } from "react";
import styled from "styled-components";
import { FontSizeOutlined, LinkOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { UrlPicker } from "./picker";
import { UrlField } from "components/web-pages/modules/types";

interface Props {
  onChange: (value: UrlField) => void;
  value: UrlField;
}

export const Url = ({ onChange, value }: Props) => {
  const [visible, setVisibility] = useState(false);

  const { reference, label } = value;

  return (
    <Element>
      <Stack>
        <LinkOutlined style={{ color: "rgba(0,0,0,.25)" }} />
        <ToggleUrlPicker
          active={Boolean(reference?.label)}
          onClick={() => setVisibility(true)}
        >
          {reference?.label ? reference.label : "Add link"}
        </ToggleUrlPicker>

        {visible && (
          <UrlPicker
            title="Edit link"
            visible={visible}
            value={value}
            onChange={(details) => {
              setVisibility(false);
              onChange(details);
            }}
            onRemove={() => {
              setVisibility(false);
              onChange({
                label: value.label ?? "",
                reference: undefined,
              });
            }}
            onCancel={() => void setVisibility(false)}
          />
        )}
      </Stack>
      <Divider />
      <StyledInput
        placeholder="Add label..."
        prefix={<FontSizeOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          onChange({
            ...value,
            label: e.currentTarget.value,
          });
        }}
        value={label}
      />
    </Element>
  );
};

const Element = styled.div`
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
`;

const StyledInput = styled(Input)`
  border: none;
`;

const Stack = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  padding: 0 0.5rem;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid rgb(217, 217, 217);
  margin: 0 0.5rem;
`;

const ToggleUrlPicker = styled.button`
  ${({ active }) => `
    color: ${active ? "#0050b3" : "#595959"};
  `}

  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  text-decoration: underline !important;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.5;
  }
`;
