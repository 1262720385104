import { Modal, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import {
  StyledEnvGroup,
  StyledGroup,
  StyledSubgroup,
  StyledSubgroupIndented,
} from "./styles";
import BRAND_ENV from "constants/app_env";
import GLOBAL_ENV from "constants/global_env";
import { useCMSConfig } from "hooks/use-cms-config";

const CMSConfig = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const cmsConfig = useCMSConfig();

  useEffect(() => {
    // a key map of allowed keys
    const allowedKeys = {
      37: "left",
      38: "up",
      39: "right",
      40: "down",
      65: "a",
      66: "b",
    };
    // the official Konami Code sequence
    const konamiCode = [
      "up",
      "up",
      "down",
      "down",
      "left",
      "right",
      "left",
      "right",
      "b",
      "a",
    ];
    let konamiCodePosition = 0;
    document.addEventListener("keydown", (e) => {
      const key = allowedKeys[e.keyCode];
      const requiredKey = konamiCode[konamiCodePosition];
      // compare the key with the required key
      if (key === requiredKey) {
        // move to the next key in the konami code sequence
        konamiCodePosition++;
        // if the last key is reached, activate modal
        if (konamiCodePosition === konamiCode.length) {
          setIsVisible(true);
          konamiCodePosition = 0;
        }
      } else {
        konamiCodePosition = 0;
      }
    });
  }, []);

  return (
    <div>
      <Modal
        title="Having Trouble?"
        open={isVisible}
        onOk={() => setIsVisible(false)}
        onCancel={() => setIsVisible(false)}
        width={600}
      >
        <Tabs>
          <Tabs.TabPane tab="CMS Config" key="cms-config">
            {Object.keys(cmsConfig).map((key, index) => {
              if (typeof cmsConfig[key] !== "object") {
                return (
                  <StyledGroup key={`cms-${index}`}>
                    <StyledSubgroup>
                      <h3>{key}:&nbsp;</h3>
                      <Tag color={cmsConfig[key] ? "blue" : "red"}>
                        {String(cmsConfig[key])}
                      </Tag>
                    </StyledSubgroup>
                  </StyledGroup>
                );
              }

              return (
                <div key={`group-${index}`}>
                  <h3>{String(key)}</h3>
                  {Object.keys(cmsConfig[key]).map((subkey, i) => (
                    <StyledSubgroupIndented key={`subkey-${i}`}>
                      {subkey}:&nbsp;
                      <Tag color={cmsConfig[key][subkey] ? "blue" : "red"}>
                        {String(cmsConfig[key][subkey])}
                      </Tag>
                    </StyledSubgroupIndented>
                  ))}
                </div>
              );
            })}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Brand ENV Config" key="env-config">
            {Object.keys(BRAND_ENV).map((key, index) => (
              <StyledEnvGroup key={`env-${index}`}>
                <StyledSubgroup>
                  <h3>{key}:&nbsp;</h3>
                  <Tag color={BRAND_ENV[key] ? "blue" : "red"}>
                    {String(BRAND_ENV[key])}
                  </Tag>
                </StyledSubgroup>
              </StyledEnvGroup>
            ))}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Global ENV Config" key="global-env-config">
            {Object.keys(GLOBAL_ENV).map((key, index) => (
              <StyledEnvGroup key={`global-env-${index}`}>
                <StyledSubgroup>
                  <h3>{key}:&nbsp;</h3>
                  <Tag color={GLOBAL_ENV[key] ? "blue" : "red"}>
                    {String(GLOBAL_ENV[key])}
                  </Tag>
                </StyledSubgroup>
              </StyledEnvGroup>
            ))}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default CMSConfig;
