import API from "../constants/api_env";
import { AccessToken } from "../storage/token";
import httpService from "../services/generic.service";

/**
 * Fetch organization
 */
export function fetchOrganization(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/v1${API.routes.organizations}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Update organization
 */
export function updateOrganization(payload, id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.urlBase}/v1${API.routes.organizations}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data: payload,
  });
}
