import React from "react";
import styled from "styled-components";
import { Alignment, align } from "./alignment";
import { direction, Direction } from "./direction";
import { Text } from "./text";
import { Color } from "components/web-pages/fields/color";
import { Image } from "components/web-pages/fields/image";
import { ImageAsset } from "components/assets/manager";

enum LayoutOptions {
  "direction" = "direction",
  "alignment" = "alignment",
  "backgroundColor" = "backgroundColor",
  "backgroundImage" = "backgroundImage",
  "height" = "height",
}

type LayoutValue = {
  alignment?: align;
  backgroundColor?: string;
  backgroundImage?: ImageAsset;
  mobileBackgroundImage?: ImageAsset;
  direction?: direction;
  height?: string;
};
interface Props {
  value: LayoutValue;
  onChange: (value: LayoutValue) => void;
  supports: string[];
}

export function Layout({ value, onChange, supports }: Props) {
  const options = [
    LayoutOptions.direction,
    LayoutOptions.alignment,
    LayoutOptions.backgroundColor,
    LayoutOptions.backgroundImage,
    LayoutOptions.height,
  ].filter((item) =>
    Boolean(supports.length) ? supports.includes(item) : true
  );

  return (
    <div>
      {options.map((item) => {
        switch (item) {
          case LayoutOptions.direction:
            return (
              <Row key={item}>
                <Label>Content flow</Label>
                <Content>
                  <Direction
                    onChange={(update) =>
                      onChange({
                        ...value,
                        direction: update,
                      })
                    }
                    value={value?.direction ?? direction.vertical}
                  />
                </Content>
              </Row>
            );
          case LayoutOptions.alignment:
            return (
              <Row key={item}>
                <Label>Alignment</Label>
                <Content>
                  <Alignment
                    value={value?.alignment ?? align.centerCenter}
                    onChange={(update) =>
                      onChange({
                        ...value,
                        alignment: update,
                      })
                    }
                  />
                </Content>
              </Row>
            );
          case LayoutOptions.backgroundColor:
            return (
              <Row key={item}>
                <Label>Background color</Label>

                <Color
                  value={value?.backgroundColor}
                  onChange={(color) =>
                    onChange({
                      ...value,
                      backgroundColor: color,
                    })
                  }
                />
              </Row>
            );
          case LayoutOptions.backgroundImage:
            return (
              <>
                <Row key={item}>
                  <Label>Background image</Label>

                  <Image
                    height={32}
                    value={value?.backgroundImage}
                    onChange={(image) =>
                      onChange({
                        ...value,
                        backgroundImage: image,
                      })
                    }
                    onRemove={() => {
                      const { backgroundImage, ...otherValues } = value;

                      onChange(otherValues);
                    }}
                  />
                </Row>

                <Row key={`${item}-mobile`}>
                  <Label>Background (mobile)</Label>

                  <Image
                    height={32}
                    value={value?.mobileBackgroundImage}
                    onChange={(image) =>
                      onChange({
                        ...value,
                        mobileBackgroundImage: image,
                      })
                    }
                    onRemove={() => {
                      const { mobileBackgroundImage, ...otherValues } = value;

                      onChange(otherValues);
                    }}
                  />
                </Row>
              </>
            );
          case LayoutOptions.height:
            return (
              <Row key={item}>
                <Label>Max height</Label>

                <Text
                  onChange={(update) =>
                    onChange({
                      ...value,
                      height: update,
                    })
                  }
                  value={value?.height ?? ""}
                  placeholder="0"
                />
              </Row>
            );

          default:
            console.warn("unsupported layout option");
            return null;
        }
      })}
    </div>
  );
}

const Row = styled.div`
  border-top: 1px solid var(--color--black-800);
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 4rem;
  padding: 0.5rem 0;

  &:nth-child(1) {
    border-top: none;
    padding-top: 0;
  }
`;

const Label = styled.label`
  font-size: 0.8125rem;
`;

const Content = styled.div`
  flex: none;
  margin: 0 0 0 auto;
`;
