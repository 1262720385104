import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { Dropdown } from "./dropdown";
import { Url } from "./url/url";
import { ButtonField } from "components/web-pages/modules/types";

interface Props {
  onChange: (value: any) => void;
  value: ButtonField;
}

export const Button = ({ onChange, value }: Props) => {
  return (
    <Container>
      <div>
        <Description type="secondary">URL</Description>
        <Url
          value={value.url}
          onChange={(url) => {
            onChange({
              ...value,
              url,
            });
          }}
        />
      </div>

      <div>
        <Description type="secondary">Button style</Description>

        <Dropdown
          options={["primary", "secondary", "tertiary"]}
          value={value?.style || null}
          onChange={(style) => {
            onChange({
              ...value,
              style,
            });
          }}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Description = styled(Typography.Text)`
  font-size: 0.75rem;
`;
