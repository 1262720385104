import {
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
  makeOperation,
} from "urql";

import { authExchange } from "@urql/exchange-auth";
import { AccessToken } from "storage/token";
import { REGEX } from "constants/validation";
import { GraphQLError } from "graphql";
import { ENV } from "constants/app_env";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { SDK } from "services/koala.service";

export function contentGetOrderingAppUrl(slug: string) {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  }

  switch (ENV) {
    case "dev":
      return `https://${slug}.dev.order.koala.io`;
    case "staging":
      return `https://${slug}.staging.order.koala.io`;
    case "sandbox":
      return `https://${slug}.sandbox.order.koala.io`;
    case "production":
      return `https://${slug}.order.koala.io`;
    default:
      return "http://localhost:3000";
  }
}

export function contentGetApiUrl(): string | null {
  switch (ENV) {
    case "local":
      return "http://localhost:9000/api/graphql";
    case "dev":
      return "https://dev.api.koala.io/content/graphql";
    case "staging":
      return "https://staging.api.koala.io/content/graphql";
    case "sandbox":
      return "https://sandbox.api.koala.io/content/graphql";
    case "production":
      return "https://api.koala.io/content/graphql";
    default:
      console.warn(`${ENV} does not exist!`);
      return null;
  }
}

export function contentSession() {
  const apiUrl = contentGetApiUrl();

  return createClient({
    url: apiUrl ?? "",
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange({
        addAuthToOperation: ({ authState, operation }: any) => {
          if (!authState?.token) {
            return operation;
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === "function"
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: authState.token,
                "X-Organization-Id": authState.organizationId,
              },
            },
          });
        },
        // @ts-expect-error: difficult to type everything with this version of urql
        getAuth: ({ authState }) => {
          if (!SDK.auth.isTokenValid()) {
            return null;
          }

          const { token_type, access_token } = AccessToken.get();

          if (!authState) {
            return {
              token: `${token_type} ${access_token}`,
            };
          }

          return null;
        },
      }),
      fetchExchange,
    ],
  });
}

export const contentGetSanitizedSlug = (value: string) => {
  const sanitizedValue = value.replace(/[ ?]/g, "-").toLowerCase();
  const matches = sanitizedValue.match(REGEX.PAGE_SLUG);

  return matches?.join("");
};

export function contentGetParsedErrorCode(code: unknown) {
  switch (code) {
    case "KS_ACCESS_DENIED":
      return "Access Denied";

    default:
      return "Error";
  }
}

export function contentGetParsedErrorIntent(code: unknown) {
  switch (code) {
    case "createPage":
      return "Cannot create page";

    case "updatePage":
      return "Cannot update page";

    default:
      return "Cannot perform action";
  }
}

export function contentGetParsedErrorReason(message: string) {
  if (message.includes('You cannot create the fields ["slug"]')) {
    return "Please enter a unique slug";
  }

  if (message.includes('You cannot update the fields ["slug"]')) {
    return "Please enter a unique slug";
  }

  return message;
}

export function contentGetParsedError(errors: GraphQLError[]) {
  return errors
    .map((error) => {
      const code = contentGetParsedErrorCode(error.extensions.code);
      const message = contentGetParsedErrorIntent(
        error?.path?.length ? error.path[0] : error.path
      ).replace(/\\\\n/g, "");

      const reason = contentGetParsedErrorReason(error.message);

      return `${code}: ${message}. ${reason}`;
    })
    .join(", ");
}

type MessageData = {
  type: string;
  [key: string]: unknown;
};

type Message = MessageData & {
  sendBack: (value: MessageData) => void;
};

type WindowMessagingDetails = {
  ref: RefObject<HTMLIFrameElement | undefined>;
  origin: string | null;
  message?: Message;
  setOrigin: Dispatch<SetStateAction<string | null>>;
  sendMessage: (value: MessageData) => void;
};

/**
 * A reactified way to do window.postMessage events via a custom hook
 *
 * - The hook handles setting up message events and captures them in a local variable
 * - This implementation is based on talking to an iframe, so we expose a `sendMessage` function that will post to the iframe assigned the declared ref
 * - A dependency list of stateful items affected by the messaging system can be passed in to ensure everything stays up to date
 * ```
 * const { origin, message } = useWindowMessaging(
 *  "https://koala-labs.order.koala.io",
 *  [someId]
 * );
 * ```
 * @param windowOrigin
 * @param dependencies
 * @returns WindowMessagingDetails
 */

export function useWindowMessaging(
  windowOrigin: string,
  dependencies: unknown[] = []
): WindowMessagingDetails {
  const ref: RefObject<HTMLIFrameElement | undefined> = useRef();
  const [message, setMessage] = useState<Message>();
  const [origin, setOrigin] = useState<string | null>(windowOrigin);

  function callback(event: MessageEvent): void {
    if (event.origin !== origin) {
      return;
    }

    setMessage({
      ...event.data,
      sendBack: (value: MessageData) => {
        (event.source as WindowProxy).postMessage(value, event.origin);
      },
    });
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const listener = useCallback(callback, [...dependencies, origin]);

  useEffect(function () {
    window.addEventListener("message", listener, false);

    return function () {
      window.removeEventListener("message", listener, false);
    };
  });

  return {
    ref,
    origin,
    message,
    setOrigin,
    sendMessage: (value: MessageData) => {
      try {
        if (!ref?.current?.contentWindow || !origin) {
          throw "unable to send message";
        }

        ref.current.contentWindow.postMessage(value, origin);
      } catch (error) {
        console.error(error);
      }
    },
  };
}
