// Utils
import { MagicBoxParams } from "utils/magicBox";

// Const
import {
  resourceKeys as rk,
  resourceDefaults as rd,
} from "constants/resources/keys";

// Actions
import actions from "./actions";
import { FixMeAny } from "types/fix-me-any";

const initialState = {
  loading: false,
  list: [],
  detail: null,
  meta: {},
  magicBox: new MagicBoxParams().setIncludes(rd[rk.globalProducts].includes),
  allergies: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_GLOBAL_PRODUCTS:
    case actions.FETCH_GLOBAL_PRODUCT:
    case actions.UPDATE_GLOBAL_PRODUCT:
    case actions.UPDATE_GLOBAL_PRODUCT_IMAGE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.GLOBAL_PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        list: action.data.data,
        meta: { retrieved_at: Date.now(), ...action.data.meta }, //Mimic the retrieved_at timestamp applied on lists queried through the Koala SDK
        magicBox: action.magicBox,
        loading: false,
      });
    case actions.GLOBAL_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        detail: action.detail,
        loading: false,
      });
    case actions.ALLERGIES_SUCCESS:
      return Object.assign({}, state, {
        allergies: action.data.data,
        loading: false,
      });
    case actions.CLEAR_GLOBAL_PRODUCT_DETAIL:
      return Object.assign({}, state, {
        detail: null,
      });
    case actions.STOP_LOADING_STATE:
    case actions.GLOBAL_PRODUCTS_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.ATTEMPT_GLOBAL_PRODUCT_LIST_MODIFICATION: {
      return Object.assign({}, state, {
        loading: false,
        detail:
          !!state.detail && state.detail!["id"] === action.id
            ? Object.assign({}, state.detail, {
                [action.key]: action.value,
              })
            : state.detail,
        list: state.list.map((item: FixMeAny) => {
          if (item.id === action.id) {
            return Object.assign({}, item, {
              [action.key]: action.value,
            });
          }

          return item;
        }),
      });
    }
    default:
      return state;
  }
}
