import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

export function fetchTags(tagType, pageNumber) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${
      API.routes.tags
    }?filters[type]==${tagType}&per_page=50&page=${pageNumber ?? 1}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function postTag(payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.tags}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

export function updateTagIconImage(id, imageValue) {
  const { token_type, access_token } = AccessToken.get();
  const formData = new FormData();
  formData.append("icon_image", imageValue);
  formData.append("_method", "put");

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.tags}/${id}/icon_image`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data: formData,
  });
}

export function deleteTag(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.tags}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function updateTag(id, payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.tags}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}
