import React from "react";
import { Checkbox as AntCheckbox } from "antd";
import styled from "styled-components";

interface Props {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const Checkbox = ({ label, onChange, value }: Props) => {
  return (
    <Element
      onChange={() => void onChange(value === false ? true : false)}
      checked={value}
    >
      {label}
    </Element>
  );
};

const Element = styled(AntCheckbox)`
  fontsize: ".75rem";
`;
