import React, { useState } from "react";
import { useHistory, Redirect } from "react-router";
import queryString from "query-string";
import { Container } from "./container";
import { Formik, Form as FormikForm, useField } from "formik";
import { Form, Stack } from "components/ui";
import { Button } from "components/ui/button";
import { resetPassword } from "./service";
import { notification } from "antd";

function Fields() {
  const [username] = useField({
    name: "username",
  });

  const [password, passwordMeta] = useField({
    name: "password",
    validate: (value) => {
      if (value.length < 8) {
        return "must be at least 8 characters";
      }

      return;
    },
  });

  const [confirmPassword, confirmPasswordMeta] = useField({
    name: "confirm_password",
    validate: (value) => {
      if (value !== password.value) {
        return "passwords must match";
      }

      return;
    },
  });

  return (
    <>
      <Form.Input {...username} label="Email address" type="text" required />
      <Form.Input
        {...password}
        label="New Password"
        type="password"
        required
        error={
          passwordMeta.touched && passwordMeta.error
            ? passwordMeta.error
            : undefined
        }
      />
      <Form.Input
        {...confirmPassword}
        label="Confirm Password"
        type="password"
        required
        error={
          confirmPasswordMeta.touched && confirmPasswordMeta.error
            ? confirmPasswordMeta.error
            : undefined
        }
      />
    </>
  );
}

export function ResetPassword(props) {
  const history = useHistory();

  const parsed = queryString.parse(props.location.search);
  const { username, token } = parsed;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <Container
      title="Reset my password"
      description="Enter your new password below. Passwords must contain at least 8 characters."
    >
      <Formik
        initialValues={{
          username: Array.isArray(username) ? username[0] : String(username),
          password: "",
          current_password: "",
        }}
        onSubmit={async ({ username, password }) => {
          if (loading) return;

          setLoading(true);

          const response = await resetPassword({
            username,
            password,
            token: Array.isArray(token) ? token[0] : String(token),
          });

          if (response.ok) {
            notification.success({
              message: "Your password has been reset",
              duration: 2,
            });

            history.push("/login");
          } else {
            setError(response.error);
          }

          setLoading(false);
        }}
      >
        <FormikForm>
          <Stack direction="vertical" gap="$3">
            <Fields />

            <Button disabled={loading} type="submit" css={{ width: "100%" }}>
              Reset Password
            </Button>

            {error && (
              <Stack
                position="center"
                css={{
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                  borderRadius: "$md",
                  color: "#750808",
                  fontSize: "$text300",
                  height: "$16",
                  lineHeight: "$tight",
                  marginTop: "$4",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {error}
              </Stack>
            )}
          </Stack>
        </FormikForm>
      </Formik>
    </Container>
  );
}
