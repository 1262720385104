import { ImageAsset } from "components/assets/manager";

export type ColorField = string;
export type DropdownField = string;

export type RichtextField = {
  color?: string;
  style?: string;
  contents: string;
};

export enum UrlType {
  "local" = "local",
  "external" = "external",
  "attachment" = "attachment",
  "static" = "static",
}

export type UrlReference = {
  type: UrlType;
  label: string;
  url: string;
  newWindow: boolean;
};

export type UrlField = {
  label: string;
  reference?: UrlReference;
};

export type ButtonField = {
  style: string;
  url: UrlField;
};

export type AlignmentField =
  | "left-top"
  | "center-top"
  | "right-top"
  | "left-center"
  | "center-center"
  | "right-center"
  | "left-bottom"
  | "center-bottom"
  | "right-bottom";

export type LayoutField =
  | "left-top"
  | "center-top"
  | "right-top"
  | "left-center"
  | "center-center"
  | "right-center"
  | "left-bottom"
  | "center-bottom"
  | "right-bottom";

export type FieldTypes =
  | ColorField
  | DropdownField
  | RichtextField
  | ImageAsset
  | UrlField
  | ButtonField
  | AlignmentField
  | LayoutField;

export type Modules = {
  order: string[];
  modules: Record<string, Module>;
};

export type ModuleValue =
  | ColorField
  | DropdownField
  | RichtextField
  | ImageAsset
  | UrlField
  | ButtonField
  | AlignmentField;

export type Module = {
  type: string;
  order?: string[];
  modules?: Record<string, Module>;
} & Record<string, ModuleValue>;

export type FieldType = {
  fieldType:
    | "text"
    | "richtext"
    | "dropdown"
    | "checkbox"
    | "radio"
    | "url"
    | "image"
    | "color"
    | "modules"
    | "button"
    | "layout";
  label: string;
  icon?: string;
  attributes?: {
    accepts?: string[];
    action?: string;
    label: string;
    limit?: number;
    supports?: string[];
    values?: string[];
    defaultValue?: string;
    placeholder?: string;
  };
};

export type ModuleType = {
  title: string;
  description: string;
  moduleType: string;
  fields: Record<string, FieldType>;
  icon?: string;
  visible?: boolean;
};
