import { ConfigLabel } from "./types";

const configActions = {
  INITIALIZE_CONFIGS: "/koala-cms/configurations/INITIALIZE_CONFIGS",
  FETCH_CONFIG: "/koala-cms/configurations/FETCH_CONFIG",
  FETCH_CONFIG_SUCCESS: "/koala-cms/configurations/FETCH_CONFIG_SUCCESS",
  FETCH_CONFIG_FAILURE: "/koala-cms/configurations/FETCH_CONFIG_FAILURE",
  UPDATE_CONFIG: "/koala-cms/configurations/UPDATE_CONFIG",
  UPDATE_CONFIG_SUCCESS: "/koala-cms/configurations/UPDATE_CONFIG_SUCCESS",
  UPDATE_CONFIG_FAILURE: "/koala-cms/configurations/UPDATE_CONFIG_FAILURE",
  CLEAR_ALL_CONFIGS: "/koala-cms/configurations/CLEAR_ALL_CONFIGS",

  initializeConfigs: (organizationId: number) => ({
    type: configActions.INITIALIZE_CONFIGS,
    organizationId,
  }),
  fetchConfig: (label: ConfigLabel, organizationId: number) => ({
    type: configActions.FETCH_CONFIG,
    label,
    organizationId,
  }),
  clearAllConfigs: () => ({
    type: configActions.CLEAR_ALL_CONFIGS,
  }),
  updateConfig: (
    label: ConfigLabel,
    schemaVersion: number,
    organizationId: number,
    payload: Record<string, unknown> | object
  ) => ({
    type: configActions.UPDATE_CONFIG,
    label,
    schemaVersion,
    organizationId,
    payload,
  }),
};

export default configActions;
