import get from "lodash/get";

export const deriveImageUploadError = (error) => {
  let errorMsg = "There was an error updating image.";
  if (typeof error === "object") {
    const errorStatus = get(error, "response.status", null);

    if (errorStatus === 405) {
      errorMsg =
        "Whoops, it looks like your image file size is too large. Please try uploading a smaller image.";
    }
  }

  return errorMsg;
};
