// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  marketing: null,
  filter: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TAGS:
    case actions.POST_TAG:
    case actions.DELETE_TAG:
    case actions.UPDATE_TAG:
    case actions.UPDATE_TAG_ICON_IMAGE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_TAGS_FAIL:
    case actions.POST_TAG_SUCCESS:
    case actions.DELETE_TAG_SUCCESS:
    case actions.DELETE_TAG_FAIL:
    case actions.UPDATE_TAG_SUCCESS:
    case actions.UPDATE_TAG_FAIL:
    case actions.UPDATE_TAG_ICON_IMAGE_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.FETCH_TAGS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        [action.tagType]: action.data,
      });
    default:
      return state;
  }
}
