import styled from "styled-components";

export const Controls = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid var(--color--black-900);
  display: flex;
  padding: 0.25rem;
`;

export const ControlsSpacer = styled.div`
  flex: 1;
`;

export const ControlButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    background-color: var(--color--black-800);
  }
`;
