import React from "react";
import styled from "styled-components";
import { ENV } from "constants/app_env";

function Copyright() {
  const copyright = `&copy; ${new Date().getFullYear()} Koala Labs`;
  const details = ENV === "production" ? "" : `&mdash; ${ENV}`;

  return (
    <Container>
      <span dangerouslySetInnerHTML={{ __html: `${copyright} ${details}` }} />
    </Container>
  );
}

const Container = styled.span`
  font-size: 0.625rem;
  color: #999;
`;

export default Copyright;
