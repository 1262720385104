import {
  ILocationAttribute,
  ILocationAttributePost,
  ILocationAttributeUpdate,
} from "types/locations";
import { MagicBoxParams } from "utils/magicBox";

const locationActions = {
  FETCH_LOCATIONS: "/koala-cms/location/FETCH_LOCATIONS",
  FETCH_LOCATIONS_BY_ADDRESS: "/koala-cms/location/FETCH_LOCATIONS_BY_ADDRESS",
  LOCATIONS_SUCCESS: "/koala-cms/location/LOCATIONS_SUCCESS",
  LOCATIONS_FAIL: "/koala-cms/location/LOCATIONS_FAIL",
  FETCH_LOCATION: "/koala-cms/location/FETCH_LOCATION",
  UPDATE_LOCATION: "/koala-cms/location/UPDATE_LOCATION",
  LOCATION_SUCCESS: "/koala-cms/location/LOCATION_SUCCESS",
  FETCH_LOCATION_ATTRIBUTES: "/koala-cms/location/FETCH_LOCATION_ATTRIBUTES",
  SET_LOCATION_ATTRIBUTES: "/koala-cms/location/SET_LOCATION_ATTRIBUTES",
  POST_LOCATION_ATTRIBUTE: "/koala-cms/location/POST_LOCATION_ATTRIBUTE",
  UPDATE_LOCATION_ATTRIBUTE: "/koala-cms/location/UPDATE_LOCATION_ATTRIBUTE",
  DELETE_LOCATION_ATTRIBUTE: "/koala-cms/location/DELETE_LOCATION_ATTRIBUTE",
  FETCH_ACTIVE_DEVICES: "/koala-cms/location/FETCH_ACTIVE_DEVICES",
  ACTIVE_DEVICES_SUCCESS: "/koala-cms/location/ACTIVE_DEVICES_SUCCESS",
  CLEAR_LOCATION: "/koala-cms/location/CLEAR_LOCATION",
  CLEAR_LOCATIONS: "/koala-cms/location/CLEAR_LOCATIONS",
  CLEAR_ACTIVE_DEVICES: "/koala-cms/location/CLEAR_ACTIVE_DEVICES",

  fetchLocation: (id: string | number) => ({
    type: locationActions.FETCH_LOCATION,
    id,
  }),

  fetchLocations: (magicBox: MagicBoxParams) => ({
    type: locationActions.FETCH_LOCATIONS,
    magicBox,
  }),

  fetchLocationsByAddress: (address: string, magicBox: MagicBoxParams) => ({
    type: locationActions.FETCH_LOCATIONS_BY_ADDRESS,
    address,
    magicBox,
  }),

  fetchActiveDevices: (id: string | number, magicBox: MagicBoxParams) => ({
    type: locationActions.FETCH_ACTIVE_DEVICES,
    id,
    magicBox,
  }),

  fetchLocationAttributes: (id: string | number) => ({
    type: locationActions.FETCH_LOCATION_ATTRIBUTES,
    id,
  }),

  setLocationAttributes: (attributes: ILocationAttribute[]) => ({
    type: locationActions.SET_LOCATION_ATTRIBUTES,
    attributes,
  }),

  postLocationAttribute: (attribute: ILocationAttributePost) => ({
    type: locationActions.POST_LOCATION_ATTRIBUTE,
    attribute,
  }),

  updateLocationAttribute: (
    attribute: ILocationAttributeUpdate,
    locationId: number
  ) => ({
    type: locationActions.UPDATE_LOCATION_ATTRIBUTE,
    attribute,
    locationId,
  }),

  deleteLocationAttribute: (id: number, locationId: number) => ({
    type: locationActions.DELETE_LOCATION_ATTRIBUTE,
    id,
    locationId,
  }),
};

export default locationActions;
