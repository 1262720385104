import { message } from "antd";
import { push } from "connected-react-router";
import { call, put, takeLatest } from "redux-saga/effects";

// Constants
import { ENV } from "constants/app_env";
import { ROUTES } from "constants/routes";

// Services
import {
  createPage,
  deletePage,
  deletePageReview,
  deployVercel,
  fetchPage,
  fetchPages,
  publishPage,
  requestPageReview,
  unpublishPage,
  updatePage,
} from "services/pages.service";
// Services
import { fetchRevision } from "services/revisions.service";

// Actions
import actions from "./actions";
import { FixMeAny } from "types/fix-me-any";

export function* fetchPagesSaga(action) {
  try {
    const response = yield call(fetchPages, action.magicBox);

    yield put(
      actions.pagesSuccess(
        response.data.data,
        response.data.meta,
        action.magicBox
      )
    );
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
  }
}

export function* fetchPageSaga(action) {
  try {
    const response = yield call(fetchPage, action.id);

    // Fetch page revision, it one exists
    const latestRevision = response.data.data.revisions[0]?.id;
    let workingRevision = {
      data: {
        data: {
          slots: [],
        },
      },
    };
    if (latestRevision) {
      workingRevision = yield call(
        fetchRevision,
        response.data.data.revisions[0]?.id
      );
    }

    yield put(
      actions.pageSuccess(response.data.data, workingRevision.data.data)
    );
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
  }
}

export function* createPageSaga(action) {
  try {
    const response = yield call(createPage, action.payload);

    // Stop spinner
    yield put({ type: actions.PAGE_SUCCESS });

    // Push to detail
    yield put(
      push(`${ROUTES.PAGES.UPDATE_INFO.replace(":id", response.data.data.id)}`)
    );

    // Alert
    yield call(message.success, "Page successfully created!");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(message.error, "There was an error creating the page");
  }
}

export function* updatePageSaga(action) {
  try {
    yield call(updatePage, action.payload, action.id);

    // Refetch page
    yield put({ type: actions.FETCH_PAGE, id: action.id });

    // Alert
    yield call(message.success, "Page successfully updated!");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(message.error, "There was an error updating the page");
  }
}

export function* deletePageSaga(action) {
  try {
    yield call(deletePage, action.id);

    // Push to list
    yield put(push(ROUTES.PAGES.BASE));

    // Alert
    yield call(message.success, "Page successfully deleted");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(message.error, "There was an error deleting the page");
  }
}

export function* publishPageSaga(action) {
  try {
    yield call(publishPage, action.id, action.revisionId);

    // Refetch page
    yield put({ type: actions.FETCH_PAGE, id: action.id });

    // Trigger a Vercel deployment, if a webhook exists
    const MARKETING_VARIABLE = `REACT_APP_MKT_HOOK_${ENV.toUpperCase()}_${
      action.marketingKey
    }`;
    if (action.marketingKey && process.env[MARKETING_VARIABLE]) {
      // @ts-expect-error Argument of type '(webhookId: string) => AxiosPromise<any>' is not assignable to parameter of type 'CallEffectNamedFn<{ [x: string]: Func1<string | undefined>; }, string>'
      yield call(deployVercel, process.env[MARKETING_VARIABLE]);
    }

    // Alert
    yield call(
      message.success,
      "Page successfully published. Please wait 5 minutes for the deployment to complete."
    );
  } catch (error: FixMeAny) {
    yield put({ type: actions.PAGES_FAIL });
    if (error.response.status === 429) {
      yield call(
        message.error,
        "The server is at publishing capacity. Please try publishing in a few minutes"
      );
    } else {
      yield call(message.error, "There was an error publishing the page");
    }
  }
}

export function* unpublishPageSaga(action) {
  try {
    yield call(unpublishPage, action.id, action.revisionId);

    // Refetch page
    yield put({ type: actions.FETCH_PAGE, id: action.id });

    // Alert
    yield call(message.success, "Page successfully unpublished");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(message.error, "There was an error unpublishing the page");
  }
}

export function* requestPageReviewSaga(action) {
  try {
    yield call(
      requestPageReview,
      action.id,
      action.revisionId,
      action.reviewerIds
    );

    // Refetch page
    yield put({ type: actions.FETCH_PAGE, id: action.id });

    // Alert
    yield call(message.success, "Review successfully requested");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(
      message.error,
      "There was an error requesting a review for the page"
    );
  }
}

export function* deletePageReviewSaga(action) {
  try {
    yield call(deletePageReview, action.pageId);

    // Refetch page
    yield put({ type: actions.FETCH_PAGE, id: action.pageId });

    // Alert
    yield call(message.success, "Review successfully removed.");
  } catch (error) {
    yield put({ type: actions.PAGES_FAIL });
    yield call(
      message.error,
      "There was an error removing this review for this page"
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_PAGES, fetchPagesSaga);
  yield takeLatest(actions.FETCH_PAGE, fetchPageSaga);
  yield takeLatest(actions.CREATE_PAGE, createPageSaga);
  yield takeLatest(actions.UPDATE_PAGE, updatePageSaga);
  yield takeLatest(actions.DELETE_PAGE, deletePageSaga);
  yield takeLatest(actions.PUBLISH_PAGE, publishPageSaga);
  yield takeLatest(actions.UNPUBLISH_PAGE, unpublishPageSaga);
  yield takeLatest(actions.REQUEST_PAGE_REVIEW, requestPageReviewSaga);
  yield takeLatest(actions.DELETE_PAGE_REVIEW, deletePageReviewSaga);
}
