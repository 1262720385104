import { SDK } from "services/koala.service";
import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch all store locations
 */
export function fetchLocations(magicBox) {
  // removing 'cached_data' from query so I can sort by
  // street_address, city, and state
  const magicBoxQuery = magicBox.toQuery().replace("cached_data.", "");
  return SDK.admin.locations.list({ querystring: magicBoxQuery });
}

/**
 * Fetch single store location
 */
export function fetchLocation(id) {
  return SDK.admin.locations.detail(id, {
    querystring: "include[]=oauth_scopes",
  });
}

/**
 * Fetch Locations by Address which is different than magicBox
 */
export function fetchLocationsByAddress(address, magicBox) {
  return SDK.admin.locations.list({
    querystring: `search[address]=${address}&${magicBox.toQuery()}`,
  });
}

/**
 * Update location
 */
export function updateLocation(payload) {
  return SDK.admin.locations.update(payload);
}

/**
 * Fetch Active Devices
 */
export function fetchActiveDevices(id, magicBox) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${
      API.version
    }/store-locations/${id}/active-devices?${magicBox.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Export All Locations
 */
export function exportAllLocations() {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}/store-locations/export`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      Accept: "text/csv",
    },
  });
}
