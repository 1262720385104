import React from "react";
import styled from "styled-components";
import { Select as AntSelect } from "antd";
const { Option } = AntSelect;

interface Props {
  onChange: (value: string) => void;
  options: string[];
  value: string | null;
}

export const Dropdown = ({ options, onChange, value }: Props) => {
  return (
    <Element
      defaultValue={options[0]}
      value={value}
      onChange={(value: string) => void onChange(value)}
    >
      {options.map((option, index) => (
        <Option key={option + index} value={option}>
          {option}
        </Option>
      ))}
    </Element>
  );
};

const Element = styled(AntSelect)`
  width: 100%;
`;
