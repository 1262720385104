import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "../stitches";

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  align?: "leading" | "center" | "trailing";
  as?: string;
  position?: "leading" | "center" | "trailing";
  css?: Stitches.CSS;
  direction?: "horizontal" | "vertical";
  gap?: string;
}

export const Stack = ({
  align = "center",
  position = "leading",
  children,
  css = {},
  direction = "horizontal",
  gap = "$2",
  ...otherProps
}: Props) => (
  <Container
    align={align}
    position={position}
    direction={direction}
    css={{ gap, ...css }}
    {...otherProps}
  >
    {children}
  </Container>
);

const Container = styled("div", {
  display: "flex",
  variants: {
    align: {
      leading: {
        alignItems: "flex-start",
      },
      center: {
        alignItems: "center",
      },
      trailing: {
        alignItems: "flex-end",
      },
    },
    position: {
      leading: {
        justifyContent: "flex-start",
      },
      center: {
        justifyContent: "center",
      },
      trailing: {
        justifyContent: "flex-end",
      },
    },
    direction: {
      horizontal: {
        flexDirection: "row",
      },
      vertical: {
        flexDirection: "column",
      },
    },
  },
});
