import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal } from "antd";
import { modules } from "./schema/types";
import { nanoid } from "nanoid";
import { ModuleType, Module } from "./types";
import { moduleGetRenderDefaults, moduleValidateType } from "./utils";

interface Props {
  onAdd: (id: string, value: Module) => void;
  validItems?: string[] | null;
  label?: string;
}

export const AddModule = ({
  onAdd,
  validItems,
  label = "Add module",
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");

  const moduleIsValid = (id: string, module: ModuleType) => {
    if (validItems) {
      return validItems.includes(id);
    }

    return module?.visible ?? true;
  };

  const matchesSearch = (module: ModuleType) => {
    if (search.length) {
      return (
        module.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        module.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }

    return true;
  };

  const moduleAddItem = (moduleId: string) => {
    const module: ModuleType = modules[moduleId];
    const id = nanoid();
    onAdd(id, moduleGetRenderDefaults(module));
  };

  const modulesList = Object.keys(modules).filter(
    (id: string) =>
      moduleValidateType(modules[id]) &&
      matchesSearch(modules[id]) &&
      moduleIsValid(id, modules[id])
  );

  return (
    <Container>
      <Button
        type="primary"
        ghost={true}
        onClick={() => {
          if (modulesList.length === 1) {
            moduleAddItem(modulesList[0]);
          } else {
            setVisible(true);
          }
        }}
        style={{ width: "100%" }}
      >
        {label}
      </Button>

      <Modal
        open={visible}
        onCancel={() => void setVisible(false)}
        title="Add module"
        footer={[
          <Button key="back" onClick={() => void setVisible(false)}>
            Cancel
          </Button>,
        ]}
      >
        <Content>
          <Search>
            <Input.Search
              placeholder="Search modules..."
              value={search}
              size="large"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Search>

          <List>
            {modulesList
              .filter((id: string) => moduleValidateType(modules[id]))
              .map((id: string) => {
                const moduleDetails: ModuleType = modules[id];

                return (
                  <Row
                    key={moduleDetails.moduleType}
                    onClick={() => {
                      moduleAddItem(id);
                      setVisible(false);
                    }}
                  >
                    <div>
                      <Heading level={4}>{moduleDetails.title}</Heading>

                      <Description>{moduleDetails.description}</Description>
                    </div>

                    {/* {moduleDetails.icon && (
                      <Icon
                        type={moduleDetails.icon}
                        style={{ fontSize: "1.5rem", color: "#40a9ff" }}
                      />
                    )} */}
                  </Row>
                );
              })}
          </List>
        </Content>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 0 0;
`;

const Content = styled.div`
  margin: -1.5rem;
  max-height: 24.25rem;
  overflow: scroll;
  padding: 0 1.5rem 1.5rem;
`;

const Search = styled.div`
  background-color: white;
  padding: 1.5rem 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const List = styled.div`
  margin: -1rem 0;
  padding: 1rem 0 0;
  position: relative;
  z-index: 1;
`;

const Row = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  border-top: 1px solid var(--color--black-900);
  color: var(--color--black-400);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  text-align: left;
  transition: all 0.2s ease;
  width: 100%;

  &:first-of-type {
    border: 0;
  }

  &:hover {
    color: var(--color--blue-400);
  }
`;

const Heading = styled.span`
  display: block;
  color: var(--color--blue-400);
  font-size: 0.875rem;
  font-weight: 500;
`;

const Description = styled.span`
  display: block;
  opacity: 0.8;
  font-size: 0.8125rem;
`;
