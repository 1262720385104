const pageActions = {
  FETCH_PAGES: "/koala-cms/pages/FETCH_PAGES",
  FETCH_PAGE: "/koala-cms/pages/FETCH_PAGE",
  CREATE_PAGE: "/koala-cms/pages/CREATE_PAGE",
  UPDATE_PAGE: "/koala-cms/pages/UPDATE_PAGE",
  DELETE_PAGE: "/koala-cms/pages/DELETE_PAGE",
  PUBLISH_PAGE: "/koala-cms/pages/PUBLISH_PAGE",
  UNPUBLISH_PAGE: "/koala-cms/pages/UNPUBLISH_PAGE",
  REQUEST_PAGE_REVIEW: "/koala-cms/pages/REQUEST_PAGE_REVIEW",
  DELETE_PAGE_REVIEW: "/koala-cms/pages/DELETE_PAGE_REVIEW",

  PAGES_SUCCESS: "/koala-cms/pages/PAGES_SUCCESS",
  PAGES_FAIL: "/koala-cms/pages/PAGES_FAIL",

  PAGE_SUCCESS: "/koala-cms/pages/PAGE_SUCCESS",

  CLEAR_PAGE: "/koala-cms/pages/CLEAR_PAGE",
  CLEAR_PAGES: "/koala-cms/pages/CLEAR_PAGES",

  pagesSuccess: (list, listMeta, magicBox) => ({
    type: pageActions.PAGES_SUCCESS,
    list,
    listMeta,
    magicBox,
  }),

  pageSuccess: (detail: object, workingRevision: object) => ({
    type: pageActions.PAGE_SUCCESS,
    detail,
    workingRevision,
  }),
};

export default pageActions;
