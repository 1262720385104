const locationStatsActions = {
  FETCH_LOCATION_STATS: "/koala-cms/location-stats/FETCH_LOCATION_STATS",
  LOCATION_STATS_SUCCESS: "/koala-cms/location-stats/LOCATION_STATS_SUCCESS",

  fetchlocationStats: (start: string, end: string, organizationId: number) => ({
    type: locationStatsActions.FETCH_LOCATION_STATS,
    payload: { start, end },
    organizationId: organizationId,
  }),

  // Success action for list fetch
  locationStatsSuccess: (list) => ({
    type: locationStatsActions.LOCATION_STATS_SUCCESS,
    list,
  }),
};

export default locationStatsActions;
