import { message } from "antd";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  deviceConfigAliases,
  labelAliases,
} from "constants/deviceConfigAliases";
import actions from "./actions";
import {
  fetchDeviceConfiguration,
  updateDeviceConfig,
} from "services/deviceConfiguration.service";
import { RootState } from "types/app";

// Add all config requests required for app initialization
export function* initializeConfigurationsSaga(action) {
  try {
    const state: RootState = yield select();
    const cmsConfig = state.app.configurations?.["cms-config"]?.config;

    // Conditionally fetch marketing config
    if (cmsConfig?.platforms.web_marketing) {
      const marketingConfigResponse = yield call(
        fetchDeviceConfiguration,
        deviceConfigAliases.MARKETING_CONFIG,
        action.organizationId
      );
      yield put({
        type: actions.FETCH_DEVICE_CONFIG_SUCCESS,
        detail: marketingConfigResponse.data,
      });
    }
  } catch (error) {
    console.log(error, "error");
    yield call(
      message.error,
      "There was an issue initializing the marketing configuration."
    );
    yield put({ type: actions.FETCH_FAILED });
  }
}

export function* fetchDeviceConfigurationSaga(action) {
  try {
    const response = yield call(
      fetchDeviceConfiguration,
      action.label,
      action.organizationId
    );
    yield put({
      type: actions.FETCH_DEVICE_CONFIG_SUCCESS,
      detail: response.data,
    });
  } catch (error) {
    console.log(error, "error");
    yield call(message.error, "There was an issue fetching the data.");
    yield put({ type: actions.FETCH_FAILED });
  }
}

export function* updateDeviceConfigSaga(action) {
  try {
    yield call(
      updateDeviceConfig,
      action.payload,
      action.id,
      action.organizationId
    );
    yield call(
      message.success,
      "You have successfully updated your configuration."
    );
    yield put(
      actions.fetchDeviceConfig(
        labelAliases[`${action.label}`],
        action.organizationId
      )
    );
  } catch (error) {
    console.log(error, "error");
    yield put({ type: actions.UPDATE_FAILED });
    yield call(
      message.error,
      "There was an issue updating your configuration."
    );
  }
}

export default function* rootSaga() {
  yield takeEvery(actions.INITALIZE_CONFIGS, initializeConfigurationsSaga);
  yield takeEvery(actions.FETCH_DEVICE_CONFIG, fetchDeviceConfigurationSaga);
  yield takeLatest(actions.UPDATE_DEVICE_CONFIG, updateDeviceConfigSaga);
}
