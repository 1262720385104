import { put, call, takeLatest } from "redux-saga/effects";

// Services
import { fetchModules } from "services/modules.service";

// Actions
import actions from "./actions";

export function* fetchModulesSaga(action) {
  try {
    // Exclude Link List and Fixed from response
    // TODO - this exclusion needs to be configurable
    // const magicBoxParams = action.magicBox.clone().addFilter('label', '![Fixed,Link List]');
    const response = yield call(fetchModules, action.magicBox);

    yield put({
      type: actions.MODULES_SUCCESS,
      list: response.data.data,
      magicBox: action.magicBox,
    });
  } catch (error) {
    console.log("Failed to fetch modules.");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_MODULES, fetchModulesSaga);
}
