import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

export const List = ({ children }: Props) => {
  return <Element>{children}</Element>;
};

export const Element = styled.div`
  display: flex;
  flex-direction: column;
`;
