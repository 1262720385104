import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch assets
 */
export function fetchAssets(magicBoxParams) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.url}${
      API.routes.assets
    }?${magicBoxParams.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Post asset
 */
export function postAsset({ label, alt_text, file }) {
  const { token_type, access_token } = AccessToken.get();
  const formData = new FormData();
  formData.append("label", label);
  file && formData.append("file", file);
  alt_text && formData.append("alt_text", alt_text);

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.url}${API.routes.assets}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data: formData,
  });
}

/**
 * Update asset
 */
export function updateAsset({ label, alt_text }, uuid) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PATCH",
    url: `${API.scheme}${API.url}${API.routes.assets}/${uuid}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data: {
      label,
      alt_text,
    },
  });
}

/**
 * Delete asset
 */
export function deleteAsset(uuid: string) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.url}${API.routes.assets}/${uuid}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
