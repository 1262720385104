import { CrossSellRuleSet } from "types/cross-sells";

export const TOGGLE_RULE_FILTER = "/koala-cms/crossSell/TOGGLE_RULE_FILTER";
export const TOGGLE_RULE_MODAL = "/koala-cms/crossSell/TOGGLE_RULE_MODAL";

export const toggleRuleModal = (
  open: boolean,
  editing?: CrossSellRuleSet,
  readOnly = false
) => ({
  type: TOGGLE_RULE_MODAL,
  open,
  editing,
  readOnly,
});

export const toggleRuleFilter = (filter: string) => ({
  type: TOGGLE_RULE_FILTER,
  filter,
});
