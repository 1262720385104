import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Modal, Tabs } from "antd";
import { UrlField, UrlType } from "components/web-pages/modules/types";
import { Pages } from "./pages";
import { Attachments } from "./attachments";
import { ExternalLink } from "./external-link";

interface Props {
  onChange: (value: UrlField) => void;
  onCancel: () => void;
  onRemove?: () => void;
  value?: UrlField;
  title?: string;
  actionLabel?: string;
  visible: boolean;
}

export const UrlPicker = ({
  onChange,
  onCancel,
  onRemove,
  value,
  title = "Link",
  actionLabel = "Save",
  visible,
}: Props) => {
  const [activeTab, setActiveTab] = useState("pages");
  const [localValue, setLocalValue] = useState<UrlField>({ label: "" });

  const { url } = value?.reference ?? {};
  const { type = UrlType.local } = localValue?.reference ?? {};

  useEffect(
    function () {
      if (value) {
        setLocalValue(value);
      }
    },
    [value]
  );

  useEffect(
    function () {
      if (type === UrlType.attachment) {
        setActiveTab("attachments");
      } else if (type === UrlType.external) {
        setActiveTab("external-link");
      } else {
        setActiveTab("pages");
      }
    },
    [type]
  );

  let footerOptions = [
    <Button key="back" onClick={() => onCancel()}>
      Cancel
    </Button>,

    <Button
      key="submit"
      type="primary"
      onClick={() => void onChange(localValue)}
    >
      {actionLabel}
    </Button>,
  ];

  if (url && onRemove !== undefined) {
    footerOptions = [
      <Button danger key="remove" onClick={() => onRemove()}>
        Remove link
      </Button>,
      ...footerOptions,
    ];
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title={title}
      footer={footerOptions}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Tabs
        activeKey={activeTab}
        onTabClick={(value) => {
          setActiveTab(value);
        }}
        tabBarStyle={{ marginBottom: 0, padding: "0 1.5rem" }}
        items={[
          {
            label: "Pages",
            key: "pages",
            children: (
              <Container>
                <Pages
                  value={localValue}
                  onChange={(value) => {
                    setLocalValue({
                      ...localValue,
                      ...value,
                    });
                  }}
                />
              </Container>
            ),
          },
          {
            label: "Attachments",
            key: "attachments",
            children: (
              <Container>
                <Attachments
                  onChange={(value) => {
                    setLocalValue({
                      ...localValue,
                      ...value,
                    });
                  }}
                  value={localValue}
                />
              </Container>
            ),
          },
          {
            label: "External link",
            key: "external-link",
            children: (
              <Container>
                <ExternalLink
                  value={localValue}
                  onChange={(value) => {
                    setLocalValue({
                      ...localValue,
                      ...value,
                    });
                  }}
                />
              </Container>
            ),
          },
        ]}
      />
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
`;
