const globalProductModifiersActions = {
  // API Actions
  FETCH_GLOBAL_PRODUCT_MODIFIERS:
    "/koala-cms/globalProductModifiers/FETCH_GLOBAL_PRODUCT_MODIFIERS",
  FETCH_GLOBAL_PRODUCT_MODIFIER:
    "/koala-cms/globalProductModifiers/FETCH_GLOBAL_PRODUCT_MODIFIER",
  UPDATE_GLOBAL_PRODUCT_MODIFIERS:
    "koala-cms/globalProductModifiers/UPDATE_GLOBAL_PRODUCT_MODIFIERS",
  UPDATE_GLOBAL_PRODUCT_MODIFIER_IMAGE:
    "koala-cms/globalProductModifiers/UPDATE_GLOBAL_PRODUCT_MODIFIER_IMAGE",
  FETCH_ALLERGIES: "koala-cms/globalProductModifiers/FETCH_ALLERGIES",

  // Cosmetic Actions
  ATTEMPT_GLOBAL_PRODUCT_MODIFIER_LIST_MODIFICATION:
    "koala-cms/globalProductModifiers/ATTEMPT_GLOBAL_PRODUCT_MODIFIER_LIST_MODIFICATION",
  STOP_LOADING_STATE: "koala-cms/globalProductModifiers/STOP_LOADING_STATE",
  ALLERGIES_SUCCESS: "koala-cms/globalProductModifiers/ALLERGIES_SUCCESS",
  CLEAR_GLOBAL_PRODUCT_MODIFIER_DETAIL:
    "koala-cms/globalProductModifiers/CLEAR_GLOBAL_PRODUCT_MODIFIER_DETAIL",
  GLOBAL_PRODUCT_MODIFIERS_SUCCESS:
    "/koala-cms/globalProductModifiers/GLOBAL_PRODUCT_MODIFIERS_SUCCESS",
  GLOBAL_PRODUCT_MODIFIER_SUCCESS:
    "/koala-cms/globalProductModifiers/GLOBAL_PRODUCT_MODIFIER_SUCCESS",

  fetchGlobalProductModifiers: (magicBox) => ({
    type: globalProductModifiersActions.FETCH_GLOBAL_PRODUCT_MODIFIERS,
    magicBox,
  }),
  fetchGlobalProductModifier: (id) => ({
    type: globalProductModifiersActions.FETCH_GLOBAL_PRODUCT_MODIFIER,
    id,
  }),
  updateGlobalProductModifiers: (payload, id) => ({
    type: globalProductModifiersActions.UPDATE_GLOBAL_PRODUCT_MODIFIERS,
    payload,
    id,
  }),
  fetchGlobalProductModifiersSuccess: (data, magicBox) => ({
    data,
    magicBox,
    type: globalProductModifiersActions.GLOBAL_PRODUCT_MODIFIERS_SUCCESS,
  }),
};

export default globalProductModifiersActions;
