import React, { ReactNode, useMemo, useState } from "react";
import styled from "styled-components";
import { createEditor } from "slate";
import { Slate, withReact } from "slate-react";
import { Descendant } from "slate";
import { Dropdown } from "antd";
import {
  BoldOutlined,
  ItalicOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  FontSizeOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { EditorActions, withLinks } from "./actions";
import { ControlButton, Controls, ControlsSpacer } from "./controls";
import { elementTypes } from "./types";
import { ToolBarMenu } from "./toolbar-menu";
import { ColorList } from "../color";
import { LinkPicker } from "./link-picker";
import { withHistory } from "slate-history";
import { Editor } from "./editor";

type RichText = string;

interface Props {
  onChange: (value: RichText) => void;
  id?: string;
  value: RichText;
  supports: string[];
}

const Supports = ({
  flag,
  children,
  list,
}: {
  flag: string;
  children: ReactNode;
  list: string[];
}) => {
  if (list.includes(flag)) {
    return <>{children}</>;
  }
  return null;
};

export const RichText = ({ value, onChange, supports }: Props) => {
  const [error, setError] = useState(false);

  const initialValue: Descendant[] = useMemo(() => {
    try {
      if (typeof value !== "string") {
        throw `${value} is incorrect value type: expected string`;
      }

      const parsedValue = value
        ? JSON.parse(value)
        : [
            {
              type: elementTypes.paragraph,
              children: [{ text: "" }],
            },
          ];

      return parsedValue;
    } catch (error) {
      console.warn(error);
      setError(true);
      return [];
    }
  }, [error]);

  const [editor] = useState(() =>
    withReact(withLinks(withHistory(createEditor())))
  );

  if (error) {
    return (
      <div>
        <p>This field has corrupt data</p>
        <button
          onClick={() => {
            setError(false);
            onChange("");
          }}
        >
          reset field
        </button>
      </div>
    );
  }

  return (
    <Container>
      <Controls>
        <Supports flag="bold" list={supports}>
          <ControlButton
            onClick={() => void EditorActions.toggleMark(editor, "bold")}
          >
            <BoldOutlined />
          </ControlButton>
        </Supports>

        <Supports flag="italic" list={supports}>
          <ControlButton
            onClick={() => void EditorActions.toggleMark(editor, "italic")}
          >
            <ItalicOutlined />
          </ControlButton>
        </Supports>

        <Supports flag="lists" list={supports}>
          <ControlButton
            onClick={() =>
              void EditorActions.toggleList(editor, elementTypes.unorderedList)
            }
          >
            <UnorderedListOutlined />
          </ControlButton>

          <ControlButton
            onClick={() =>
              void EditorActions.toggleList(editor, elementTypes.orderedList)
            }
          >
            <OrderedListOutlined />
          </ControlButton>
        </Supports>

        <Supports flag="links" list={supports}>
          <LinkPicker
            editor={editor}
            onChange={(value) => void EditorActions.updateLink(editor, value)}
            onRemove={() => void EditorActions.removeLink(editor)}
          />
        </Supports>

        <ControlsSpacer />

        <Supports flag="font" list={supports}>
          <ControlButton>
            <ToolBarMenu
              items={["x-large", "large", "normal", "small"]}
              onChange={(size) => {
                EditorActions.setSize(editor, size);
              }}
            >
              <FontSizeOutlined />
            </ToolBarMenu>
          </ControlButton>
        </Supports>

        <Supports flag="color" list={supports}>
          <ControlButton>
            <Dropdown
              placement="bottomRight"
              trigger={["click"]}
              overlay={
                <ColorList
                  onChange={(color) => {
                    void EditorActions.setColor(editor, color ?? "inherit");
                  }}
                />
              }
            >
              <BgColorsOutlined />
            </Dropdown>
          </ControlButton>
        </Supports>
      </Controls>

      <Slate
        editor={editor}
        value={initialValue}
        onChange={(content) => void onChange(JSON.stringify(content))}
      >
        <Editor supports={supports} />
      </Slate>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid var(--color--black-900);
  border-radius: 0.25rem;
`;
