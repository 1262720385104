import React, { ReactNode } from "react";

import styled from "styled-components";

interface Props {
  children: ReactNode;
  isStatic?: boolean;
}

export function Panel({ children, isStatic = false }: Props) {
  return (
    <Container isStatic={isStatic}>
      <Content isStatic={isStatic}>{children}</Content>
    </Container>
  );
}

const Container = styled.div`
  ${({ isStatic }) => `
  ${
    !isStatic
      ? `
        border-left: 2px solid #e7f0fe;
        margin-left: 0.375rem;
      `
      : ``
  }
  `}
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  ${({ isStatic }) => `
    ${
      !isStatic
        ? `
          padding-left: 1rem;
        `
        : ``
    }
  `}

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-right: 1rem;
`;
