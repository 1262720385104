import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { moduleValidateRendered } from "./utils";
import { AddModule } from "./add";
import { Items } from "./items/items";
import { Modules } from "./types";
import { Empty } from "antd";

interface Props {
  data: Modules | null;
  onChange: (value: Modules) => void;
  onSort: (value: string[]) => void;
  validItems?: string[] | null;
  addLabel?: string;
  level?: number;
}

export function ModulesList({
  data,
  onChange,
  onSort,
  validItems,
  addLabel,
  level = 0,
}: Props) {
  const [valid, setValid] = useState<boolean | null>(null);

  useEffect(function () {
    if (data !== null) {
      setValid(moduleValidateRendered(data));
    }
  }, []);

  if (valid === false) {
    return null;
  }

  return (
    <>
      {(!data || Object.keys(data).length === 0) && (
        <EmptyState
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>Nothing added yet</span>}
        />
      )}

      {data && (
        <Items level={level} data={data} onChange={onChange} onSort={onSort} />
      )}

      <AddModule
        onAdd={(id: string, value) => {
          const modules = data?.modules ?? {};
          const order = data?.order ?? [];

          onChange({
            ...data,
            order: order.concat([id]),
            modules: {
              ...modules,
              [id]: value,
            },
          });
        }}
        label={addLabel}
        validItems={validItems}
      />
    </>
  );
}

const EmptyState = styled(Empty)`
  margin: 2rem 0 0;
`;
