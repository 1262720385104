import React from "react";
import styled from "styled-components";
import { getColumns, getRows } from "./utils";

export enum align {
  "left" = "left",
  "right" = "right",
  "top" = "top",
  "bottom" = "bottom",
  "center" = "center",
  "leftTop" = "left-top",
  "centerTop" = "center-top",
  "rightTop" = "right-top",
  "leftCenter" = "left-center",
  "centerCenter" = "center-center",
  "rightCenter" = "right-center",
  "leftBottom" = "left-bottom",
  "centerBottom" = "center-bottom",
  "rightBottom" = "right-bottom",
}

export const AlignmentIcon = ({ type }) => {
  let path = "";

  switch (type) {
    case align.leftTop:
      path =
        "M0.5 0C0.223858 0 0 0.223858 0 0.5V9.5C0 9.77614 0.223858 10 0.5 10H2.5C2.77614 10 3 9.77614 3 9.5V0.5C3 0.223858 2.77614 0 2.5 0H0.5ZM4 0.5C4 0.223858 4.22386 0 4.5 0H6.5C6.77614 0 7 0.223858 7 0.5V7.5C7 7.77614 6.77614 8 6.5 8H4.5C4.22386 8 4 7.77614 4 7.5V0.5ZM8 0.5C8 0.223858 8.22386 0 8.5 0H10.5C10.7761 0 11 0.223858 11 0.5V5.5C11 5.77614 10.7761 6 10.5 6H8.5C8.22386 6 8 5.77614 8 5.5V0.5Z";
      break;

    case align.centerTop:
      path =
        "M0 0.5C0 0.223858 0.223858 0 0.5 0H2.5C2.77614 0 3 0.223858 3 0.5V5.5C3 5.77614 2.77614 6 2.5 6H0.5C0.223858 6 0 5.77614 0 5.5V0.5ZM4 0.5C4 0.223858 4.22386 0 4.5 0H6.5C6.77614 0 7 0.223858 7 0.5V9.5C7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5V0.5ZM8.5 0C8.22386 0 8 0.223858 8 0.5V5.5C8 5.77614 8.22386 6 8.5 6H10.5C10.7761 6 11 5.77614 11 5.5V0.5C11 0.223858 10.7761 0 10.5 0H8.5Z";
      break;

    case align.rightTop:
      path =
        "M0 0.5C0 0.223862 0.223862 0 0.5 0H2.5C2.77614 0 3 0.223862 3 0.5V5.5C3 5.77614 2.77614 6 2.5 6H0.5C0.223862 6 0 5.77614 0 5.5V0.5ZM4 0.5C4 0.223862 4.22386 0 4.5 0H6.5C6.77614 0 7 0.223862 7 0.5V7.5C7 7.77614 6.77614 8 6.5 8H4.5C4.22386 8 4 7.77614 4 7.5V0.5ZM8.5 0C8.22386 0 8 0.223862 8 0.5V9.5C8 9.77614 8.22386 10 8.5 10H10.5C10.7761 10 11 9.77614 11 9.5V0.5C11 0.223862 10.7761 0 10.5 0H8.5Z";
      break;

    case align.leftCenter:
      path =
        "M0 0.5C0 0.223862 0.223862 0 0.5 0H2.5C2.77614 0 3 0.223862 3 0.5V9.5C3 9.77614 2.77614 10 2.5 10H0.5C0.223862 10 0 9.77614 0 9.5V0.5ZM4 1.5C4 1.22386 4.22386 1 4.5 1H6.5C6.77614 1 7 1.22386 7 1.5V8.5C7 8.77614 6.77614 9 6.5 9H4.5C4.22386 9 4 8.77614 4 8.5V1.5ZM8.5 2C8.22386 2 8 2.22386 8 2.5V7.5C8 7.77614 8.22386 8 8.5 8H10.5C10.7761 8 11 7.77614 11 7.5V2.5C11 2.22386 10.7761 2 10.5 2H8.5Z";
      break;

    case align.centerCenter:
      path =
        "M4 0.5C4 0.223862 4.22386 0 4.5 0H6.5C6.77614 0 7 0.223862 7 0.5V9.5C7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5V0.5ZM0 2.5C0 2.22386 0.223862 2 0.5 2H2.5C2.77614 2 3 2.22386 3 2.5V7.5C3 7.77614 2.77614 8 2.5 8H0.5C0.223862 8 0 7.77614 0 7.5V2.5ZM8.5 2C8.22386 2 8 2.22386 8 2.5V7.5C8 7.77614 8.22386 8 8.5 8H10.5C10.7761 8 11 7.77614 11 7.5V2.5C11 2.22386 10.7761 2 10.5 2H8.5Z";
      break;

    case align.rightCenter:
      path =
        "M8.5 0C8.22386 0 8 0.223862 8 0.5V9.5C8 9.77614 8.22386 10 8.5 10H10.5C10.7761 10 11 9.77614 11 9.5V0.5C11 0.223862 10.7761 0 10.5 0H8.5ZM4 1.5C4 1.22386 4.22386 1 4.5 1H6.5C6.77614 1 7 1.22386 7 1.5V8.5C7 8.77614 6.77614 9 6.5 9H4.5C4.22386 9 4 8.77614 4 8.5V1.5ZM0 2.5C0 2.22386 0.223862 2 0.5 2H2.5C2.77614 2 3 2.22386 3 2.5V7.5C3 7.77614 2.77614 8 2.5 8H0.5C0.223862 8 0 7.77614 0 7.5V2.5Z";
      break;

    case align.leftBottom:
      path =
        "M2.5 10C2.77614 10 3 9.77614 3 9.5V0.5C3 0.223862 2.77614 0 2.5 0H0.5C0.223862 0 0 0.223862 0 0.5V9.5C0 9.77614 0.223862 10 0.5 10H2.5ZM7 9.5C7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5V2.5C4 2.22386 4.22386 2 4.5 2H6.5C6.77614 2 7 2.22386 7 2.5V9.5ZM11 9.5C11 9.77614 10.7761 10 10.5 10H8.5C8.22386 10 8 9.77614 8 9.5V4.5C8 4.22386 8.22386 4 8.5 4H10.5C10.7761 4 11 4.22386 11 4.5V9.5Z";
      break;

    case align.centerBottom:
      path =
        "M7 9.5C7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5V0.5C4 0.223862 4.22386 0 4.5 0H6.5C6.77614 0 7 0.223862 7 0.5V9.5ZM11 9.5C11 9.77614 10.7761 10 10.5 10H8.5C8.22386 10 8 9.77614 8 9.5V4.5C8 4.22386 8.22386 4 8.5 4H10.5C10.7761 4 11 4.22386 11 4.5V9.5ZM2.5 10C2.77614 10 3 9.77614 3 9.5V4.5C3 4.22386 2.77614 4 2.5 4H0.5C0.223862 4 0 4.22386 0 4.5V9.5C0 9.77614 0.223862 10 0.5 10H2.5Z";
      break;

    case align.rightBottom:
      path =
        "M10.5 10C10.7761 10 11 9.77614 11 9.5V0.5C11 0.223862 10.7761 0 10.5 0H8.5C8.22386 0 8 0.223862 8 0.5V9.5C8 9.77614 8.22386 10 8.5 10H10.5ZM7 9.5C7 9.77614 6.77614 10 6.5 10H4.5C4.22386 10 4 9.77614 4 9.5V2.5C4 2.22386 4.22386 2 4.5 2H6.5C6.77614 2 7 2.22386 7 2.5V9.5ZM3 9.5C3 9.77614 2.77614 10 2.5 10H0.5C0.223862 10 0 9.77614 0 9.5V4.5C0 4.22386 0.223862 4 0.5 4H2.5C2.77614 4 3 4.22386 3 4.5V9.5Z";
      break;

    default:
      break;
  }

  return (
    <AlignmentIconElement
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={path}
        fill="currentColor"
      />
    </AlignmentIconElement>
  );
};

interface Props {
  onChange: (value: align) => void;
  value: align;
  supports?: string[];
}

export const Alignment = ({
  onChange,
  supports = ["horizontal", "vertical"],
  value = align.centerCenter,
}: Props) => {
  const values = value.split("-");

  let coordinates = [
    [align.left, align.top],
    [align.center, align.top],
    [align.right, align.top],

    [align.left, align.center],
    [align.center, align.center],
    [align.right, align.center],

    [align.left, align.bottom],
    [align.center, align.bottom],
    [align.right, align.bottom],
  ];

  if (!supports.includes("horizontal")) {
    coordinates = [
      [align.center, align.top],
      [align.center, align.center],
      [align.center, align.bottom],
    ];
  }

  if (!supports.includes("vertical")) {
    coordinates = [
      [align.left, align.center],
      [align.center, align.center],
      [align.right, align.center],
    ];
  }

  return (
    <Container supports={supports}>
      {coordinates.map((item, index) => {
        const isActive =
          align[values[0]] === item[0] && align[values[1]] === item[1];

        return (
          <Point
            key={index}
            isActive={isActive}
            onClick={() => {
              const updateValue = [item[0], item[1]].join("-") as align;

              onChange(updateValue);
            }}
            title={item.join("-")}
          >
            <AlignmentIcon type={[item[0], item[1]].join("-")} />

            <svg width="4" height="4" viewBox="0 0 4 4">
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Point>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid var(--color--black-800);
  align-items: center;
  justify-items: center;
  border-radius: 0.375rem;
  display: grid;
  overflow: hidden;
  padding: 1px;
  width: 4rem;

  ${({ supports }) => `
   grid-template-rows: ${getRows(supports)};
   grid-template-columns: ${getColumns(supports)};
  `};
`;

const Point = styled.button`
  --alignment-icon-element--opacity: 0;
  appearance: none;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  place-items: center;
  height: 1.25rem;
  transition: all 0.1s ease;
  width: 1.25rem;
  padding: 0;

  ${({ isActive }) => `
    background-color: ${isActive ? "var(--color--blue-400)" : "transparent"};  
    color: ${isActive ? "white" : "var(--color--black-400)"};

    --alignment-icon-element--opacity: ${isActive ? 1 : 0};
    --alignment-default-icon-element--opacity:  ${isActive ? 0 : 1};
  `}

  svg {
    display: block;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.075s ease;

    &:nth-child(2) {
      opacity: var(--alignment-default-icon-element--opacity);
    }
  }

  &:focus {
    outline: 1px solid black;
  }

  &:hover {
    --alignment-icon-element--opacity: 1;
    --alignment-default-icon-element--opacity: 0;
  }
`;

const AlignmentIconElement = styled.svg`
  opacity: var(--alignment-icon-element--opacity, 0);
`;
