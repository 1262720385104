import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "../stitches";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  children: React.ReactNode;
  css?: Stitches.CSS;
  variant?: "primary" | "subtle";
}

export function Button({
  children,
  css,
  variant = "primary",
  ...props
}: Props) {
  return (
    <Container css={css} variant={variant} {...props}>
      {children}
    </Container>
  );
}

const Container = styled("button", {
  appearance: "none",
  background: "none",
  border: "none",
  borderRadius: "$md",
  cursor: "pointer",
  fontFamily: "$body",
  fontSize: "$text200",
  fontWeight: "400",
  lineHeight: "$none",
  transition: "all .2s ease",

  variants: {
    variant: {
      primary: {
        backgroundColor: "$shade800",
        color: "$shade100",
        letterSpacing: "$extreme",
        height: "$12",
        padding: "0 $2",
        textTransform: "uppercase",

        "&:hover": { backgroundColor: "$shade900" },
      },
      subtle: {
        color: "$shade500",
        fontFamily: "$system",
        padding: "$2",
        textDecoration: "underline",

        "&:hover": { color: "$shade800" },
      },
    },
    disabled: {
      true: {
        cursor: "default",
        opacity: 0.5,

        "&:hover": { backgroundColor: "$shade800" },
      },
    },
  },
});
