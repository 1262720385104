const tagsActions = {
  FETCH_TAGS: "/koala-cms/tags/FETCH_TAGS",
  FETCH_TAGS_SUCCESS: "/koala-cms/tags/FETCH_TAGS_SUCCESS",
  FETCH_TAGS_FAIL: "/koala-cms/tags/FETCH_TAGS_FAIL",
  POST_TAG: "/koala-cms/tags/POST_TAG",
  POST_TAG_SUCCESS: "/koala-cms/tags/POST_TAG_SUCCESS",
  POST_TAG_FAIL: "/koala-cms/tags/POST_TAG_FAIL",
  DELETE_TAG: "/koala-cms/tags/DELETE_TAG",
  DELETE_TAG_SUCCESS: "/koala-cms/tags/DELETE_TAG_SUCCESS",
  DELETE_TAG_FAIL: "/koala-cms/tags/DELETE_TAG_FAIL",
  UPDATE_TAG: "/koala-cms/tags/UPDATE_TAG",
  UPDATE_TAG_SUCCESS: "/koala-cms/tags/UPDATE_TAG_SUCCESS",
  UPDATE_TAG_FAIL: "/koala-cms/tags/UPDATE_TAG_FAIL",
  UPDATE_TAG_ICON_IMAGE: "/koala-cms/tags/UPDATE_TAG_ICON_IMAGE",
  UPDATE_TAG_ICON_IMAGE_FAIL: "/koala-cms/tags/UPDATE_TAG_ICON_IMAGE_FAIL",

  fetchTags: (tagType: string) => ({
    type: tagsActions.FETCH_TAGS,
    tagType,
  }),

  postTag: (payload: any) => ({
    type: tagsActions.POST_TAG,
    payload,
  }),

  deleteTag: (id: number, tagType: string) => ({
    type: tagsActions.DELETE_TAG,
    id,
    tagType,
  }),

  updateTag: (id: number, payload: any) => ({
    type: tagsActions.UPDATE_TAG,
    id,
    payload,
  }),

  updateTagIconImage: (id: number, imageFile: any) => ({
    type: tagsActions.UPDATE_TAG_ICON_IMAGE,
    id,
    imageFile,
  }),
};

export default tagsActions;
