import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  onClick: () => void;
}
export const DeletePage = ({ onClick }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => void setVisible(true)}>Delete page</Button>

      <Modal
        title="Delete page"
        open={visible}
        onCancel={() => void setVisible(false)}
        onOk={() => {
          setVisible(false);
          onClick();
        }}
        okType="danger"
        okText="Delete"
      >
        <p>
          Are you sure you want to delete this page? Any references to this page
          will need to be removed manually.
        </p>
      </Modal>
    </>
  );
};

const Button = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  color: var(--color--red-400);
  cursor: pointer;
`;
