import { all } from "redux-saga/effects";
import activeDevicesSagas from "./activeDevices/saga";
import allergensSagas from "./allergens/saga";
import assetsSagas from "./assets/saga";
import authSagas from "./auth/saga";
import categoriesSagas from "./_deprecated/categories/saga";
import configurationsSagas from "./configurations/saga";
import crossSellSagas from "./crossSell/saga";
import customersSagas from "./customers/saga";
import deviceConfigSagas from "./_deprecated/deviceConfiguration/saga";
import globalMenuSagas from "./globalMenu/saga";
import globalProductModifiersSagas from "./globalProductModifiers/saga";
import locationAttributePositionsSagas from "./locationAttributePositions/saga";
import locationSagas from "./locations/saga";
import locationStatsSagas from "./locationStats/saga";
import meSagas from "./me/saga";
import modulesSagas from "./_deprecated/modules/saga";
import organizationSagas from "./organization/saga";
import orgConfigSagas from "./orgConfig/saga";
import pagesSagas from "./pages/saga";
import persistentTemplatesSagas from "./_deprecated/persistentTemplates/saga";
import revisionsSagas from "./revisions/saga";
import tagsSagas from "./tags/saga";
import usersSagas from "./users/saga";

export default function* rootSaga(getState) {
  yield all([
    activeDevicesSagas(),
    authSagas(),
    configurationsSagas(),
    crossSellSagas(),
    usersSagas(),
    customersSagas(),
    meSagas(),
    pagesSagas(),
    categoriesSagas(),
    assetsSagas(),
    modulesSagas(),
    revisionsSagas(),
    persistentTemplatesSagas(),
    locationSagas(),
    locationStatsSagas(),
    locationAttributePositionsSagas(),
    globalMenuSagas(),
    globalProductModifiersSagas(),
    deviceConfigSagas(),
    allergensSagas(),
    organizationSagas(),
    orgConfigSagas(),
    tagsSagas(),
  ]);
}
