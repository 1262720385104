import button from "./button.json";
import column from "./column.json";
import columns from "./columns.json";
import container from "./container.json";
import form from "./form/form.json";
import formEmail from "./form/email.json";
import formLocations from "./form/locations.json";
import formMessage from "./form/message.json";
import formPhone from "./form/phone.json";
import formText from "./form/text.json";
import hero from "./hero.json";
import iframe from "./iframe.json";
import image from "./image.json";
import link from "./link.json";
import linkList from "./link-list.json";
import popularItems from "./popular-items.json";
import slide from "./slide.json";
import slider from "./slider.json";
import text from "./text.json";

export const modules = {
  button,
  column,
  columns,
  container,
  form,
  formEmail,
  formLocations,
  formMessage,
  formPhone,
  formText,
  hero,
  iframe,
  image,
  link,
  linkList,
  popularItems,
  slide,
  slider,
  text,
};
