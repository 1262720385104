import { debounce } from "lodash";
import { useRef, useMemo, useEffect } from "react";

type DebouncedListener = {
  ref: React.RefObject<HTMLElement | undefined>;
  listener: any;
};
/**
 * Provides an abstraction to create debounced events in a react style way
 *
 * The following will create a debounced event that fires every 1000ms a performs
 * some action that should happen. An optional "refreshToken" boolean can sent
 * to restart the memoized throttle.
 *
 * ```
 * const { ref, listener } = useDebouncedListener(
    () => {
      // do something
    },
    refreshToken,
    1000
  );
 * ```
  * @returns DebouncedListener
  * @param duration
 * @param shouldRefresh
 */
export const useDebouncedListener = (
  callback: (...args: any[]) => void,
  duration: number,
  shouldRefresh: boolean = false
): DebouncedListener => {
  const ref: React.RefObject<HTMLElement | undefined> = useRef();

  const listener = useMemo(
    () => debounce(callback, duration),
    [callback, duration]
  );

  useEffect(() => {
    if (shouldRefresh) {
      listener();
    }
  }, [listener, shouldRefresh]);

  return {
    ref,
    listener,
  };
};
