const deviceConfigActions = {
  INITALIZE_CONFIGS: "/koala-cms/deviceConfiguration/INITALIZE_CONFIGS",

  FETCH_DEVICE_CONFIG: "/koala-cms/deviceConfiguration/FETCH_DEVICE_CONFIG",
  FETCH_DEVICE_CONFIG_SUCCESS:
    "/koala-cms/deviceConfiguration/FETCH_DEVICE_CONFIG_SUCCESS",
  UPDATE_DEVICE_CONFIG: "/koala-cms/deviceConfiguration/UPDATE_DEVICE_CONFIG",
  CLEAR_DEVICE_CONFIG: "/koala-cms/deviceConfiguration/CLEAR_DEVICE_CONFIG",

  FETCH_FAILED: "/koala-cms/deviceConfiguration/FETCH_FAILED",
  UPDATE_SUCCESS: "/koala-cms/deviceConfiguration/UPDATE_SUCCESS",
  UPDATE_FAILED: "/koala-cms/deviceConfiguration/UPDATE_FAILED",

  /**
   * Conditionally initialize Marketing API config "marketing-config". Do not use, to be removed
   * when the Marketing API is turned off
   *
   * @deprecated
   * @param organizationId number
   * @returns void
   */
  initializeConfigs: (organizationId: number) => ({
    type: deviceConfigActions.INITALIZE_CONFIGS,
    organizationId,
  }),

  /**
   * Fetch a Marketing API config. Do not use, to be removed
   * when the Marketing API is turned off
   *
   * @deprecated
   * @param organizationId number
   * @returns void
   */
  fetchDeviceConfig: (label: string, organizationId: number) => ({
    type: deviceConfigActions.FETCH_DEVICE_CONFIG,
    label,
    organizationId,
  }),

  /**
   * Update a Marketing API config. Do not use, to be removed
   * when the Marketing API is turned off
   *
   * @deprecated
   * @param organizationId number
   * @returns void
   */
  updateDeviceConfig: (
    payload: object,
    id: number,
    label: string,
    organizationId: number
  ) => ({
    type: deviceConfigActions.UPDATE_DEVICE_CONFIG,
    payload,
    id,
    label,
    organizationId,
  }),
};

export default deviceConfigActions;
