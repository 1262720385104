import React, { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Panel } from "./panel";

export const SectionContext = React.createContext<{
  shouldOpen?: string;
  shouldHighlight?: string;
  resetOpened: () => void;
  setShouldHighlight: (id: string) => void;
}>({
  resetOpened: () => undefined,
  setShouldHighlight: () => undefined,
});

export const useSectionContext = () => useContext(SectionContext);

interface Props {
  actions?: ReactNode;
  children: ReactNode;
  icon?: ReactNode | null;
  iconColor?: string;
  id?: string;
  isStatic?: boolean;
  label: string;
  prepend?: ReactNode;
  subLabel?: string;
  isDragging?: boolean;
}

export const Section = ({
  actions,
  children,
  icon,
  iconColor,
  id,
  isStatic = false,
  label,
  prepend,
  subLabel,
  isDragging = false,
}: Props) => {
  const { shouldOpen, shouldHighlight, resetOpened, setShouldHighlight } =
    useSectionContext();
  const [isVisible, setVisible] = useState(false);

  useEffect(
    function () {
      if (id && shouldOpen === id) {
        setVisible((state) => !state);
        resetOpened();
      }

      // else {
      //   setVisible(false);
      // }
    },
    [shouldOpen]
  );

  return (
    <Container
      onMouseEnter={function () {
        if (id) {
          setShouldHighlight(id);
        }
      }}
    >
      <Button isDragging={isDragging} onClick={() => setVisible(!isVisible)}>
        {prepend && icon && (
          <Icon>
            {icon && <IconItem>{icon}</IconItem>}
            {prepend && <IconItem>{prepend}</IconItem>}
          </Icon>
        )}

        {!prepend && icon && (
          <IconItem style={iconColor ? { color: iconColor } : {}}>
            {icon}
          </IconItem>
        )}
        {prepend && !icon && <IconItem>{prepend}</IconItem>}

        <Label isActive={(id && shouldHighlight === id) || isVisible}>
          <LabelTitle>{label}</LabelTitle>

          {subLabel && <LabelSubtitle>{subLabel}</LabelSubtitle>}
        </Label>

        {actions && <Actions>{actions}</Actions>}

        <Arrow
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          aria-hidden={true}
          isVisible={isVisible}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5859 5.00004L0.439453 0.853591L1.14656 0.146484L6.00011 5.00004L1.14656 9.85359L0.439453 9.14648L4.5859 5.00004Z"
            fill="currentColor"
          />
        </Arrow>
      </Button>

      {isVisible && <Panel isStatic={isStatic}>{children}</Panel>}
    </Container>
  );
};

interface SectionsProps {
  children: ReactNode;
}

export const Sections = ({ children }: SectionsProps) => {
  return <>{children}</>;
};

const Container = styled.div`
  border-bottom: 1px solid var(--color--black-900);
  margin: 0 -1rem 0 0;
  transition: all 0.1s ease;
`;

const IconItem = styled.div`
  color: var(--color--blue-400);
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  &:nth-child(1) {
    pointer-events: none;
  }

  &:nth-child(2) {
    opacity: 0;
  }
`;

const Icon = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;

  &:hover ${IconItem} {
    &:nth-child(1) {
      opacity: 0;
    }

    &:nth-child(2) {
      opacity: 1;
    }
  }
`;

export const Actions = styled.div`
  margin: 0 0 0 auto;
  opacity: 0;
  transition: all 0.2s ease;
`;

const Button = styled.div`
  ${({ isDragging }) =>
    isDragging
      ? `background-color: var(--color--blue-900, transparent);`
      : `background-color: transparent;`}

  align-items: center;
  appearance: none;
  border: 0;
  display: flex;
  font-weight: 500;
  gap: 0.5rem;
  height: 3rem;
  justify-content: space-between;
  margin-left: -1rem;
  padding: 0 1rem;
  text-align: left;
  transition: all 0.2s ease;

  svg {
    flex: 0 0 6px;
  }

  &:hover {
    color: var(--color--blue-400);
  }

  &:hover ${Actions} {
    ${({ isDragging }) => (isDragging ? `opacity: 0` : `opacity: 1`)}
  }
`;

const Label = styled.label`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  gap: 0.125rem;
  line-height: 1.1;
  overflow: hidden;

  ${({ isActive }) => `
    color: ${isActive ? "var(--color--blue-400)" : ""};
  `}
`;

const LabelTitle = styled.span`
  flex: 1 1 auto;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelSubtitle = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.6;
`;

const Arrow = styled.svg`
  ${({ isVisible }) => `
    transform: rotate(${isVisible ? 90 : 0}deg);
  `}

  transition: all .2s ease;
`;
