import { Button, Modal, Tabs } from "antd";
import find from "lodash/find";
import React from "react";
import AssetForm from "components/assets/form";
import { SearchAssets } from "../search-assets";
import AssetHawk from "redux/assets/hawk";
import { StyledPreview } from "./styles";
import VideoInput from "../../modules/forms/hero/videoInput";
import { OptionProps } from "antd/lib/select";
import { FixMeAny } from "types/fix-me-any";

export interface ImageAsset {
  uuid: string;
  url: string;
  label: string;
  type: string;
}

interface StoreProps {
  fetchAssets: (mb: any) => void;
  pushAsset: (obj?: object) => void;
  postAsset: () => void;
  clearAsset: () => void;
  clearAllAssets: () => void;
  assetsMB: any;
  assetsList: Array<{}>;
  assetDetail?: any; // TODO - type
  enableVideo: boolean;
}
interface PassProps {
  visible: boolean;
  modalType?: string;
  onSelect: (value: ImageAsset) => void;
  onCancel: () => void;
  onRemove?: () => void;
  hasImage?: boolean;
}
class AssetManager extends React.Component<StoreProps & PassProps> {
  componentWillUnmount() {
    this.props.clearAllAssets();
  }

  onSearch = (term: string) => {
    const magicBox = this.props.assetsMB.clone().addFilter("label", `~${term}`);

    this.props.fetchAssets(magicBox);
  };

  onSelect = (value: string, option: OptionProps) => {
    const { assetsList, pushAsset } = this.props;
    const selected = find(assetsList, { uuid: option.key });
    pushAsset(selected);
  };

  onSubmitVideo = (videoLink: string) => {
    this.props.pushAsset({
      url: videoLink,
      label: null,
      type: "data.desktop_video_url",
    });
  };

  render() {
    const {
      onSelect,
      onRemove,
      postAsset,
      assetsList,
      onCancel,
      visible,
      assetDetail,
      clearAsset,
      enableVideo,
      modalType,
      hasImage,
    } = this.props;

    let footer = [
      <Button key="back" onClick={() => onCancel()}>
        Cancel
      </Button>,
      <Button
        key="submit"
        type="primary"
        disabled={!assetDetail}
        onClick={() => {
          if (assetDetail) {
            onSelect({
              uuid: assetDetail.uuid,
              url: assetDetail.url,
              label: assetDetail.label,
              type: assetDetail.type || modalType,
            });
          } else {
            alert(
              "Please select an image, either by searching or by uploading a new one."
            );
          }
        }}
      >
        Apply Asset
      </Button>,
    ];

    if (onRemove && hasImage) {
      footer = [
        <Button
          key="remove"
          danger
          onClick={() => {
            onRemove();
            onCancel();
          }}
        >
          Remove Image
        </Button>,
        ...footer,
      ];
    }

    return (
      <Modal open={visible} onCancel={onCancel} footer={footer}>
        <Tabs defaultActiveKey="0" onChange={clearAsset}>
          <Tabs.TabPane tab="Search Images" key="0">
            <SearchAssets
              onSelect={this.onSelect}
              onSearch={this.onSearch}
              data={assetsList}
              placeholder="Search images by title..."
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Upload Image" key="1">
            {!assetDetail ? (
              <AssetForm submitText="Upload image" onSubmit={postAsset} />
            ) : (
              <button onClick={clearAsset} type="button">
                Upload new asset
              </button>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Add Video" key="2" disabled={!enableVideo}>
            <VideoInput onSubmitVideo={this.onSubmitVideo} />
          </Tabs.TabPane>
        </Tabs>

        {/* Selected asset preview */}
        {assetDetail && assetDetail.type !== "video" && (
          <StyledPreview>
            <p>Selected Image:</p>
            <img
              src={assetDetail.url}
              alt={assetDetail.alt_text}
              title={assetDetail.label}
            />
          </StyledPreview>
        )}
      </Modal>
    );
  }
}

export default AssetHawk(AssetManager) as FixMeAny; // Hawk type issue
