import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  label?: string;
  level?: number;
}

export const Container = ({ label, level, children }: Props) => {
  return (
    <Element level={level}>
      {label && <Label>{label}</Label>}

      <Content level={level}>{children}</Content>
    </Element>
  );
};

const Element = styled.div`
  border-top: 1px solid var(--color--black-900);
  padding: 0 1rem 0 0;
  margin: 1rem -1rem 0 0;

  &:first-of-type {
    border: 0;
    margin-top: 0;
  }
`;

const Content = styled.div``;

export const Label = styled.label`
  align-items: center;
  display: flex;
  font-size: 0.8725rem;
  font-weight: 500;
  gap: 0.5rem;

  line-height: 1.1;
  padding: 1rem 0 0.5rem 0.075rem;
`;
