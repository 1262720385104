/* Permission Level options */

export const GROUPS = {
  ADMIN: "group:organization_admin",
  USER: "group:organization_user",
};

export const PERMISSIONS = {
  MANAGE_PAGES: "pages:manage",
  MANAGE_TEMPLATES: "templates:manage",
  MANAGE_REVIEWS: "review_requests:manage",
  MANAGE_PERMISSIONS: "permissions:manage",
  PUBLISH_PAGES: "pages:publish",
  APPROVE_REVIEWS: "review_requests:approve",
  MANAGE_ASSETS: "assets:manage",
  MANAGE_NAVIGATION: "persistent_templates:manage",
  MANAGE_MODULES: "persistent_templates:manage",
  DEVICE_CONFIGURATION: "device_configurations:manage",
};

export const SCOPE_GROUPS = {
  USERS: "Users",
  MARKETING: "Marketing Content",
  ORDERING: "Ordering",
  OTHER: "Other Permissions",
};

export const OAUTH_SCOPES = {
  BASE: [
    PERMISSIONS.MANAGE_PAGES,
    PERMISSIONS.MANAGE_TEMPLATES,
    PERMISSIONS.MANAGE_REVIEWS,
  ],
  UNIQUE: {
    MANAGE_ORDERING: {
      label: "Manage Configurations",
      group: SCOPE_GROUPS.ORDERING,
      description: "Allows user to manage device configrations.",
      scopes: [PERMISSIONS.DEVICE_CONFIGURATION],
    },
    MANAGE_USERS: {
      label: "Manage Users",
      group: SCOPE_GROUPS.USERS,
      description: "Allows user to create, modify, or delete other users.",
      scopes: [PERMISSIONS.MANAGE_PERMISSIONS],
    },
    MANAGE_PAGES: {
      group: SCOPE_GROUPS.MARKETING,
      label: "Manage Pages",
      scopes: [PERMISSIONS.MANAGE_PAGES],
    },
    PUBLISH_PAGES: {
      label: "Publish/Unpublish Pages",
      group: SCOPE_GROUPS.MARKETING,
      description: "Allows user to publish or unpublish pages.",
      scopes: [PERMISSIONS.PUBLISH_PAGES, PERMISSIONS.APPROVE_REVIEWS],
    },
    MANAGE_ASSETS: {
      group: SCOPE_GROUPS.OTHER,
      label: "Manage Assets",
      scopes: [PERMISSIONS.MANAGE_ASSETS],
    },
    MANAGE_NAVIGATION: {
      group: SCOPE_GROUPS.MARKETING,
      label: "Manage Navigation",
      scopes: [PERMISSIONS.MANAGE_NAVIGATION],
    },
    MANAGE_GLOBAL_MODULES: {
      group: SCOPE_GROUPS.MARKETING,
      label: "Manage Global Modules",
      scopes: [PERMISSIONS.MANAGE_MODULES],
    },
  },
};
