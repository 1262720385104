import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

// Actions
import actions from "./actions";

// Services
import {
  fetchOrganization,
  updateOrganization,
} from "../../services/organizations.service";

export function* fetchOrganizationSaga(action) {
  try {
    const response = yield call(fetchOrganization, action.id);

    yield put({
      type: actions.ORGANIZATION_SUCCESS,
      detail: response.data.data,
    });
  } catch (error) {
    console.log("error:", error);
    yield call(message.error, "There was an issue fetching the organization");
  }
}

export function* updateOrganizationSaga(action) {
  try {
    const response = yield call(updateOrganization, action.payload, action.id);

    // Refetch org to ensure stats are there...
    yield put(actions.fetchOrganization(response.data.data.id));

    // Display success
    yield call(message.success, "Organization successfully updated");
  } catch (error) {
    yield call(message.error, "There was a problem updating your organization");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ORGANIZATION, fetchOrganizationSaga);
  yield takeLatest(actions.UPDATE_ORGANIZATION, updateOrganizationSaga);
}
