// Types
import { ICmsConfig } from "types/constants/config";

export const DEFAULT_CONFIG: ICmsConfig = {
  // Web Marketing
  WEB_MARKETING: false,

  // Global Products, Global Product Modifiers, Tags
  GLOBAL_MENU: {
    PRODUCTS: false,
    MODIFIERS: false,
    TAGS: false,
  },

  // Locations
  LOCATIONS: {
    ATTRIBUTES: false,
    KIOSK: false,
  },

  GLOBAL_CONFIG: {
    BASE: false,
  },

  // Ordering Config
  ORDERING_CONFIG: {
    WEB: false,
    KIOSK: false,
    MOBILE_IOS: false,
  },

  // Cross Sell
  CROSSSELL: {
    BASE: false,
  },

  // Settings
  SETTINGS: {
    BASE: false,
    INTEGRATIONS: false,
  },
};
