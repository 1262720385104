import { call, put, takeLatest } from "redux-saga/effects";
import { fetchLocationAttributePositions } from "services/locationAttributePositions.service";
import actions from "./actions";

export function* fetchLocationAttributePositionsSaga() {
  try {
    const response = yield call(fetchLocationAttributePositions);
    yield put(actions.storeLocationAttributePositions(response.data.data));
  } catch (error) {
    yield put(actions.storeLocationAttributePositions([]));
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.FETCH_LOCATION_ATTRIBUTE_POSITIONS,
    fetchLocationAttributePositionsSaga
  );
}
