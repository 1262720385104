import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";

import configureStore from "./redux/store";

// Constants
import { IEnv } from "types/constants/env";

// Component
import App from "./containers/App";
import get from "lodash/get";

// Extend window interface
declare global {
  interface Window {
    gtag?: any;
    grecaptcha?:
      | {
          enterprise?: undefined;
          ready: (callback: () => any) => void;
          execute: (siteKey: string, options: any) => Promise<string>;
        }
      | {
          ready?: undefined;
          execute?: undefined;
          enterprise: {
            ready: (callback: () => any) => void;
            execute: (siteKey: string, options: any) => Promise<string>;
          };
        };
    koalaBrandConfig: IEnv;
    koalaUnifiedBrandConfig: IEnv;
  }
}

const history = createBrowserHistory();

// Initialize store
const store = configureStore({}, history);

history.listen((location, action) => {
  window?.gtag("event", "page_view", {
    page_path: location.pathname,
    organization_id: get(store.getState(), "app.me.detail.organization_id"), //Attach user's organization id, if possible
  });
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root") as HTMLElement
);
