import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

// Types
import {
  ILocationAttributePost,
  ILocationAttributeUpdate,
} from "types/locations";

/**
 * Fetch a store location's attributes
 * TODO - move into SDK
 */
export function fetchLocationAttributes(id) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}/store-locations/${id}/attributes`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

export function postLocationAttribute(data: ILocationAttributePost) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}/${API.version}/location-attributes`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data,
  });
}

export function updateLocationAttribute(data: ILocationAttributeUpdate) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.urlBase}/${API.version}/location-attributes/${data.id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data,
  });
}

export function deleteLocationAttribute(id: number) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.urlBase}/${API.version}/location-attributes/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
