// Utils
import { MagicBoxParams, parseSortsFromTable } from "utils/magicBox";

// Actions
import actions from "./actions";
import {
  resourceDefaults as rd,
  resourceKeys as rk,
} from "../../constants/resources/keys";

const initialState = {
  loading: false,
  list: [],
  detail: null,
  listMeta: {},
  magicBox: new MagicBoxParams()
    .setIncludes(rd[rk.customers].includes)
    .setSorts(parseSortsFromTable(rd[rk.customers].sorts))
    .setFilters(rd[rk.customers].filters),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_CUSTOMERS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
        listMeta: action.listMeta,
        magicBox: action.magicBox,
      });
    case actions.CLEAR_CUSTOMERS:
      return Object.assign({}, state, {
        loading: false,
        list: [],
      });
    case actions.RESET_CUSTOMERS:
      return Object.assign({}, state, {
        loading: false,
        list: [],
        listMeta: action.listMeta,
        magicBox: initialState.magicBox.clone(),
      });
    case actions.CUSTOMERS_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    default:
      return state;
  }
}
