import { Location } from "@chowly/koala-sdk-js";
import { message, notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  deleteLocationAttribute,
  fetchLocationAttributes,
  postLocationAttribute,
  updateLocationAttribute,
} from "services/locationAttributes.service";
import {
  fetchActiveDevices,
  fetchLocation,
  fetchLocations,
  fetchLocationsByAddress,
  updateLocation,
} from "services/locations.service";
import { IMeta } from "types/app";
import actions from "./actions";

export function* fetchLocationsSaga(action) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const response: { data: Location[]; meta: IMeta } = yield call(
      fetchLocations,
      action.magicBox
    );
    yield put({
      type: actions.LOCATIONS_SUCCESS,
      list: response.data,
      meta: response.meta,
      magicBox: action.magicBox,
    });
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(message.error, "There was an error getting the locations.");
  }
}

export function* fetchLocationsByAddressSaga(action) {
  try {
    const response = yield call(
      fetchLocationsByAddress,
      action.address,
      action.magicBox
    );
    yield put({
      type: actions.LOCATIONS_SUCCESS,
      list: response.data,
      meta: response.meta,
      magicBox: action.magicBox,
    });
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(message.error, "There was an error getting the locations.");
  }
}

export function* fetchLocationSaga(action) {
  try {
    const response = yield call(fetchLocation, action.id);
    yield put({ type: actions.LOCATION_SUCCESS, detail: response });
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(message.error, "There was an error getting the location.");
  }
}

export function* updateLocationSaga(action) {
  try {
    // Update the location
    const payload = Object.assign({}, action.payload, { id: action.id });
    yield call(updateLocation, payload);

    // Success Alert
    yield call(notification.success, {
      message: "Location updated",
      description: "Changes may take up to an hour to fully propagate.",
    });

    // Stop spinner and update the ui with latest information
    yield put({ type: actions.LOCATION_SUCCESS });

    yield put(actions.fetchLocation(action.id));
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(message.error, "There was an error updating the location");
  }
}

export function* fetchDevices(action) {
  try {
    const response = yield call(fetchActiveDevices, action.id, action.magicBox);
    yield put({
      type: actions.ACTIVE_DEVICES_SUCCESS,
      activeDevices: response.data.data,
    });
  } catch (error) {
    console.log("Failed to fetch location.", error);
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(message.error, "There was an error getting the location.");
  }
}

/*
 * Location Attributes
 **/
export function* fetchLocationAttributesSaga(action) {
  try {
    const response = yield call(fetchLocationAttributes, action.id);
    yield put(actions.setLocationAttributes(response.data.data));
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(
      message.error,
      "There was an error getting the location attributes."
    );
  }
}

export function* postLocationAttributeSaga(action) {
  try {
    yield call(postLocationAttribute, action.attribute);
    yield put(
      actions.fetchLocationAttributes(action.attribute.store_location_id)
    );
    yield call(message.success, "Attribute successfully updated.");
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(
      message.error,
      "There was an error getting the location attributes."
    );
  }
}

export function* updateLocationAttributeSaga(action) {
  try {
    yield call(updateLocationAttribute, action.attribute);
    yield put(actions.fetchLocationAttributes(action.locationId));
    yield call(message.success, "Attribute successfully updated.");
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(
      message.error,
      "There was an error getting the location attributes."
    );
  }
}

export function* deleteLocationAttributeSaga(action) {
  try {
    yield call(deleteLocationAttribute, action.id);
    yield put(actions.fetchLocationAttributes(action.locationId));
    yield call(message.success, "Attribute successfully updated.");
  } catch (error) {
    yield put({ type: actions.LOCATIONS_FAIL });
    yield call(
      message.error,
      "There was an error getting the location attributes."
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_LOCATIONS, fetchLocationsSaga);
  yield takeLatest(actions.FETCH_LOCATION, fetchLocationSaga);
  yield takeLatest(actions.UPDATE_LOCATION, updateLocationSaga);
  yield takeLatest(
    actions.FETCH_LOCATIONS_BY_ADDRESS,
    fetchLocationsByAddressSaga
  );
  yield takeLatest(actions.FETCH_ACTIVE_DEVICES, fetchDevices);

  // Location Attributes
  yield takeLatest(
    actions.FETCH_LOCATION_ATTRIBUTES,
    fetchLocationAttributesSaga
  );
  yield takeLatest(actions.POST_LOCATION_ATTRIBUTE, postLocationAttributeSaga);
  yield takeLatest(
    actions.UPDATE_LOCATION_ATTRIBUTE,
    updateLocationAttributeSaga
  );
  yield takeLatest(
    actions.DELETE_LOCATION_ATTRIBUTE,
    deleteLocationAttributeSaga
  );
}
