import loadable from "@loadable/component";
import Raven from "raven-js";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "urql";
import "./styles.css";
import { STAGE_SENTRY_TOKEN } from "constants/config";
import { ROUTES } from "constants/routes";
import restrict from "components/restrict/app";
import CMSConfig from "../CMSConfig";
import { ENV } from "constants/app_env";
import { contentSession } from "utils/content";
import { WebPageEditor } from "containers/WebPages/Edit";
import { ResetPassword } from "containers/Auth/reset-password";
import { SignOut } from "containers/Auth/sign-out";
import { ForgotPassword } from "containers/Auth/forgot-password";
import { useScript } from "hooks/use-script";
import Global_env from "constants/global_env";

// Async loaded pages
const AsyncSignIn = loadable(async () => {
  // Example of importing named imports
  const { SignIn } = await import("containers/Auth/sign-in");

  // you can return props => <Component {...props} />: https://github.com/gregberge/loadable-components/issues/97
  return () => <SignIn />;
});

const AsyncContainer = loadable(() => import("containers/Container"));
const AsyncPreviewPageRevision = loadable(
  () => import("containers/Revisions/Preview")
);

// Preview
const AsyncPreview = loadable(() => import("containers/Preview"));

if (ENV === "sandbox" || ENV === "production") {
  Raven.config(STAGE_SENTRY_TOKEN).install();
}

const Main = () => {
  if (Global_env.RECAPTCHA_SITE_KEY) {
    useScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${Global_env.RECAPTCHA_SITE_KEY}`
    );
  }

  return (
    <>
      <CMSConfig />
      <Switch>
        <Route path={ROUTES.AUTH.LOGIN} component={AsyncSignIn} />
        <Route path={ROUTES.AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={ROUTES.AUTH.LOGOUT} component={SignOut} />
        <Route path={ROUTES.AUTH.PASSWORD_RESET} component={ResetPassword} />

        <Route path={ROUTES.WEB_ORDERING.EDITOR} component={WebPageEditor} />

        <Route
          path={ROUTES.PAGES.PREVIEW_REVISION}
          render={(props) => <AsyncPreviewPageRevision {...props} />}
        />
        {/* Preview */}
        <Route
          exact={true}
          path={ROUTES.PREVIEW.BASE}
          component={AsyncPreview}
        />
        <Route path="/" component={restrict(AsyncContainer)} />
      </Switch>
    </>
  );
};

function App() {
  return (
    <Provider value={contentSession()}>
      <Main />
    </Provider>
  );
}

export default App;
