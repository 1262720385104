import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Input, Popover, Typography } from "antd";
import { debounce } from "lodash";
import { useQuery } from "urql";
import { PagesQueryDocument } from "types/content.gql";
import { UrlField, UrlType } from "components/web-pages/modules/types";

interface Props {
  onChange: (value: UrlField) => void;
  value: UrlField;
}

interface PageQuery {
  search?: string;
  sort?: {
    title: string;
  };
}

export function Pages({ onChange, value }: Props) {
  const { label = "" } = value;
  const {
    label: referenceLabel = "",
    url = "",
    type = UrlType.local,
    newWindow = false,
  } = value?.reference ?? {};

  const defaultVariables: PageQuery = {
    sort: { title: "asc" },
  };

  const [showResults, setShowResults] = useState(false);
  const [searchValue, setSearchValue] = useState(label);
  const [variables, setVariables] = useState(defaultVariables);

  const [{ data }] = useQuery({
    query: PagesQueryDocument,
    // @ts-expect-error
    variables,
  });

  const updateSearch = useCallback(
    debounce((searchValue: string) => {
      if (searchValue !== "") {
        setVariables({
          ...variables,
          search: searchValue,
        });
      } else {
        setVariables(defaultVariables);
      }
    }, 250),
    []
  );

  useEffect(
    function () {
      updateSearch(searchValue);
    },
    [searchValue]
  );

  useEffect(
    function () {
      if (type === UrlType.attachment || type === UrlType.external) {
        setSearchValue("");
      }
    },
    [value]
  );

  useEffect(
    function () {
      if (
        type === UrlType.attachment ||
        type === UrlType.external ||
        searchValue
      ) {
        return;
      }

      setSearchValue(referenceLabel);
    },
    [referenceLabel]
  );

  const static_routes = [
    {
      id: "static/home",
      slug: "/",
      title: "Home",
    },
    {
      id: "static/locations",
      slug: "/locations",
      title: "Store locator",
    },
    {
      id: "static/sign-up",
      slug: "/sign-up",
      title: "Sign up",
    },
    {
      id: "static/account",
      slug: "/account",
      title: "My account",
    },
    {
      id: "static/forgot-password",
      slug: "/forgot-password",
      title: "Forgot Password",
    },
  ];

  return (
    <>
      <Popover
        content={
          <List>
            {/* @TODO: update the UI to differentiate between dynamic and static pages */}

            {(data?.pages ?? [])
              .concat(
                static_routes.filter((route) =>
                  route.title.toLowerCase().includes(searchValue.toLowerCase())
                )
              )
              .map((item) => {
                const { slug = "", title = "" } = item;
                return (
                  <Result
                    key={item.id}
                    onClick={() => {
                      onChange({
                        label: String(title),
                        reference: {
                          newWindow,
                          label: String(title),
                          url: String(slug),
                          type: UrlType.local,
                        },
                      });

                      setSearchValue(item.title ?? "");
                      setShowResults(false);
                    }}
                  >
                    {item.title}
                  </Result>
                );
              })}
          </List>
        }
        placement="bottomLeft"
        open={showResults}
        trigger="click"
        onOpenChange={(status: boolean) => setShowResults(status)}
      >
        <Typography.Text strong>Page</Typography.Text>
        <Input
          placeholder="Search pages..."
          value={searchValue}
          onChange={function (e) {
            setShowResults(true);
            setSearchValue(e.target.value);
          }}
        />
      </Popover>

      <div>
        <Typography.Text strong>Label</Typography.Text>

        <Input
          placeholder="Label..."
          value={label}
          onChange={function (e) {
            onChange({
              ...value,
              label: e.target.value,
            });
          }}
        />
      </div>

      <Checkbox
        checked={newWindow}
        onChange={(e) => {
          onChange({
            ...value,
            reference: {
              label,
              url,
              type,
              newWindow: Boolean(e.target.checked),
            },
          });
        }}
      >
        Open link in a new tab
      </Checkbox>
    </>
  );
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: scroll;
  width: 300px;
`;

const Result = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--color--black-900);
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  text-align: left;
  transition: all 0.1s ease;

  &:last-of-type {
    border: none;
  }

  &:hover {
    background-color: var(--color--blue-900);
    border-bottom-color: var(--color--blue-900);
    color: var(--color--blue-400);
  }
`;
