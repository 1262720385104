import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch available location attributes positions
 * TODO - move into SDK
 */
export function fetchLocationAttributePositions() {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}/location-attribute-positions?per_page=50`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
