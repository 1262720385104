import styled from "styled-components";

export const StyledPreview = styled.div`
  text-align: center;
  p {
    font-size: 14px;
    margin: 10px 0;
  }
  img {
    max-width: 100%;
  }
`;
