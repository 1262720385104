import { put, call, takeLatest, select } from "redux-saga/effects";
import { message } from "antd";
import get from "lodash/get";

// Services
import {
  fetchAssets,
  postAsset,
  deleteAsset,
  updateAsset,
} from "services/assets.service";

// Actions
import actions from "./actions";
import { FixMeAny } from "types/fix-me-any";

export function* fetchAssetsSaga(action) {
  try {
    const response = yield call(fetchAssets, action.magicBox);

    yield put(
      actions.assetsSuccess(
        response.data.data,
        response.data.meta,
        action.magicBox
      )
    );
  } catch (error) {
    yield call(message.error, "There was an issue fetching assets");
  }
}

export function* postAssetSaga(action) {
  try {
    const file = action.payload?.file || undefined;

    // There are cases wherein redux-form state can become a bit mangled
    // and a user can still manage to submit an asset without a "file" property
    if (!file) {
      throw new Error("Please select a file to upload.");
    } else {
      const response = yield call(postAsset, action.payload);

      // Refetch asset list
      const state = yield select();
      yield put(actions.fetchAssets(get(state, "app.assets.magicBox", {})));

      // Push asset detail into redux
      yield put(actions.pushAsset(response.data.data));

      // Display success
      yield call(message.success, "Asset successfully uploaded");
    }
  } catch (error: FixMeAny) {
    console.log("ERROR: ", error);

    let errorMessage = "";

    if (
      get(error, "response.data") &&
      get(error, "response.data.error_description")
    ) {
      errorMessage = error.response.data.error_description;
    } else if (error?.message) {
      errorMessage = error.message;
    } else {
      errorMessage =
        "Please make sure your file is smaller than 8 MB and is an accepted file format.";
    }

    yield put({ type: actions.ASSETS_FAIL });
    yield call(message.error, `An error occurred. ${errorMessage}`);
  }
}

export function* uploadAssetSaga(action) {
  try {
    yield call(updateAsset, action.payload, action.id);

    // Refetch asset list
    const state = yield select();
    yield put(actions.fetchAssets(get(state, "app.assets.magicBox", {})));

    // Display success
    yield call(message.success, "Asset successfully updated");
  } catch (error) {
    yield call(message.error, "There was a problem updating your asset");
  }
}

export function* deleteAssetSaga(action) {
  try {
    yield call(deleteAsset, action.payload);

    // Refetch asset list
    const state = yield select();
    yield put(actions.fetchAssets(get(state, "app.assets.magicBox", {})));

    // Display success
    yield call(message.success, "Asset deleted");
  } catch (error) {
    yield call(message.error, "There was a problem deleting your asset");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ASSETS, fetchAssetsSaga);
  yield takeLatest(actions.POST_ASSET, postAssetSaga);
  yield takeLatest(actions.UPDATE_ASSET, uploadAssetSaga);
  yield takeLatest(actions.DELETE_ASSET, deleteAssetSaga);
}
