import { ENV } from "constants/app_env";

const API = {
  routes: {
    authenticate: "/oauth/access_token",
    scopes: "/oauth/scopes",
    mePermissions: "/me/permissions",
    activeDevices: "/active-devices",
    users: "/users",
    pages: "/pages",
    revisions: "/revisions",
    persistentTemplates: "/persistent-templates",
    categories: "/categories",
    assets: "/assets",
    modules: "/modules",
    globalCategories: "/categories",
    globalProducts: "/global-products",
    globalProductModifiers: "/global-product-options",
    allergies: "/allergies",
    deviceConfiguration: "/device-configurations",
    squareState: "/square/state",
    organizations: "/organizations",
    recommendations: "/recommendations",
    tags: "/tags",
  },
};

const API_ENV = {
  dev: {
    url: "dev.api.koala.io/configurations",
    legacy_url: "dev.api.koala.io/marketing/v1",
    urlBase: "dev.api.koala.io",
    version: "v1",
    scheme: "https://",
  },
  staging: {
    url: "staging.api.koala.io/configurations",
    legacy_url: "staging.api.koala.io/marketing/v1",
    urlBase: "staging.api.koala.io",
    version: "v1",
    scheme: "https://",
  },
  sandbox: {
    url: "sandbox.api.koala.io/configurations",
    legacy_url: "sandbox.api.koala.io/marketing/v1",
    urlBase: "sandbox.api.koala.io",
    version: "v1",
    scheme: "https://",
  },
  production: {
    url: "api.koala.io/configurations",
    legacy_url: "api.koala.io/marketing/v1",
    urlBase: "api.koala.io",
    version: "v1",
    scheme: "https://",
  },
};

export default Object.assign({}, API, API_ENV[ENV]);
