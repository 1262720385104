import { message } from "antd";
import get from "lodash/get";
import { call, put, select, takeLatest } from "redux-saga/effects";

// Actions
import pageActions from "redux/pages/actions";
import actions from "./actions";

// Services
import {
  createRevision,
  fetchRevision,
  fetchRevisions,
} from "services/revisions.service";

// Fetch all revisions
export function* fetchRevisionsSaga({
  magicBox,
}: {
  type: string;
  magicBox: any;
}) {
  try {
    const response = yield call(fetchRevisions, magicBox);

    yield put({
      type: actions.REVISIONS_SUCCESS,
      list: response.data.data,
      magicBox,
    });
  } catch (error) {
    yield put({ type: actions.REVISIONS_FAIL });
  }
}

// Fetch a single revisions
export function* fetchRevisionSaga({ id }: { type: string; id: number }) {
  try {
    const response = yield call(fetchRevision, id);

    yield put({ type: actions.REVISION_SUCCESS, detail: response.data.data });
  } catch (error) {
    yield put({ type: actions.REVISIONS_FAIL });
  }
}

export function* postRevisionsSaga(action) {
  try {
    yield call(createRevision, action.payload);

    // Alert
    yield call(message.success, "Page modules successfully saved!");

    // Refetch page
    if (action.refetch) {
      yield put({ type: pageActions.FETCH_PAGE, id: action.payload.page_id });
    }

    // Stop spinner
    yield put({ type: actions.REVISION_SUCCESS });
  } catch (error) {
    // Alert
    yield call(
      message.error,
      "There was an error saving these updates. Please try again."
    );

    // TODO - log to sentry
    yield put({ type: actions.REVISION_FAIL });
  }
}

export function* revertRevisionsSaga(action) {
  try {
    yield call(createRevision, action.payload);
    yield put({ type: actions.REVISION_SUCCESS });

    // Alert
    yield call(message.success, "Revision successfully reverted!");

    // Refetch revisions
    const state = yield select();
    yield put({
      type: actions.FETCH_REVISIONS,
      magicBox: get(state, "app.revisions.magicBox", {}),
    });
  } catch (error) {
    // Alert
    yield call(
      message.error,
      "There was an error saving these updates. Please try again."
    );

    // TODO - log to sentry
    yield put({ type: actions.REVISION_FAIL });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_REVISION, fetchRevisionSaga);
  yield takeLatest(actions.FETCH_REVISIONS, fetchRevisionsSaga);
  yield takeLatest(actions.POST_REVISION, postRevisionsSaga);
  yield takeLatest(actions.REVERT_REVISION, revertRevisionsSaga);
}
