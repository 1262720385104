import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

export function fetchLocationStats(
  start: string,
  end: string,
  organizationId: number
) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.organizations}/${organizationId}/store-location-stats/${start}/${end}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
