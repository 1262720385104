import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";

export enum elementTypes {
  text = "text",
  paragraph = "paragraph",
  listItem = "li",
  unorderedList = "ul",
  orderedList = "ol",
  anchor = "a",
}

export type markTypes = "bold" | "italic";

export type FormattedText = {
  type: "text";
  text: string;
  bold?: boolean | null;
  italic?: boolean | null;
  size?: string;
  color?: string;
  highlighted?: boolean;
};

export type Li = {
  type: elementTypes.listItem;
  children: FormattedText[];
  text: string;
};

export type ParagraphElement = {
  type: elementTypes.paragraph;
  children: FormattedText[];
};
export type ULElement = { type: elementTypes.unorderedList; children: Li[] };
export type OLElement = { type: elementTypes.orderedList; children: Li[] };
export type AnchorElement = {
  type: elementTypes.anchor;
  href: string | null;
  target: string | null;
  "data-url": string | null;
  children: FormattedText[];
};

export type CustomElement =
  | ParagraphElement
  | Li
  | ULElement
  | OLElement
  | AnchorElement
  | FormattedText;

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: FormattedText;
  }
}
