import findIndex from "lodash/findIndex";
import merge from "lodash/merge";

// Actions
import {
  ADD_RULE,
  CLEAR_RECOMMENDATIONS,
  COPY_RULE,
  FETCH_RULES,
  REMOVE_RULE,
  REORDER_RULE,
  REQUEST_FAILED,
  SAVE_RULES,
  SET_LOCATION_ID,
  SET_RECOMMENDATIONS,
  SET_RULES,
  UPDATE_RULE,
} from "./actions";
import { CrossSellRuleSet } from "types/cross-sells";

const initialRules: CrossSellRuleSet[] = [];
const initialMatches: CrossSellRuleSet[] = [];
const initialState = {
  matches: initialMatches,
  meta: {},
  rules: initialRules,
  loading: false,
  locationId: null,
  recommendations: {},
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_LOCATION_ID: {
      return Object.assign({}, state, {
        locationId: action.locationId,
      });
    }
    case SET_RULES: {
      return Object.assign({}, state, {
        rules: action.rules,
        loading: false,
      });
    }
    case ADD_RULE:
    case COPY_RULE: {
      return Object.assign({}, state, {
        rules: [...state.rules, action.rule],
      });
    }
    case REMOVE_RULE: {
      return Object.assign({}, state, {
        rules: state.rules.filter((_, i) => action.index !== i),
      });
    }
    case REORDER_RULE: {
      return Object.assign({}, state, {
        rules: action.rules,
      });
    }
    case UPDATE_RULE: {
      const clonedConfig: any = merge({}, state);
      const ruleIndex: number = findIndex(clonedConfig.rules, {
        id: action.rule.id,
      });

      if (ruleIndex > -1) {
        clonedConfig.rules[ruleIndex] = action.rule;
      }

      return clonedConfig;
    }

    case SAVE_RULES:
    case FETCH_RULES: {
      return Object.assign({}, state, {
        loading: true,
      });
    }

    case REQUEST_FAILED: {
      return Object.assign({}, state, {
        loading: false,
      });
    }

    case SET_RECOMMENDATIONS: {
      return Object.assign({}, state, {
        recommendations: action.recommendations,
      });
    }

    case CLEAR_RECOMMENDATIONS: {
      return Object.assign({}, state, {
        recommendations: {},
      });
    }

    default:
      return state;
  }
}
