// Utils
import { MagicBoxParams } from "utils/magicBox";

// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  list: [],
  detail: null,
  workingRevision: null,
  listMeta: {},
  magicBox: new MagicBoxParams(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PAGE:
    case actions.CREATE_PAGE:
    case actions.UPDATE_PAGE:
    case actions.DELETE_PAGE:
    case actions.PUBLISH_PAGE:
    case action.UNPUBLISH_PAGE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_PAGES:
      return Object.assign({}, state, {
        loading: true,
        magicBox: action.magicBox,
      });
    case actions.PAGES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        magicBox: action.magicBox,
        list: action.list,
        listMeta: action.listMeta,
      });
    case actions.PAGE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
        workingRevision: action.workingRevision,
      });
    case actions.PAGES_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_PAGE:
      return Object.assign({}, state, {
        detail: null,
        workingRevision: null,
      });
    case actions.CLEAR_PAGES:
      return Object.assign({}, state, {
        list: initialState.list,
        listMeta: initialState.listMeta,
        magicBox: initialState.magicBox,
      });
    default:
      return state;
  }
}
