import React from "react";
import type * as Stitches from "@stitches/react";
import { Box } from "../box";
import { Base, Field } from "./base";

export interface SelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  children: React.ReactNode;
  required?: boolean;
  error?: string;
  css?: Stitches.CSS;
}

function Icon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M19.5 9L12 16.5L4.5 9" fill="none" stroke="currentColor" />
    </svg>
  );
}

export function Select({
  label,
  name,
  required = false,
  error,
  children,
  ...otherProps
}: SelectProps) {
  const baseProps = { label, name, required, error };

  return (
    <Base {...baseProps}>
      <Field
        as="select"
        id={name}
        required={required}
        aria-label={label}
        {...otherProps}
      >
        {children}
      </Field>

      <Box
        css={{
          display: "flex",
          position: "absolute",
          right: "$4",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Icon />
      </Box>
    </Base>
  );
}
