export const LAYOUT = {
  HEADERHEIGHT: 90,
  MOBILE_HEADERHEIGHT: 58,
  MAXWIDTH: 1280,
  GUTTER: 20,
  H_SPACING: 50,
};

export const COLORS = {
  ACTIVE_BLUE: "#1890ff",
  WHITE: "#fff",
  CONTENT: "inherit",
};

export const ORDER = {
  LOADING: 12,
  SKIP_TO_CONTENT: 11,
  HEADER: 10,
  MENU: 9,
  HOMEPAGE_SLIDER: 8,
};
