export const ROUTES = {
  HOME: {
    BASE: "/",
  },
  AUTH: {
    LOGOUT: "/logout",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    PASSWORD_RESET: "/password-reset",
  },
  ME: {
    ACCOUNT: "/account",
  },
  USERS: {
    BASE: "/users",
    CUSTOMERS: {
      BASE: "/users/customers",
    },
    CMS: {
      BASE: "/users/cms",
      CREATE: "/users/cms/create",
      UPDATE: "/users/cms/:id",
    },
  },
  ASSETS: {
    BASE: "/assets",
    CREATE: "/assets/create",
    UPDATE: "/assets/:id",
  },
  LOCATIONS: {
    BASE: "/locations",
    CREATE: "/locations/create",
    UPDATE: "/locations/:id",
    UPDATE_ATTRIBUTES: "/locations/:id/attributes",
    UPDATE_INFO: "/locations/:id/info",
    UPDATE_KIOSKS: "/locations/:id/kiosks",
    UPDATE_KIOSK: "/locations/:id/kiosks/:kioskId",
  },
  GLOBAL_MENU: {
    BASE: "/global-menu",
    PRODUCTS: {
      BASE: "/global-menu/products",
      UPDATE: "/global-menu/products/:id",
    },
    MODIFIERS: {
      BASE: "/global-menu/product-modifiers",
      UPDATE: "/global-menu/product-modifiers/:id",
    },
    TAGS: {
      BASE: "/global-menu/tags",
      UPDATE: "/global-menu/tags/:id",
    },
  },
  // Web Marketing-specific
  WEB_MARKETING: {
    BASE: "/web-marketing",
    CONFIG: "/web-marketing/config",
    // TODO - move all the below into this config
  },
  PAGES: {
    BASE: "/web-marketing/pages",
    CREATE: "/web-marketing/pages/create",
    UPDATE: "/web-marketing/pages/:id",
    UPDATE_INFO: "/web-marketing/pages/:id/info",
    UPDATE_MODULES: "/web-marketing/pages/:id/modules",
    UPDATE_REVISIONS: "/web-marketing/pages/:id/revisions",
    PREVIEW_REVISION: "/web-marketing/pages/revisions/:id",
  },
  GLOBAL_MODULES: {
    BASE: "/web-marketing/global-modules",
    CREATE: "/web-marketing/global-modules/create/:id",
    UPDATE: "/web-marketing/global-modules/:id",
  },
  NAVIGATION: {
    BASE: "/web-marketing",
    HEADER: "/web-marketing/header",
    FOOTER: "/web-marketing/footer",
  },

  APPEARANCE: {
    WIZARD: "/appearance/wizard",
  },

  // Web Ordering
  GLOBAL_COLORS: {
    BASE: "/global-colors",
  },

  // Web Ordering
  WEB_ORDERING: {
    BASE: "/web-ordering",
    CONFIG: "/web-ordering/config",
    EDITOR: "/web-ordering/pages/editor/:id",
    HEADER: "/web-ordering/header",
    FOOTER: "/web-ordering/footer",
    PAGES: "/web-ordering/pages",
    STRINGS: "/web-ordering/strings",
  },

  // Mobile iOS
  MOBILE_IOS: {
    BASE: "/mobile-ios",
    CONFIG: "/mobile-ios/config",
  },

  // Kiosk
  KIOSK: {
    BASE: "/kiosk",
    CONFIG: "/kiosk/config",
    CONFIG_COLORS: "/kiosk/config/colors",
    CONFIG_ICONS: "/kiosk/config/icons",
    CONFIG_IMAGES: "/kiosk/config/images",
    CONFIG_DESCRIPTIONS: "/kiosk/config/descriptions",
    STRINGS: "/kiosk/strings",
  },

  // Settings
  CROSSSELL: {
    BASE: "/cross-sell",
  },

  // Settings
  SETTINGS: {
    BASE: "/settings",
  },

  // Preview
  PREVIEW: {
    BASE: "/preview",
  },
};
