import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  onClick: () => void;
}

export const DuplicatePage = ({ onClick }: Props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => void setVisible(true)}>Duplicate page</Button>

      <Modal
        title="Duplicate Page"
        open={visible}
        onCancel={() => void setVisible(false)}
        onOk={() => {
          setVisible(false);
          onClick();
        }}
        okText="Duplicate"
      >
        <p>
          Creating a copy of this page will duplicate all of its data from the
          last time it was saved.
        </p>
      </Modal>
    </>
  );
};

const Button = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
`;
