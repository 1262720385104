import { message, notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

// Actions
import actions from "./actions";

// Services
import {
  fetchGlobalProductModifiers,
  fetchGlobalProductModifier,
  updateGlobalProductModifiers,
  updateGlobalProductModifierImage,
  fetchAllergies,
} from "../../services/globalProductModifiers.service";

// Utils
import { deriveImageUploadError } from "../../utils/errors";

export function* fetchGlobalProductModifiersSaga(action) {
  try {
    const qs = action.magicBox.toQuery();
    const response = yield call(fetchGlobalProductModifiers, qs);

    yield put({
      type: actions.GLOBAL_PRODUCT_MODIFIERS_SUCCESS,
      data: response.data,
      magicBox: action.magicBox,
    });
  } catch (error) {
    // @ts-expect-error Property 'GLOBAL_PRODUCT_MODIFIERS_FAIL' does not exist on type '{ FETCH_GLOBAL_PRODUCT_MODIFIERS: string...
    yield put({ type: actions.GLOBAL_PRODUCT_MODIFIERS_FAIL });
    yield call(
      message.error,
      "There was an error loading the product modifiers."
    );
  }
}

export function* fetchGlobalProductModifierSaga(action) {
  try {
    const response = yield call(fetchGlobalProductModifier, action.id);

    yield put({
      type: actions.GLOBAL_PRODUCT_MODIFIER_SUCCESS,
      detail: response.data.data,
    });
  } catch (error) {
    // @ts-expect-error Property 'GLOBAL_PRODUCT_MODIFIERS_FAIL' does not exist on type '{ FETCH_GLOBAL_PRODUCT_MODIFIERS: string...
    yield put({ type: actions.GLOBAL_PRODUCT_MODIFIERS_FAIL });
    yield call(
      message.error,
      "There was an error getting the global modifier."
    );
  }
}

export function* updateGlobalProductModifiersSaga(action) {
  try {
    yield call(updateGlobalProductModifiers, action.payload, action.id);

    // Stop spinner
    yield put({ type: actions.STOP_LOADING_STATE });

    yield put(actions.fetchGlobalProductModifier(action.id));

    // Success Alert
    yield call(notification.success, {
      message: "Global product modifier updated",
      description: "Changes may take up to 20 minutes to fully propagate.",
    });
  } catch (error) {
    // Stop spinner
    yield put({ type: actions.STOP_LOADING_STATE });
    yield call(
      message.error,
      "There was an error updating the global product modifier."
    );
  }
}

export function* updateGlobalProductModifierImageSaga(action) {
  try {
    const response = yield call(
      updateGlobalProductModifierImage,
      action.id,
      action.key,
      action.value
    );

    // Success Alert
    yield call(notification.success, {
      message: "Global modifier updated",
      description: "Changes may take up to 20 minutes to fully propagate.",
    });

    // Attempt to update list view with generic action
    // NOTE - the key we use to POST the new image differs from the key we get in the response
    const key = action.key.replace("image", "image_url");
    yield put({
      type: actions.ATTEMPT_GLOBAL_PRODUCT_MODIFIER_LIST_MODIFICATION,
      id: action.id,
      key,
      value: response.data.data[key],
    });
  } catch (error) {
    // Stop spinner
    yield put({ type: actions.STOP_LOADING_STATE });

    // Error alert
    yield call(message.error, deriveImageUploadError(error));
  }
}

export function* getAllergies() {
  try {
    const response = yield call(fetchAllergies);
    yield put({ type: actions.ALLERGIES_SUCCESS, data: response.data });
  } catch (error) {
    yield call(message.error, "There was an error fetching allergens.");
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.FETCH_GLOBAL_PRODUCT_MODIFIERS,
    fetchGlobalProductModifiersSaga
  );
  yield takeLatest(
    actions.FETCH_GLOBAL_PRODUCT_MODIFIER,
    fetchGlobalProductModifierSaga
  );
  yield takeLatest(
    actions.UPDATE_GLOBAL_PRODUCT_MODIFIERS,
    updateGlobalProductModifiersSaga
  );
  yield takeLatest(
    actions.UPDATE_GLOBAL_PRODUCT_MODIFIER_IMAGE,
    updateGlobalProductModifierImageSaga
  );
  yield takeLatest(actions.FETCH_ALLERGIES, getAllergies);
}
