import { SDK } from "services/koala.service";

// Config
import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch me
 */
export function fetchMe() {
  return SDK.admin.user.me();
}

/**
 * Fetch me
 */
export function fetchMePermissions() {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.mePermissions}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Update me
 */
export function updateMe(payload) {
  const params = Object.assign({}, payload);

  if (!params.password) {
    delete params.current_password;
  }
  return SDK.admin.user.updateMe(params);
}
