import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

//  Returns the store instance
const configureStore = (initialState, history) => {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    // @ts-expect-error Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' does not exist on type 'Window & typeof globalThis'
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */

  const store = createStore(
    rootReducer(history),
    composeEnhancers(...enhancers)
  );

  return {
    ...store,
    // @ts-expect-error Argument of type '(getState: any) => Generator<GenericAllEffect<Generator<ForkEffect, void, unknown>>, void, unknown>' is not assignable to parameter of type 'Saga0'
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configureStore;
