import { IEnv } from "types/constants/env";

// Get env from url path
const getEnvFromUrl = (host: string) => {
  let possibleEnv = host.split(".")[0];

  if (possibleEnv === "cms") {
    return "production";
  } else {
    if (
      possibleEnv === "dev" ||
      possibleEnv === "staging" ||
      possibleEnv === "sandbox"
    ) {
      return possibleEnv;
    }
  }

  return "dev";
};

export const ENV =
  process.env.REACT_APP_ENV || getEnvFromUrl(window.location.host);
export const VERSION = (
  process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA || ""
).slice(0, 7);

const ENV_CONFIG = window.koalaUnifiedBrandConfig
  ? window.koalaUnifiedBrandConfig[ENV]
  : {};

export default ENV_CONFIG as IEnv;
