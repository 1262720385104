import { ILocationAttributePosition } from "types/locations";

const locationAttributePositions = {
  FETCH_LOCATION_ATTRIBUTE_POSITIONS:
    "/koala-cms/location-attribute-positions/FETCH_LOCATION_ATTRIBUTE_POSITIONS",
  fetchLocationAttributePositions: () => ({
    type: locationAttributePositions.FETCH_LOCATION_ATTRIBUTE_POSITIONS,
  }),

  STORE_LOCATION_ATTRIBUTE_POSITIONS:
    "/koala-cms/location-attribute-positions/STORE_LOCATION_ATTRIBUTE_POSITIONS",
  storeLocationAttributePositions: (data: ILocationAttributePosition | []) => ({
    type: locationAttributePositions.STORE_LOCATION_ATTRIBUTE_POSITIONS,
    data,
  }),
};

export default locationAttributePositions;
