export const ASSET_TYPES = {
  IMAGE: "image",
  PDF: "pdf",
};

export const ASSET_MIME_TYPES = {
  "image/jpeg": ASSET_TYPES.IMAGE,
  "image/gif": ASSET_TYPES.IMAGE,
  "image/svg+xml": ASSET_TYPES.IMAGE,
  "image/vnd.microsoft.icon": ASSET_TYPES.IMAGE,
  "image/png": ASSET_TYPES.IMAGE,
  "image/x-icon": ASSET_TYPES.IMAGE,
  "application/pdf": ASSET_TYPES.PDF,
};
