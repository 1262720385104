import actions from "./actions";

const initialState = {
  list: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_LOCATION_STATS:
      return Object.assign({}, state, {
        loading: true,
        organizationId: action.organizationId,
      });
    case actions.LOCATION_STATS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
      });
    default:
      return state;
  }
}
