import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch revisions
 */
export function fetchRevisions(magicBoxParams) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${
      API.routes.revisions
    }?${magicBoxParams.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Fetch a single revision
 */
export function fetchRevision(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.revisions}/${id}?include[]=slots`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Create revision
 */
export function createRevision(payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.legacy_url}${API.routes.revisions}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}
