import { createStitches } from "@stitches/react";

const colors = {
  accentPrimary: "#0D6CF2",
  accentSecondary: "#5D6E86",
  accentSuccess: "#008060",
  accentHighlight: "##5BCDDA",
  accentError: "#D82C0D",
  accentWarning: "#FFC453",
  shade900: "#000000",
  shade800: "#062B38",
  shade600: "#595959",
  shade500: "#AAA",
  shade400: "#C1C1C1",
  shade300: "#E5E5E5",
  shade200: "#F3F3F3",
  shade100: "#FFFFFF",
};

const typography = {
  fonts: {
    system:
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif",
    body: "Soehne",
  },
  fontSizes: {
    primary: "1.25rem",
    secondary: "2rem",
    text800: "3rem",
    text700: "2.5rem",
    text600: "1.75rem",
    text500: "1.5rem",
    text400: "1.25rem",
    text300: "1rem",
    text200: ".875rem",
    text100: ".75rem",
  },
  lineHeights: {
    none: 1,
    tighter: 1.1,
    tight: 1.25,
    snug: 1.35,
    normal: 1.5,
    loose: 1.65,
    looser: 2,
  },
  letterSpacings: {
    none: 0,
    tight: "-0.025em",
    snug: "-0.0125em",
    loose: "0.025em",
    wide: "0.05em",
    wider: "0.075em",
    extreme: "0.125em",
  },
};

const space = {
  0: "0",
  "0-5": ".125rem",
  1: ".25rem",
  "1-5": ".375rem",
  2: ".5rem",
  "2-5": ".675rem",
  3: ".75rem",
  "3-5": ".875rem",
  4: "1rem",
  "4-5": "1.125rem",
  5: "1.25rem",
  "5-5": "1.375rem",
  6: "1.5rem",
  "6-5": "1.625rem",
  7: "1.75rem",
  "7-5": "1.875rem",
  8: "2rem",
  "8-5": "2.125rem",
  9: "2.25rem",
  10: "2.5rem",
  11: "2.75rem",
  12: "3rem",
  14: "3.5rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  28: "7rem",
  32: "8rem",
  36: "9rem",
  40: "10rem",
  44: "11rem",
  48: "12rem",
  56: "14rem",
  64: "16rem",
  72: "18rem",
  80: "20rem",
  96: "24rem",
  128: "32rem",
};

const sizes = {
  ...space,
  sm: "20rem",
  md: "28rem",
  lg: "36rem",
  xl: "40rem",
  "2xl": "48rem",
  "3xl": "56rem",
  "4xl": "64rem",
  "5xl": "72rem",
  "6xl": "80rem",
  full: "100%",
};

const radii = {
  sm: "0.25rem",
  md: "0.5rem",
  lg: "0.75rem",
  xl: "1rem",
  "2xl": "1.25rem",
  "3xl": "1.5rem",
  "4xl": "1.75rem",
  "5xl": "2rem",
  full: "100%",
};

const zIndices = {
  "-1": -1,
  "0": 0,
  "10": 10,
  "20": 20,
  "30": 30,
  "40": 40,
  "50": 50,
  "100": 100,
};

const media = {
  sm: "(min-width: 640px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 1024px)",
  xl: "(min-width: 1280px)",
  "2xl": "(min-width: 1536px)",
  print: "print",
};

const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
  reset,
} = createStitches({
  theme: {
    ...typography,
    colors,
    space,
    sizes,
    radii,
    zIndices,
  },
  media,
});

export {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
  reset,
};
