import axios from "axios";
import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch pages
 */
export function fetchPages(magicBoxParams) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${
      API.routes.pages
    }?${magicBoxParams.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Fetch page
 */
export function fetchPage(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}?include[]=revisions&include[]=revisions.author&include[]=current_revision.author&include[]=review_request.from_user`, // TODO - abstract
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Create page
 */
export function createPage(payload) {
  const { token_type, access_token } = AccessToken.get();

  // Hardcode page_type_id for now
  payload.page_type_id = 2;

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

/**
 * Update page
 */
export function updatePage(payload, id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

/**
 * Delete page
 */
export function deletePage(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

/**
 * Publish page
 */
export function publishPage(id, revisionId) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}/publish`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      revision_id: revisionId,
    },
  });
}

/**
 * Unpublish page
 */
export function unpublishPage(id, revisionId) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}/publish`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      revision_id: revisionId,
    },
  });
}

/**
 * Request page review
 */
export function requestPageReview(id, revisionId, reviewIds) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${id}/review-request`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      to_user_ids: reviewIds,
      review_revision_id: revisionId,
    },
  });
}

/**
 * Delete page review
 */
export function deletePageReview(pageId) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.legacy_url}${API.routes.pages}/${pageId}/review-request`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

/**
 * Trigger Vercel deploy hook
 */
export function deployVercel(webhookId: string) {
  return axios({
    method: "GET",
    url: `https://api.vercel.com/v1/integrations/deploy/${webhookId}`,
  });
}
