import React, { ReactNode } from "react";
import { Dropdown, Menu } from "antd";

interface Props {
  children: ReactNode;
  items: string[];
  onChange: (item: string) => void;
  onOpen?: (status: boolean) => void;
}

export const ToolBarMenu = ({ children, items, onChange, onOpen }: Props) => {
  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      onOpenChange={(open) => {
        onOpen?.(open);
      }}
      overlay={
        <Menu>
          {items.map((item) => (
            <Menu.Item key={item} onClick={() => onChange(item)}>
              {item}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      {children}
    </Dropdown>
  );
};
