// Utils
import { MagicBoxParams } from "utils/magicBox";

// Actions
import actions from "./actions";

const initialState = {
  list: [],
  detail: null,
  loading: false,
  listMeta: {},
  magicBox: new MagicBoxParams(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PERSISTENT_TEMPLATE:
    case actions.FETCH_PERSISTENT_TEMPLATE_BY_ALIAS:
    case actions.FETCH_PERSISTENT_TEMPLATES:
    case actions.UPDATE_PERSISTENT_TEMPLATE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.PERSISTENT_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        magicBox: action.magicBox,
        list: action.list,
        listMeta: action.listMeta,
      });
    case actions.PERSISTENT_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
      });
    case actions.PERSISTENT_TEMPLATES_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_PERSISTENT_TEMPLATE:
      return initialState;
    default:
      return state;
  }
}
