import React from "react";
import {
  AlignLeftOutlined,
  AppstoreOutlined,
  BgColorsOutlined,
  BlockOutlined,
  BoldOutlined,
  BorderOuterOutlined,
  BorderOutlined,
  ExpandOutlined,
  FileOutlined,
  FileImageOutlined,
  FontSizeOutlined,
  LinkOutlined,
  PicCenterOutlined,
  SearchOutlined,
  StarOutlined,
} from "@ant-design/icons";

export function getIcon(type: string) {
  switch (type) {
    case "align-left":
      return <AlignLeftOutlined />;
    case "app-store":
      return <AppstoreOutlined />;
    case "border":
      return <BorderOutlined />;
    case "bold":
      return <BoldOutlined />;
    case "bg-color":
      return <BgColorsOutlined />;
    case "border-outer":
      return <BorderOuterOutlined />;
    case "block-outlined":
      return <BlockOutlined />;
    case "expand-outlined":
      return <ExpandOutlined />;
    case "font-size":
      return <FontSizeOutlined />;
    case "file":
      return <FileOutlined />;
    case "file-image":
      return <FileImageOutlined />;
    case "pic-center":
      return <PicCenterOutlined />;
    case "star":
      return <StarOutlined />;
    case "link":
      return <LinkOutlined />;
    case "search":
      return <SearchOutlined />;
    default:
      return null;
  }
}
