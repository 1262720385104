const usersActions = {
  FETCH_USERS: "/koala-cms/user/FETCH_USERS",
  CLEAR_USERS: "/koala-cms/user/CLEAR_USERS",
  RESET_USERS: "/koala-cms/user/RESET_USERS",
  USERS_SUCCESS: "/koala-cms/user/USERS_SUCCESS",
  USERS_FAIL: "/koala-cms/user/USERS_FAIL",

  FETCH_USER: "/koala-cms/user/FETCH_USER",
  CREATE_USER: "/koala-cms/user/CREATE_USER",
  UPDATE_USER: "/koala-cms/user/UPDATE_USER",
  DELETE_USER: "/koala-cms/user/DELETE_USER",
  USER_SUCCESS: "/koala-cms/user/USER_SUCCESS",
  USER_FAIL: "/koala-cms/user/USER_FAIL",
  CLEAR_USER: "/koala-cms/user/CLEAR_USER",

  usersSuccess: (list, meta, magicBox) => ({
    type: usersActions.USERS_SUCCESS,
    list,
    meta,
    magicBox,
  }),
};

export default usersActions;
