import styled from "styled-components";
import { Input } from "antd";

export const StyledInput = styled(Input)`
  &.ant-input-lg {
    // Ant specific overrides
    font-size: 13px;
    height: 42px;
    padding: 6px 10px;
  }
`;
