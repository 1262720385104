// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  allergens: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ALLERGENS:
    case actions.POST_ALLERGEN:
    case actions.DELETE_ALLERGEN:
    case actions.UPDATE_ALLERGEN:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.FETCH_ALLERGENS_FAIL:
    case actions.POST_ALLERGEN_SUCCESS:
    case actions.DELETE_ALLERGEN_SUCCESS:
    case actions.DELETE_ALLERGEN_FAIL:
    case actions.UPDATE_ALLERGEN_SUCCESS:
    case actions.UPDATE_ALLERGEN_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.FETCH_ALLERGENS_SUCCESS:
      return Object.assign({}, state, {
        allergens: action.data,
        loading: false,
      });
    default:
      return state;
  }
}
