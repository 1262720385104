import actions from "./actions";

const initialState = {
  navCollapsed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.TOGGLE_NAV:
      return Object.assign({}, state, {
        navCollapsed: !state.navCollapsed,
      });
    default:
      return state;
  }
}
