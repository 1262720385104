// Utils
import { MagicBoxParams } from "utils/magicBox";

// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  list: [],
  magicBox: new MagicBoxParams({
    module_type_id: "=1", // Don't surface config modules
  }),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_MODULES:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.MODULES_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        loading: false,
        magicBox: action.magicBox,
      });
    default:
      return state;
  }
}
