import KoalaSDK, { APP_TYPES } from "@chowly/koala-sdk-js";

import BRAND_ENV from "constants/app_env";
import KOALA_CLIENT_CREDENTIALS from "constants/koala/auth";

// TODO - the || 'development' is potentially problematic if you want to e.g. run 'staging' locally

export const SDK = new KoalaSDK({
  ...KOALA_CLIENT_CREDENTIALS,
  env: BRAND_ENV.REACT_APP_ENV || "development",
  type: APP_TYPES.CMS,
  storageKey: "koalaCMSToken",
});
