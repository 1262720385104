import { CrossSellRuleSet } from "types/cross-sells";
import {
  ResponseBasketItems,
  Upsells as CrossSellResponse,
} from "@chowly/koala-sdk-js";

export const ADD_RULE = "/koala-cms/crossSell/ADD_RULE";
export const COPY_RULE = "/koala-cms/crossSell/COPY_RULE";
export const FETCH_RULES = "/koala-cms/crossSell/FETCH_RULES";
export const REMOVE_RULE = "/koala-cms/crossSell/REMOVE_RULE";
export const REORDER_RULE = "/koala-cms/crossSell/REORDER_RULE";
export const UPDATE_RULE = "/koala-cms/crossSell/UPDATE_RULE";
export const FETCH_CROSS_SELLS = "/koala-cms/crossSell/FETCH_CROSS_SELLS";
export const SET_RECOMMENDATIONS = "/koala-cms/crossSell/SET_RECOMMENDATIONS";
export const CLEAR_RECOMMENDATIONS =
  "/koala-cms/crossSell/CLEAR_RECOMMENDATIONS";
export const REQUEST_FAILED = "/koala-cms/crossSell/REQUEST_FAILED";
export const SET_LOCATION_ID = "/koala-cms/crossSell/SET_LOCATION_ID";
export const SET_RULES = "/koala-cms/crossSell/SET_RULES";
export const SAVE_RULES = "/koala-cms/crossSell/SAVE_RULES";

export const fetchRules = () => ({
  type: FETCH_RULES,
});

export const addRule = (rule: CrossSellRuleSet) => ({
  type: ADD_RULE,
  rule,
});

export const copyRule = (rule: CrossSellRuleSet) => ({
  type: COPY_RULE,
  rule,
});

export const removeRule = (index: number) => ({
  type: REMOVE_RULE,
  index,
});

export const reorderRules = (rules: CrossSellRuleSet[]) => ({
  type: REORDER_RULE,
  rules,
});

export const updateRule = (rule: CrossSellRuleSet) => ({
  type: UPDATE_RULE,
  rule,
});

export const fetchCrossSells = (cartItems: ResponseBasketItems[]) => ({
  type: FETCH_CROSS_SELLS,
  cartItems,
});

export const setLocationId = (locationId: number) => ({
  type: SET_LOCATION_ID,
  locationId,
});

export const setRules = (rules: CrossSellRuleSet[]) => ({
  type: SET_RULES,
  rules,
});

export const saveRules = () => ({
  type: SAVE_RULES,
});

export const requestFailed = () => ({
  type: REQUEST_FAILED,
});

export const setRecommendations = (recommendations: CrossSellResponse) => ({
  type: SET_RECOMMENDATIONS,
  recommendations,
});

export const clearRecommendations = () => ({
  type: CLEAR_RECOMMENDATIONS,
});
