import API from "constants/api_env";
import httpService from "services/generic.service";
import { AccessToken } from "storage/token";

/**
 * Fetch Device Config
 */
export function fetchDeviceConfiguration(
  label: string,
  organizationId: number
) {
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.deviceConfiguration}/alias/${label}?include[]=module`,
    headers: {
      "X-Organization-Id": organizationId,
    },
  });
}

export function updateDeviceConfig(payload, id, organizationId: number) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.legacy_url}${API.routes.deviceConfiguration}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
      "X-Organization-Id": organizationId,
    },
    data: payload,
  });
}
