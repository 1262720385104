import { Button } from "./button";
import { Checkbox } from "./checkbox";
import { Color } from "./color";
import { Container } from "./container";
import { Dropdown } from "./dropdown";
import { Layout } from "./layout";
import { List } from "./list";
import { Image } from "./image";
import { RichText } from "./richtext/richtext";
import { Text } from "./text";
import { Textarea } from "./textarea";
import { Typography } from "./typography";
import { Url } from "./url/url";

export const Field = {
  Button,
  Checkbox,
  Color,
  Container,
  Dropdown,
  Layout,
  List,
  Image,
  RichText,
  Text,
  Textarea,
  Typography,
  Url,
};
