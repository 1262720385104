import actions from "./actions";

const initialState = {
  list: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
      });
    default:
      return state;
  }
}
