import * as React from "react";
import { Field } from "redux-form";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import Input from "components/uielements/input";

const Info = () => (
  <span>
    Title
    <Popover
      overlayStyle={{ maxWidth: 200 }}
      content="This is an 'admin only' title that is used to identify this content within the CMS and to serve as the GA category text for CTA events that occur within the module. This text will not appear on the main site."
    >
      <InfoCircleOutlined style={{ marginLeft: 4 }} />
    </Popover>
  </span>
);

const ModuleLabel = () => (
  <Field
    addonBefore={Info()}
    size="large"
    placeholder="Internal title"
    name="label"
    component={Input}
  />
);

export default ModuleLabel;
