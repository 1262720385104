import React from "react";
import { ColorItem } from "components/uielements/inputTypeFromSchema/colorSelect";
import { Dropdown, Menu } from "antd";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";
import { useConfig } from "hooks/use-config";

interface ListProps {
  onChange: (color?: string) => void;
  hasColor?: boolean;
}

export const ColorList = ({ onChange, hasColor = false }: ListProps) => {
  const { config: globalConfig } = useConfig("global-colors");
  const availableColors = globalConfig?.colors || [];

  return (
    <Menu
      style={{
        height: "200px",
        overflow: "scroll",
      }}
    >
      {hasColor && (
        <Menu.Item
          style={{
            height: "32px",
            margin: 0,
            padding: 0,
          }}
          key="remove current color"
          onClick={() => onChange()}
        >
          <RemoveColor>remove current color</RemoveColor>
        </Menu.Item>
      )}

      {availableColors.map((color) => (
        <Menu.Item
          style={{
            height: "32px",
            margin: 0,
            padding: 0,
          }}
          key={color.hex}
          onClick={() => onChange(color.hex)}
        >
          <ColorItem color={color.hex}>
            {color.name?.replace(/-/g, " ")}
          </ColorItem>
        </Menu.Item>
      ))}
    </Menu>
  );
};

interface Props {
  value?: string;
  onChange: (color?: string) => void;
}

export const Color = ({ value, onChange }: Props) => {
  return (
    <Dropdown
      placement="bottomRight"
      trigger={["click"]}
      overlay={
        <ColorList
          hasColor={Boolean(value)}
          onChange={(color) => onChange(color)}
        />
      }
    >
      <div>
        {value && (
          <SwatchContainer
            title="Change color"
            style={{
              borderColor: value ? value : "var(--color--black-800)",
            }}
          >
            <Swatch style={{ backgroundColor: value }}></Swatch>
          </SwatchContainer>
        )}
        {!value && (
          <Add title="Add color">
            <PlusOutlined />
          </Add>
        )}
      </div>
    </Dropdown>
  );
};

const SwatchContainer = styled.button`
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--color--black-800);
  border-radius: 0.375rem;
  display: block;
  cursor: pointer;
  height: 2rem;
  padding: 1px;
  width: 100%;
`;

const Swatch = styled.div`
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Add = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--color--black-800);
  border-radius: 0.375rem;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 2rem;
  gap: 0.25rem;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    border-color: var(--color--blue-400);
    color: var(--color--blue-400);
  }
`;

const RemoveColor = styled.span`
  align-items: center;
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;

  height: 32px;
  text-align: center;
  width: 100%;
`;
