const allergensActions = {
  FETCH_ALLERGENS: "/koala-cms/allergens/FETCH_ALLERGENS",
  FETCH_ALLERGENS_SUCCESS: "/koala-cms/allergens/FETCH_ALLERGENS_SUCCESS",
  FETCH_ALLERGENS_FAIL: "/koala-cms/allergens/FETCH_ALLERGENS_FAIL",
  POST_ALLERGEN: "/koala-cms/allergens/POST_ALLERGEN",
  POST_ALLERGEN_SUCCESS: "/koala-cms/allergens/POST_ALLERGEN_SUCCESS",
  POST_ALLERGEN_FAIL: "/koala-cms/allergens/POST_ALLERGEN_FAIL",
  DELETE_ALLERGEN: "/koala-cms/allergens/DELETE_ALLERGEN",
  DELETE_ALLERGEN_SUCCESS: "/koala-cms/allergens/DELETE_ALLERGEN_SUCCESS",
  DELETE_ALLERGEN_FAIL: "/koala-cms/allergens/DELETE_ALLERGEN_FAIL",
  UPDATE_ALLERGEN: "/koala-cms/allergens/UPDATE_ALLERGEN",
  UPDATE_ALLERGEN_SUCCESS: "/koala-cms/allergens/UPDATE_ALLERGEN_SUCCESS",
  UPDATE_ALLERGEN_FAIL: "/koala-cms/allergens/UPDATE_ALLERGEN_FAIL",

  postAllergen: (payload) => ({
    type: allergensActions.POST_ALLERGEN,
    payload,
  }),

  deleteAllergen: (id) => ({
    type: allergensActions.DELETE_ALLERGEN,
    id,
  }),

  updateAllergen: (id, payload) => ({
    type: allergensActions.UPDATE_ALLERGEN,
    id,
    payload,
  }),
};

export default allergensActions;
