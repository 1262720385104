import { put, call, takeLatest, all } from "redux-saga/effects";
import { message } from "antd";

// Actions
import actions from "./actions";
import authActions from "redux/auth/actions";

// Services
import { fetchMe, fetchMePermissions, updateMe } from "services/me.service";
import { fetchUserGroupScopes } from "services/users.service";

export function* fetchMeSaga() {
  try {
    let [meResponse, permissionsResponse] = yield all([
      yield call(fetchMe),
      yield call(fetchMePermissions),
    ]);

    if (meResponse) {
      const scopesResponse = yield call(fetchUserGroupScopes, meResponse.id);
      yield put({
        type: actions.ME_SUCCESS,
        detail: meResponse,
        permissions: permissionsResponse?.data.data,
        groupScopes: scopesResponse.data,
      });
    }
  } catch (error) {
    console.log(error);
    yield put(authActions.logout());
  }
}

export function* updateMeSaga(action) {
  try {
    yield call(updateMe, action.payload);

    // Refetch user
    yield put({ type: actions.FETCH_ME });

    // Alert
    yield call(message.success, "Your profile was successfully updated!");
  } catch (error) {
    // @ts-expect-error Property 'USERS_FAIL' does not exist on type '{ FETCH_ME: string; UPDATE_ME: string; ME_SUCCESS: string; ME_FAIL: string; CLEAR_ME: string; }'
    yield put({ type: actions.USERS_FAIL });
    yield call(message.error, "There was an error updating your profile");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ME, fetchMeSaga);
  yield takeLatest(actions.UPDATE_ME, updateMeSaga);
}
