import React, { ReactNode } from "react";
import { LOGO } from "constants/config";
import Copyright from "components/uielements/copyright";
import { styled } from "components/ui/stitches";

interface Props {
  title: string;
  description: string;
  children: ReactNode;
}

export function Container({ title, description, children }: Props) {
  return (
    <Content>
      <Logo src={LOGO} />

      <Main>
        <Title>{title}</Title>

        <Description>{description}</Description>

        {children}
      </Main>

      <Footer>
        <Copyright />
      </Footer>
    </Content>
  );
}

const Content = styled("div", {
  background: "white",
  display: "grid",
  gridTemplateRows: "auto 1fr auto",
  minHeight: "100vh",
  padding: "$12",
  width: "100%",
});

const Logo = styled("img", {
  width: "$32",
});

const Main = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "$md",
  margin: "0 auto",
  justifyContent: "center",
  width: "100%",
});

const Title = styled("h1", {});

const Description = styled("p", {
  opacity: 0.5,
  padding: "0 0 $4",
});

const Footer = styled("footer", {
  textAlign: "right",
});
