export enum UpsellsStep {
  add = "add_product",
  checkout = "checkout",
}

export enum CrossSellTarget {
  web = "web",
  kiosk = "kiosk",
  mobile = "mobile",
}

export enum CrossSellCartKey {
  product = "product",
  category = "category",
  wildcard = "*",
}

export enum CrossSellOperator {
  equals = "=",
  notEquals = "!=",
}

export interface CrossSellMatch {
  name: string;
  global_id: string;
}

export interface CrossSellRule {
  operator: CrossSellOperator;
  cart_key: CrossSellCartKey;
  match: CrossSellMatch[];
}

export interface CrossSellItem {
  name: string;
  global_id: string;
}

export interface CrossSellRuleSet {
  id: string;
  name: string;
  rules: CrossSellRule[];
  target?: CrossSellTarget[];
  cross_sells: CrossSellItem[];
}
