import React, { ReactNode } from "react";
import styled from "styled-components";

interface BlockProps {
  attributes: any;
  children: ReactNode;
  element: {
    href: string;
  };
}

const Paragraph = (props: BlockProps) => (
  <p {...props.attributes}>{props.children}</p>
);
const OrderedList = (props: BlockProps) => (
  <ol {...props.attributes}>{props.children}</ol>
);
const UnorderedList = (props: BlockProps) => (
  <ul {...props.attributes}>{props.children}</ul>
);
const ListItem = (props: BlockProps) => (
  <li {...props.attributes}>{props.children}</li>
);
const Anchor = (props: BlockProps) => (
  <Link
    title={props.element.href}
    {...props.attributes}
    href={props.element.href}
    target="_blank"
  >
    {props.children}
  </Link>
);

const Link = styled.a`
  border-bottom: 1px solid var(--color--black-400);
  cursor: default;
`;

export const Elements = {
  Paragraph,
  UnorderedList,
  OrderedList,
  ListItem,
  Anchor,
};

const getSize = (size: string) => {
  switch (size) {
    case "small":
      return "12px";
    case "medium":
      return "14px";
    case "large":
      return "18px";
    case "x-large":
      return "24px";
    default:
      return "14px";
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  const {
    color = "inherit",
    size = "inherit",
    bold = false,
    italic = false,
  } = leaf;

  return (
    <span
      {...attributes}
      style={{
        color,
        fontSize: getSize(size),
        fontWeight: bold ? "bold" : "normal",
        fontStyle: italic ? "italic" : "normal",
      }}
    >
      {children}
    </span>
  );
};
