import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch modules
 */
export function fetchModules(magicBoxParams) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${
      API.routes.modules
    }?${magicBoxParams.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
