import actions from "./actions";

// Const
import {
  resourceDefaults as rd,
  resourceKeys as rk,
} from "constants/resources/keys";

// Utils
import { MagicBoxParams, parseSortsFromTable } from "utils/magicBox";

const initialState = {
  list: [],
  loading: false,
  listMeta: {},
  magicBox: new MagicBoxParams()
    .setIncludes(rd[rk.activeDevices].includes)
    .setSorts(parseSortsFromTable(rd[rk.activeDevices].sorts))
    .setFilters(rd[rk.activeDevices].filters),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ACTIVE_DEVICES:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.ACTIVE_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
        listMeta: action.listMeta,
        magicBox: action.magicBox,
      });
    default:
      return state;
  }
}
