import React from "react";
import { Input } from "antd";

const VideoInput = (props) => {
  const { onSubmitVideo } = props;

  return (
    <div>
      <Input
        onChange={(e) => onSubmitVideo(e.target.value)}
        addonBefore="Video Link"
        name="data.desktop_video_url"
        type="text"
      />
      <div style={{ marginTop: 10 }}>
        Video should be an absolute URL path to an external CDN (i.e. not a file
        that lives locally on your computer). Recommended aspect ratio is 16:9.
        For best performance, video should not exceed 5 MB. Note that the video
        will only play on desktop.
      </div>
    </div>
  );
};

export default VideoInput;
