import React from "react";
import type * as Stitches from "@stitches/react";
import { Base, Field } from "./base";

export interface TextareaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  required?: boolean;
  error?: string;
  css?: Stitches.CSS;
}

export function Textarea({
  label,
  name,
  required = false,
  error,
  css = {},
  ...otherProps
}: TextareaProps) {
  const baseProps = { label, name, required, error };

  return (
    <Base {...baseProps}>
      <Field
        id={name}
        required={required}
        as="textarea"
        placeholder=" "
        aria-label={label}
        css={{
          height: "$24",
          ...css,
        }}
        {...otherProps}
      />
    </Base>
  );
}
