// Types
import { BasketItem } from "@chowly/koala-sdk-js";

export const ADD_ITEM = "/crossSell/cart/ADD_ITEM";
export const REMOVE_ITEM = "/crossSell/cart/REMOVE_ITEM";

export const addToCart = (item: BasketItem) => ({
  type: ADD_ITEM,
  item,
});

export const removeFromCart = (index: number) => ({
  type: REMOVE_ITEM,
  index,
});
