const revisionActions = {
  FETCH_REVISIONS: "/koala-cms/revision/FETCH_REVISIONS",
  FETCH_REVISION: "/koala-cms/revision/FETCH_REVISION",
  POST_REVISION: "/koala-cms/revision/POST_REVISION",
  REVERT_REVISION: "/koala-cms/revision/REVERT_REVISION",
  REVISIONS_SUCCESS: "/koala-cms/revision/REVISIONS_SUCCESS",
  REVISIONS_FAIL: "/koala-cms/revision/REVISIONS_FAIL",

  REVISION_SUCCESS: "/koala-cms/revision/REVISION_SUCCESS",
  REVISION_FAIL: "/koala-cms/revision/REVISION_FAIL",

  CLEAR_REVISION: "/koala-cms/revision/CLEAR_REVISION",
};

export default revisionActions;
