import { put, call, takeLatest, select } from "redux-saga/effects";
import { message } from "antd";
import get from "lodash/get";
import { ROUTES } from "constants/routes";
import {
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
  fetchUserGroupScopes,
  updateUserGroupScopes,
} from "services/users.service";
import actions from "./actions";

export function* fetchUsersSaga(action) {
  try {
    const response = yield call(fetchUsers, action.magicBox);
    yield put(
      actions.usersSuccess(response.data, response.meta, action.magicBox)
    );
  } catch (error) {
    console.log(error);
    yield put({ type: actions.USERS_FAIL });
  }
}

export function* resetUsersSaga() {
  try {
    yield put({ type: actions.CLEAR_USERS });
    const state = yield select();
    yield put({
      type: actions.FETCH_USERS,
      magicBox: get(state, "app.users.magicBox"),
    });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.USERS_FAIL });
  }
}

export function* fetchUserSaga(action) {
  try {
    const response = yield call(fetchUser, action.id);
    const scopes = yield call(fetchUserGroupScopes, action.id);
    yield put({
      type: actions.USER_SUCCESS,
      detail: { ...response, groupScopes: scopes.data },
    });
  } catch (error) {
    yield put({ type: actions.USER_FAIL });
  }
}

export function* createUserSaga(action) {
  try {
    const payload = {
      ...action.payload,
      app_url: `${window.location.origin}${ROUTES.AUTH.PASSWORD_RESET}`,
    };
    yield call(createUser, payload);
    yield call(
      message.success,
      `${action.payload.username} successfully created!`
    );
  } catch (error) {
    console.log("createUser fail", error);
    yield put({ type: actions.USER_FAIL });
    yield call(message.error, "There was an error creating the user");
  }
}

export function* updateUserSaga(action) {
  try {
    if (action.payload.name) {
      yield call(updateUser, action.payload);
    }
    if (action.payload.groups) {
      yield call(updateUserGroupScopes, action.payload);
    }

    yield call(message.success, `User successfully updated!`);
  } catch (error) {
    console.log("updateUser fail", error);
    yield put({ type: actions.USER_FAIL });
    yield call(message.error, "There was an error updating the user");
  }
}

export function* deleteUserSaga(action) {
  try {
    const response = yield call(deleteUser, action.id);
    if (response.status === 204) {
      yield call(message.success, `User has been deleted`);
    }
  } catch (error) {
    console.log("deleteUser fail", error);
    yield put({ type: actions.USER_FAIL });
    yield call(message.error, "There was an error deleting the user");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_USERS, fetchUsersSaga);
  yield takeLatest(actions.RESET_USERS, resetUsersSaga);
  yield takeLatest(actions.FETCH_USER, fetchUserSaga);
  yield takeLatest(actions.CREATE_USER, createUserSaga);
  yield takeLatest(actions.UPDATE_USER, updateUserSaga);
  yield takeLatest(actions.DELETE_USER, deleteUserSaga);
}
