import { FormErrors } from "redux-form";
import { FixMeAny } from "types/fix-me-any";

export const assetValidation = (values) => {
  const errors: FormErrors<FixMeAny, string> = {};

  if (!values.label) {
    errors.label = "Required";
  }

  if (!values.file) {
    errors.file = "Required";
  }

  return errors;
};
