import {
  LaptopOutlined,
  TabletOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { CrossSellTarget } from "types/cross-sells";
import React from "react";

export interface RuleFilterType {
  key: CrossSellTarget;
  text: string;
  iconType?: React.ReactNode;
  isEnabled: boolean;
}

export interface RuleFiltersType {
  [key: string]: RuleFilterType;
}

export const ruleFiltersConfig: RuleFiltersType = {
  web: {
    key: CrossSellTarget.web,
    text: "Web",
    isEnabled: true,
    iconType: <LaptopOutlined style={{ marginRight: 10 }} />,
  },
  kiosk: {
    key: CrossSellTarget.kiosk,
    text: "Kiosk",
    isEnabled: true,
    iconType: <TabletOutlined style={{ marginRight: 10 }} />,
  },
  mobile: {
    key: CrossSellTarget.mobile,
    text: "Mobile",
    isEnabled: true,
    iconType: <MobileOutlined style={{ marginRight: 10 }} />,
  },
};

export const ruleFiltersOptions: CrossSellTarget[] = Object.keys(
  ruleFiltersConfig
).map((key: string) => ruleFiltersConfig[key].key);
