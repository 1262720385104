import { message, notification } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAllergies,
  fetchGlobalProducts,
  fetchGlobalProduct,
  updateGlobalProduct,
  updateGlobalProductImage,
} from "services/globalMenu.service";
import actions from "./actions";
import { deriveImageUploadError } from "../../utils/errors";

export function* fetchGlobalProductsSaga(action) {
  try {
    const qs = action.magicBox.toQuery();

    const response = yield call(fetchGlobalProducts, qs);

    yield put({
      type: actions.GLOBAL_PRODUCTS_SUCCESS,
      data: response.data,
      magicBox: action.magicBox,
    });
  } catch (error) {
    yield put({ type: actions.GLOBAL_PRODUCTS_FAIL });
    yield call(message.error, "There was an error loading your products.");
  }
}

export function* fetchGlobalProductSaga(action) {
  try {
    const response = yield call(fetchGlobalProduct, action.id);

    yield put({
      type: actions.GLOBAL_PRODUCT_SUCCESS,
      detail: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GLOBAL_PRODUCTS_FAIL });
    yield call(message.error, "There was an error getting the global product.");
  }
}

export function* updateGlobalProductSaga(action) {
  try {
    yield call(updateGlobalProduct, action.payload, action.id);

    // Stop spinner
    yield put({ type: actions.STOP_LOADING_STATE });

    yield put(actions.fetchGlobalProduct(action.id));

    // Success Alert
    yield call(notification.success, {
      message: "Global product updated",
      description: "Changes may take up to 20 minutes to fully propagate.",
    });
  } catch (error) {
    // Stop spinner
    yield put({ type: actions.GLOBAL_PRODUCTS_FAIL });
    // Error alert
    yield call(
      message.error,
      "There was an error updating the global product."
    );
  }
}

export function* updateGlobalProductImageSaga(action) {
  try {
    const response = yield call(
      updateGlobalProductImage,
      action.id,
      action.key,
      action.value
    );

    // Success Alert
    yield call(notification.success, {
      message: "Global product updated",
      description: "Changes may take up to 20 minutes to fully propagate.",
    });

    // Attempt to update list view with generic action
    // NOTE - the key we use to POST the new image differs from the key we get in the response
    const key = action.key.replace("image", "image_url");
    yield put({
      type: actions.ATTEMPT_GLOBAL_PRODUCT_LIST_MODIFICATION,
      id: action.id,
      key,
      value: response.data.data[key],
    });
  } catch (error) {
    // Stop spinner
    yield put({ type: actions.GLOBAL_PRODUCTS_FAIL });

    // Error alert
    yield call(message.error, deriveImageUploadError(error));
  }
}

export function* fetchAllergiesSaga() {
  try {
    const response = yield call(fetchAllergies);
    yield put({ type: actions.ALLERGIES_SUCCESS, data: response.data });
  } catch (error) {
    yield call(message.error, "There was an error fetching allergens.");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_GLOBAL_PRODUCTS, fetchGlobalProductsSaga);
  yield takeLatest(actions.FETCH_GLOBAL_PRODUCT, fetchGlobalProductSaga);
  yield takeLatest(actions.UPDATE_GLOBAL_PRODUCT, updateGlobalProductSaga);
  yield takeLatest(
    actions.UPDATE_GLOBAL_PRODUCT_IMAGE,
    updateGlobalProductImageSaga
  );
  yield takeLatest(actions.FETCH_ALLERGIES, fetchAllergiesSaga);
}
