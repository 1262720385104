import { message } from "antd";
import get from "lodash/get";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { CrossSellRuleSet } from "types/cross-sells";

import {
  fetchCrossSellRules,
  fetchCrossSellsPreview,
  postCrossSellRules,
} from "services/crossSell.service";

import { Upsells as CrossSellResponse } from "@chowly/koala-sdk-js";

// Actions
import {
  FETCH_CROSS_SELLS,
  FETCH_RULES,
  fetchRules,
  requestFailed,
  SAVE_RULES,
  setRecommendations,
  setRules,
} from "./actions";

interface IRuleSetResponse {
  data: {
    id: string;
    rulesets: CrossSellRuleSet[];
    organization_id: number;
  };
}

export function* fetchCrossSellRulesSaga() {
  try {
    const response: IRuleSetResponse = yield call(fetchCrossSellRules);

    yield put(setRules(response.data.rulesets));
  } catch (error) {
    yield put(requestFailed());
    if (error.response.status !== 404) {
      message.error("Error fetching cross-sell rules.");
    }
  }
}

export function* saveCrossSellRulesSaga() {
  try {
    // Access current rules
    const state = yield select();

    yield call(postCrossSellRules, get(state, "app.crossSell.rules", {}));

    yield put(fetchRules());

    message.success("Cross-sell rules saved!");
  } catch (error) {
    yield put(requestFailed());
    message.error("Error saving cross-sell rules.");
  }
}

export function* fetchCrossSellsSaga(action) {
  try {
    // Access current rules
    const state = yield select();

    // Access cross-sells variables from state
    const locationId = get(state, "app.crossSell.locationId");

    const response: CrossSellResponse = yield call(
      fetchCrossSellsPreview,
      action.cartItems,
      locationId
    );

    yield put(setRecommendations(response));
  } catch (error) {
    yield put(requestFailed());
    message.error("Error fetching cross-sells.");
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_RULES, fetchCrossSellRulesSaga);
  yield takeLatest(SAVE_RULES, saveCrossSellRulesSaga);
  yield takeLatest(FETCH_CROSS_SELLS, fetchCrossSellsSaga);
}
