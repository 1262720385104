import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  DesktopOutlined,
  MobileOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { useWindowMessaging } from "utils/content";

function getSize(size: frameSize): string {
  switch (size) {
    case frameSize.mobile:
      return "375px";
    case frameSize.tablet:
      return "768px";
    case frameSize.desktop:
      return "100%";
    default:
      return "100%";
  }
}
interface Props {
  id?: string;
  shouldRefresh: boolean;
  confirmRefresh: () => void;
  onShow: (id?: string) => void;
  onHighlight: (id?: string) => void;
  highlighted?: string;
  origin: string;
}

enum events {
  createConnection = "create_connection",
  requestRefresh = "request_refresh",
  confirmRefresh = "confirm_refresh",
  setId = "set_id",
  selectModule = "select_module",
  highlightModule = "highlight_module",
}

enum frameSize {
  mobile = "mobile",
  tablet = "tablet",
  desktop = "desktop",
}

export function Preview({
  id,
  shouldRefresh,
  confirmRefresh,
  origin,
  onShow,
  onHighlight,
  highlighted,
}: Props) {
  const [size, setSize] = useState(frameSize.desktop);
  const [hasConnection, setHasConnection] = useState(false);
  const [hasError, setHasError] = useState(false);
  const src = `${origin}/previewer`;
  const { ref, message, sendMessage } = useWindowMessaging(origin, [
    id,
    shouldRefresh,
    hasConnection,
  ]);

  useEffect(
    function () {
      if (!message) {
        return;
      }

      switch (message.type) {
        case events.createConnection:
          setHasConnection(true);

          message.sendBack({
            type: events.setId,
            id,
          });

          break;
        case events.selectModule:
          onShow(message?.id ? String(message.id) : undefined);

          break;
        case events.highlightModule:
          onHighlight(message?.id ? String(message.id) : undefined);

          break;
        default:
          console.warn(`${message.type} is not a supported message`);
          break;
      }
    },
    [message]
  );

  useEffect(
    function () {
      if (highlighted) {
        sendMessage({
          type: events.highlightModule,
          id: highlighted,
        });
      }
    },
    [highlighted]
  );

  useEffect(
    function () {
      if (shouldRefresh && id) {
        sendMessage({
          type: events.requestRefresh,
        });

        confirmRefresh();
      }
    },
    [shouldRefresh, id]
  );

  // we have a 30 second timeout for the iframe connection
  // if the iframe connection doesn't finish in that time, we show an error
  useEffect(
    function () {
      const timer = setTimeout(() => {
        setHasError(true);
      }, 30000);

      if (hasConnection) {
        clearTimeout(timer);
      }

      return function () {
        clearTimeout(timer);
      };
    },
    [hasConnection]
  );

  return (
    <Container>
      <FrameWrapper>
        {id !== "" && <Frame size={size} ref={ref} src={src} />}

        {!hasConnection && <Loading>Loading...</Loading>}
        {hasError && <Error>unable to load previewer</Error>}
      </FrameWrapper>

      <Toolbar>
        <SizeButton
          active={size === frameSize.mobile}
          onClick={() => setSize(frameSize.mobile)}
        >
          <span>
            <MobileOutlined />
          </span>
          mobile
        </SizeButton>
        <SizeButton
          active={size === frameSize.tablet}
          onClick={() => setSize(frameSize.tablet)}
        >
          <span>
            <TabletOutlined />
          </span>
          tablet
        </SizeButton>
        <SizeButton
          active={size === frameSize.desktop}
          onClick={() => setSize(frameSize.desktop)}
        >
          <span>
            <DesktopOutlined />
          </span>
          desktop
        </SizeButton>
      </Toolbar>
    </Container>
  );
}

const Container = styled.div`
  background-color: var(--color--black-800);
  box-shadow: inset 0px 2px 4px 0px hsla(0, 0%, 0%, 0.12);
  display: grid;
  gap: 1rem;
  grid-template-rows: 1fr 4rem;
  padding: 1rem;
  position: relative;
  z-index: 2;
`;

const FrameWrapper = styled.div`
  border: none;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Frame = styled.iframe`
  ${({ size }) => `
    --frame--width: ${getSize(size)};
  `}

  background-color: white;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.12);
  height: 100%;
  overflow: scroll;
  transition: all 0.3s ease;
  width: var(--frame--width, 100%);
  z-index: 20 !important; // required because we set all iframes as -1 due to create react app
`;

const Loading = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.25rem;
  color: #333;
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  position: absolute;
  inset: 1rem;
  z-index: 10;
`;

const Error = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.25rem;
  color: var(--color--red-400);
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  position: absolute;
  inset: 1rem;
  z-index: 10;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
`;

const SizeButton = styled.button`
  ${({ active }) => `
    color: ${active ? "var(--color--blue-400)" : "var(--color--black-400)"};
  `}

  appearance: none;
  background-color: transparent;
  border: none;

  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.675rem;

  gap: 0.25rem;
  padding: 0;
  transition: all 0.2s ease;

  span {
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 4px 0px hsla(0, 0%, 0%, 0.12);
    border-radius: 100%;
    flex: none;
    display: flex;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
  }

  &:hover {
    color: var(--color--blue-400);
  }

  svg {
    color: currentColor;
    height: 1rem;

    width: auto;
  }
`;
