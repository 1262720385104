import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";
import React from "react";
import debounce from "lodash/debounce";
import styled from "styled-components";

const { Option } = AutoComplete;

interface Props {
  data: any[];
  onSearch: any;
  onSelect: any;
  placeholder?: string;
}

export const SearchAssets = ({
  data,
  onSearch,
  onSelect,
  placeholder = "",
}: Props) => (
  <AutoComplete
    onSearch={debounce(onSearch, 250)}
    onSelect={(value, option) => onSelect(value, option)}
    placeholder={
      <>
        <SearchOutlined /> {placeholder}
      </>
    }
    style={{ width: "100%" }}
    size="large"
    allowClear
    autoFocus
  >
    {data.map((item) => (
      <Option key={item.uuid} value={item.label}>
        <AssetResult image={item.url}>
          <div className="img-preview" />
          {item.label}
        </AssetResult>
      </Option>
    ))}
  </AutoComplete>
);

const AssetResult = styled.div`
  display: flex;
  align-items: center;
  .img-preview {
    border: 1px solid #efefef;
    background-color: #efefef;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center center;
    height: 50px;
    margin-right: 10px;
    width: 50px;
  }
`;
