import React from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { FixMeAny } from "types/fix-me-any";

// Const
import {
  resourceKeys as rk,
  resourceAttrs as ra,
} from "constants/resources/keys";

// Actions
import assetActions from "./actions";

function assetsHoc(WrappedComponent): FixMeAny {
  const AssetsHoc = (props) => (
    <WrappedComponent
      {...props}
      fetchAssetsDebounced={debounce(props.fetchAssets, 250)}
    />
  );

  const mapStateToProps = (state) => ({
    resourceKey: rk.assets,
    [`${rk.assets}${ra.list}`]: state.app[rk.assets].list,
    [`${rk.assets}${ra.loading}`]: state.app[rk.assets].loading,
    [`${rk.assets}${ra.magicBox}`]: state.app[rk.assets].magicBox,
    [`${rk.assets}${ra.meta}`]: state.app[rk.assets].listMeta,

    // TODO
    assetDetail: state.app.assets.detail,
  });

  const mapDispatchToProps = (dispatch) => ({
    fetchAssets: (magicBox) => dispatch(assetActions.fetchAssets(magicBox)),
    postAsset: (formData) => dispatch(assetActions.postAsset(formData)),
    updateAsset: (formData, id) =>
      dispatch(assetActions.updateAsset(formData, id)),
    deleteAsset: (assetId) => dispatch(assetActions.deleteAsset(assetId)),

    pushAsset: (asset) => dispatch(assetActions.pushAsset(asset)),
    clearAsset: () => dispatch(assetActions.clearAsset()),
    clearAllAssets: () => dispatch(assetActions.clearAll()),
  });

  return connect(mapStateToProps, mapDispatchToProps)(AssetsHoc);
}

export default assetsHoc;
