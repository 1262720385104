import styled from "styled-components";

export const StyledGroup = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const StyledSubgroup = styled.div`
  display: flex;
`;

export const StyledSubgroupIndented = styled.div`
  margin-left: 20px;
  padding: 5px;
`;

export const StyledEnvGroup = styled.div`
  padding: 5px;
`;
