import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch global menu
 */
export function fetchGlobalProducts(qs) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.globalProducts}?${qs}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function fetchGlobalProduct(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.globalProducts}/${id}?include[]=verifications&include[]=allergens&include[]=tags&include[]=global_product_options`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function updateGlobalProduct(payload, id) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.globalProducts}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

export function updateGlobalProductImage(id, imageKey, imageValue) {
  const { token_type, access_token } = AccessToken.get();
  const formData = new FormData();
  formData.append(imageKey, imageValue);
  formData.append("_method", "put");

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.globalProducts}/${id}/${imageKey}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
    data: formData,
  });
}

export function fetchAllergies() {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.allergies}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}
