import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

// Services
import {
  deleteTag,
  fetchTags,
  postTag,
  updateTag,
  updateTagIconImage,
} from "services/tags.service";

// Actions
import actions from "./actions";

export function* fetchTagsSaga(action) {
  try {
    // @ts-expect-error Argument of type '(tagType: any, pageNumber: any) => Promise<AxiosResponse<any>>' is not assignable to parameter of type 'CallEffectNamedFn<{ [x: string]: Func1<any>; }, string>'.
    const response = yield call(fetchTags, action.tagType);
    yield put({
      type: actions.FETCH_TAGS_SUCCESS,
      data: response.data.data,
      tagType: action.tagType,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TAGS_FAIL });
    yield call(message.error, "There was an error fetching tags.");
  }
}

export function* postTagSaga(action) {
  try {
    const response = yield call(postTag, action.payload);

    if (action.payload.icon_image) {
      // If there's an icon_image, append it to tag we just created
      yield put(
        actions.updateTagIconImage(
          response.data.data.id,
          action.payload.icon_image
        )
      );
    } else {
      yield put(actions.fetchTags(response.data.data.type));
      yield put({ type: actions.POST_TAG_SUCCESS });
      yield call(message.success, "Success!");
    }
  } catch (error) {
    yield put({ type: actions.POST_TAG_FAIL });
    yield call(message.error, "There was an error posting a new tag.");
  }
}

export function* updateTagIconImageSaga(action) {
  try {
    const response = yield call(
      updateTagIconImage,
      action.id,
      action.imageFile
    );
    yield put(actions.fetchTags(response.data.data.type));
    yield put({ type: actions.POST_TAG_SUCCESS });
    yield call(message.success, "Success!");
  } catch (error) {
    yield put({ type: actions.UPDATE_TAG_ICON_IMAGE_FAIL });
    yield call(
      message.error,
      "There was an error adding an icon to tag. Edit tag to try again."
    );
  }
}

export function* deleteTagSaga(action) {
  try {
    yield call(deleteTag, action.id);
    yield put({ type: actions.DELETE_TAG_SUCCESS });
    yield put(actions.fetchTags(action.tagType));
    yield call(message.success, "Tags successfully deleted");
  } catch (error) {
    yield put({ type: actions.DELETE_TAG_FAIL });
    yield call(message.error, "There was an error deleting tag.");
  }
}

export function* updateTagSaga(action) {
  try {
    const response = yield call(updateTag, action.id, action.payload);

    if (action.payload.icon_image || action.payload.icon_image_url) {
      // If there's an icon_image, append it to tag we just created
      yield put(
        actions.updateTagIconImage(action.id, action.payload.icon_image)
      );
    } else {
      yield put({ type: actions.UPDATE_TAG_SUCCESS });
      yield put(actions.fetchTags(response.data.data.type));
      yield call(message.success, "Success!");
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_TAG_FAIL });
    yield call(message.error, "There was an error updating the tag.");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_TAGS, fetchTagsSaga);
  yield takeLatest(actions.POST_TAG, postTagSaga);
  yield takeLatest(actions.DELETE_TAG, deleteTagSaga);
  yield takeLatest(actions.UPDATE_TAG, updateTagSaga);
  yield takeLatest(actions.UPDATE_TAG_ICON_IMAGE, updateTagIconImageSaga);
}
