import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

/**
 * Fetch pages
 */
export function fetchCategories() {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.categories}?per_page=50`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}
