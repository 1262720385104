import actions from "./actions";

const initialState = {
  loading: false,
  scopes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.GET_AUTH_SCOPES:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.AUTH_SCOPES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        scopes: action.scopes,
      });
    default:
      return state;
  }
}
