import { SDK } from "services/koala.service";
import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";
import { UpsellsStep } from "types/cross-sells";
import { BasketItem } from "@chowly/koala-sdk-js";
import { UPSELL_DRIVERS } from "constants/crossSell/config";

/**
 * Fetch Cross-Sell Rules
 */
export function fetchCrossSellRules() {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}${API.routes.recommendations}/organization/cross-sell/ruleset/active`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

/**
 * Post Cross-Sell Rules
 */
export function postCrossSellRules(rulesets) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}${API.routes.recommendations}/organization/cross-sell/ruleset`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      rulesets,
    },
  });
}

/**
 * Fetch Cross-sells even when the org does not have an up-sell engine enabled
 */
export function fetchCrossSellsPreview(
  products: BasketItem[],
  locationId: number,
  engineId: number = UPSELL_DRIVERS.KOALA_RECOMMENDATIONS
) {
  return SDK.ordering.upsells.preview({
    products: products.map((item: any) => item.product.id),
    store_location_id: locationId,
    engine_id: engineId,
    num_upsells_requested: 5,
    step: UpsellsStep.add,
  });
}
