import React from "react";
import styled from "styled-components";

import { Input as AntInput } from "antd";

interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export function Text(props: Props) {
  return <Input {...props} onChange={(e) => props.onChange(e.target.value)} />;
}

const Input = styled(AntInput)`
  border-radius: 0.375rem;
  border-color: var(--color--black-800);
  font-size: 0.8125rem;
  height: 2rem;
  width: 100%;
`;
