import { call, put, takeLatest } from "redux-saga/effects";

// Actions
import actions from "./actions";

// Services
import { fetchOrgConfig } from "services/orgConfig.service";

export function* fetchOrgConfigSaga() {
  try {
    const data = yield call(fetchOrgConfig);

    // Success
    yield put({
      type: actions.ORG_CONFIG_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ORG_CONFIG_FAIL });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ORG_CONFIG, fetchOrgConfigSaga);
}
