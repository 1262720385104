import { put, call, takeLatest } from "redux-saga/effects";

// Services
import { fetchCategories } from "services/categories.service";

// Actions
import actions from "./actions";

export function* fetchCategoriesSaga() {
  try {
    const response = yield call(fetchCategories);

    yield put({ type: actions.CATEGORIES_SUCCESS, list: response.data.data });
  } catch (error) {
    console.log("Failed to fetch categories.");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_CATEGORIES, fetchCategoriesSaga);
}
