import React from "react";
import { Input, Typography } from "antd";
import { UrlField, UrlType } from "components/web-pages/modules/types";

interface Props {
  onChange: (value: UrlField) => void;
  value: UrlField;
}

export function ExternalLink({ onChange, value }: Props) {
  const { label = "" } = value;
  const {
    label: referenceLabel = "",
    url = "",
    newWindow = false,
    type = UrlType.local,
  } = value?.reference ?? {};

  return (
    <>
      <div>
        <Typography.Text strong>URL</Typography.Text>
        <Input
          placeholder="Enter a custom url..."
          value={type === UrlType.external ? url : ""}
          onChange={function (e) {
            onChange({
              ...value,
              reference: {
                newWindow,
                label: referenceLabel,
                url: e.target.value,
                type: UrlType.external,
              },
            });
          }}
        />
      </div>

      <div>
        <Typography.Text strong>Label</Typography.Text>
        <Input
          placeholder="Label..."
          value={label}
          onChange={function (e) {
            onChange({
              ...value,
              label: e.target.value,
            });
          }}
        />
      </div>
    </>
  );
}
