import actions from "./actions";

const initialState = {
  loading: false,
  detail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_ORGANIZATION:
    case actions.FETCH_ORGANIZATION:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
      });
    case actions.CLEAR_ORGANIZATION:
      return Object.assign({}, state, {
        loading: false,
        detail: null,
      });
    default:
      return state;
  }
}
