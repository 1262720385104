import { put, call, takeLatest, select } from "redux-saga/effects";
import get from "lodash/get";

// Services
import { fetchUsers } from "services/users.service";

// Actions
import actions from "./actions";

export function* fetchCustomersSaga(action) {
  try {
    // Fetch users
    const response = yield call(fetchUsers, action.magicBox);

    yield put({
      type: actions.CUSTOMERS_SUCCESS,
      list: response.data,
      listMeta: response.meta,
      magicBox: action.magicBox,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.CUSTOMERS_FAIL });
  }
}

export function* resetCustomersSaga(action) {
  try {
    yield put({ type: actions.CLEAR_CUSTOMERS });
    const state = yield select();
    yield put({
      type: actions.FETCH_CUSTOMERS,
      magicBox: get(state, "app.customers.magicBox"),
    });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.CUSTOMERS_FAIL });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_CUSTOMERS, fetchCustomersSaga);
  yield takeLatest(actions.RESET_CUSTOMERS, resetCustomersSaga);
}
