import { MagicBoxParams, parseSortsFromTable } from "utils/magicBox";
import actions from "./actions";
import {
  resourceDefaults as rd,
  resourceKeys as rk,
} from "../../constants/resources/keys";
import { IUsers } from "types/app";

const initialState: IUsers = {
  loading: false,
  list: [],
  detail: null,
  meta: {},
  magicBox: new MagicBoxParams()
    .setIncludes(rd[rk.users].includes)
    .setSorts(parseSortsFromTable(rd[rk.users].sorts))
    .setFilters(rd[rk.users].filters),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_USER:
    case actions.CREATE_USER:
    case actions.UPDATE_USER:
    case actions.FETCH_USERS:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
        meta: action.meta,
        magicBox: action.magicBox,
      });
    case actions.RESET_USERS:
    case actions.CLEAR_USERS:
      return Object.assign({}, state, {
        loading: false,
        list: [],
        meta: action.meta,
        magicBox: initialState.magicBox.clone(),
      });
    case actions.USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
      });
    case actions.USER_FAIL:
    case actions.USERS_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_USER:
      return Object.assign({}, state, {
        detail: null,
      });
    case actions.DELETE_USER:
    default:
      return state;
  }
}
