// Utils
import { MagicBoxParams, parseSortsFromTable } from "utils/magicBox";

// Const
import {
  resourceDefaults as rd,
  resourceKeys as rk,
} from "constants/resources/keys";

// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  list: [],
  listMeta: {},
  detail: null,
  magicBox: new MagicBoxParams().setSorts(
    parseSortsFromTable(rd[rk.assets].sorts)
  ),
  modalState: {
    open: false,
    content: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_ASSET:
    case actions.POST_ASSET:
    case actions.DELETE_ASSET:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.ASSETS_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.FETCH_ASSETS:
      return Object.assign({}, state, {
        loading: true,
        magicBox: action.magicBox,
      });
    case actions.ASSETS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
        listMeta: action.listMeta,
        magicBox: action.magicBox,
        modalState: initialState.modalState,
      });
    case actions.UPDATE_MODAL_STATE:
      return Object.assign({}, state, {
        modalState: action.modalState || initialState.modalState,
      });
    case actions.PUSH_ASSET:
      return Object.assign({}, state, {
        detail: action.asset,
      });
    case actions.CLEAR_ASSET:
      return Object.assign({}, state, {
        detail: initialState.detail,
      });
    case actions.CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
