const persistentTemplateActions = {
  FETCH_PERSISTENT_TEMPLATES:
    "/koala-cms/persistent-templates/FETCH_PERSISTENT_TEMPLATES",
  FETCH_PERSISTENT_TEMPLATE:
    "/koala-cms/persistent-templates/FETCH_PERSISTENT_TEMPLATE",
  FETCH_PERSISTENT_TEMPLATE_BY_ALIAS:
    "/koala-cms/persistent-templates/FETCH_PERSISTENT_TEMPLATE_BY_ALIAS",
  UPDATE_PERSISTENT_TEMPLATE:
    "/koala-cms/persistent-templates/UPDATE_PERSISTENT_TEMPLATE",
  PERSISTENT_TEMPLATES_SUCCESS:
    "/koala-cms/persistent-templates/PERSISTENT_TEMPLATES_SUCCESS",
  PERSISTENT_TEMPLATE_SUCCESS:
    "/koala-cms/persistent-templates/PERSISTENT_TEMPLATE_SUCCESS",
  PERSISTENT_TEMPLATES_FAIL:
    "/koala-cms/persistent-templates/PERSISTENT_TEMPLATES_FAIL",
  CLEAR_PERSISTENT_TEMPLATE:
    "/koala-cms/persistent-templates/CLEAR_PERSISTENT_TEMPLATE",
  CREATE_PERSISTENT_TEMPLATE:
    "/koala-cms/persistent-templates/CREATE_PERSISTENT_TEMPLATE",
  fetchPersistentTemplates: () => ({
    type: persistentTemplateActions.FETCH_PERSISTENT_TEMPLATES,
  }),
  fetchPersistentTemplate: (id: number) => ({
    type: persistentTemplateActions.FETCH_PERSISTENT_TEMPLATE,
    id,
  }),
  fetchPersistentTemplateByAlias: (orgId: number, alias: string) => ({
    type: persistentTemplateActions.FETCH_PERSISTENT_TEMPLATE_BY_ALIAS,
    orgId,
    alias,
  }),
  updatePersistentTemplate: (
    payload: object,
    id: number,
    orgId: number,
    alias?: string
  ) => ({
    type: persistentTemplateActions.UPDATE_PERSISTENT_TEMPLATE,
    payload,
    id,
    orgId,
    alias,
  }),
  createPersistentTemplate: (payload: object, noRedirect?: boolean) => ({
    type: persistentTemplateActions.CREATE_PERSISTENT_TEMPLATE,
    payload,
    noRedirect,
  }),
};

export default persistentTemplateActions;
