import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { AccessToken } from "storage/token";

/**
 * HOC (Higher-Order Component) to wrap restricted pages
 */
const restricted = (BaseComponent) =>
  class Restricted extends Component {
    renderComponentOrRedirect(props) {
      if (AccessToken.isAuthenticated()) {
        return <BaseComponent {...props} />;
      }

      AccessToken.destroy();
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { referrer: props.location.pathname },
          }}
        />
      );
    }

    render() {
      return <Route render={this.renderComponentOrRedirect} />;
    }
  };

export default restricted;
