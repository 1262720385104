import { message } from "antd";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchConfig,
  fetchLatestSchema,
  updateConfig,
} from "services/configuration.service";
import actions from "./actions";

export function* initializeConfigsSaga(
  action: ReturnType<typeof actions.initializeConfigs>
) {
  try {
    const [cmsSchema, globalColorsSchema] = yield all([
      call(fetchLatestSchema, "cms-config", action.organizationId),
      call(fetchLatestSchema, "global-colors", action.organizationId),
    ]);
    const [cmsConfig, globalColorsConfig] = yield all([
      call(
        fetchConfig,
        "cms-config",
        cmsSchema.data.data.version,
        action.organizationId
      ),
      call(
        fetchConfig,
        "global-colors",
        globalColorsSchema.data.data.version,
        action.organizationId
      ),
    ]);

    yield all([
      put({
        type: actions.FETCH_CONFIG_SUCCESS,
        label: "cms-config",
        data: {
          config: cmsConfig.data.data.data,
          schema: cmsSchema.data.data.data,
          latestVersion: 1,
        },
      }),
      put({
        type: actions.FETCH_CONFIG_SUCCESS,
        label: "global-colors",
        data: {
          config: globalColorsConfig.data.data.data,
          schema: globalColorsSchema.data.data.data,
          latestVersion: globalColorsSchema.data.data.version,
        },
      }),
    ]);
  } catch (error) {
    console.log(error, "error");
    yield call(message.error, "There was an issue initializing the CMS.");
    yield put({ type: actions.FETCH_CONFIG_FAILURE, label: "cms-config" });
    yield put({ type: actions.FETCH_CONFIG_FAILURE, label: "global-colors" });
  }
}

export function* fetchConfigSaga(
  action: ReturnType<typeof actions.fetchConfig>
) {
  try {
    const latestSchema = yield call(
      fetchLatestSchema,
      action.label,
      action.organizationId
    );
    const config = yield call(
      fetchConfig,
      action.label,
      latestSchema.data.data.version,
      action.organizationId
    );
    yield put({
      type: actions.FETCH_CONFIG_SUCCESS,
      label: action.label,
      data: {
        config: config.data.data.data,
        schema: latestSchema.data.data.data,
        latestVersion: latestSchema.data.data.version,
      },
    });
  } catch (error) {
    console.log(error, "error");
    yield call(message.error, "There was an issue fetching the config.");
    yield put({ type: actions.FETCH_CONFIG_FAILURE, label: action.label });
  }
}

export function* updateConfigSaga(
  action: ReturnType<typeof actions.updateConfig>
) {
  try {
    const response = yield call(
      updateConfig,
      action.label,
      action.schemaVersion,
      action.organizationId,
      action.payload
    );

    yield put({
      type: actions.UPDATE_CONFIG_SUCCESS,
      label: action.label,
      data: response.data.data.data,
    });

    yield call(
      message.success,
      "You have successfully updated your configuration."
    );
  } catch (error) {
    console.log(error, "error");
    yield call(message.error, "There was an issue saving the config.");
    yield put({ type: actions.UPDATE_CONFIG_FAILURE, lable: action.label });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.INITIALIZE_CONFIGS, initializeConfigsSaga);
  yield takeEvery(actions.FETCH_CONFIG, fetchConfigSaga);
  yield takeLatest(actions.UPDATE_CONFIG, updateConfigSaga);
}
