const orgConfigActions = {
  FETCH_ORG_CONFIG: "/koala-cms/org-config/FETCH_ORG_CONFIG",
  ORG_CONFIG_SUCCESS: "/koala-cms/org-config/ORG_CONFIG_SUCCESS",
  ORG_CONFIG_FAIL: "/koala-cms/org-config/ORG_CONFIG_FAIL",
  CLEAR_ORG_CONFIG: "/koala-cms/org-config/CLEAR_ORG_CONFIG",

  fetchOrgConfig: () => ({
    type: orgConfigActions.FETCH_ORG_CONFIG,
  }),
};

export default orgConfigActions;
