const assetActions = {
  FETCH_ASSETS: "/koala-cms/asset/FETCH_ASSETS",
  ASSETS_SUCCESS: "/koala-cms/asset/ASSETS_SUCCESS",
  ASSETS_FAIL: "/koala-cms/asset/ASSETS_FAIL",
  PUSH_ASSET: "/koala-cms/asset/PUSH_ASSET",
  POST_ASSET: "/koala-cms/asset/POST_ASSET",
  UPDATE_ASSET: "/koala-cms/asset/UPDATE_ASSET",
  DELETE_ASSET: "/koala-cms/asset/DELETE_ASSET",

  CLEAR_ALL: "/koala-cms/asset/CLEAR_ASSETS",
  CLEAR_ASSET: "/koala-cms/asset/CLEAR_ASSET",
  UPDATE_MODAL_STATE: "/koala-cms/asset/UPDATE_MODAL_STATE",

  fetchAssets: (magicBox) => ({
    type: assetActions.FETCH_ASSETS,
    magicBox,
  }),
  postAsset: (payload) => ({
    type: assetActions.POST_ASSET,
    payload,
  }),
  updateAsset: (payload, id) => ({
    type: assetActions.UPDATE_ASSET,
    payload,
    id,
  }),
  deleteAsset: (payload) => ({
    type: assetActions.DELETE_ASSET,
    payload,
  }),

  // Push asset into redux
  pushAsset: (asset) => ({
    type: assetActions.PUSH_ASSET,
    asset,
  }),

  // Success action for list fetch
  assetsSuccess: (list, listMeta, magicBox) => ({
    type: assetActions.ASSETS_SUCCESS,
    list,
    listMeta,
    magicBox,
  }),

  // Clears assets and asset mb from redux
  clearAll: () => ({
    type: assetActions.CLEAR_ALL,
  }),

  // Clears asset detail
  clearAsset: () => ({
    type: assetActions.CLEAR_ASSET,
  }),

  // Keeps track of asset container resource modal state
  updateModalState: (modalState) => ({
    type: assetActions.UPDATE_MODAL_STATE,
    modalState,
  }),
};

export default assetActions;
