import { useSelector } from "react-redux";
import {
  ConfigData,
  ConfigLabel,
  NullConfigData,
} from "redux/configurations/types";
import { RootState } from "types/app";

/**
 * Use the hook useCMSConfig instead of useConfig("cms-config") to acquire a CMS config
 * that adheres to the interface ICmsConfig. ICmsConfig is a modified version of
 * ICmsDeviceConfig that comes back from the API.
 */

/**
 * Select the desired ConfigData from state by label. Provides an optional ability to return
 * an object with empty config/schema objects instead of null values.
 *
 * Uses function overloads to determine the return type of the config data to support
 * the various ways in which these configs are used across the BrandCMS.
 *
 * @param label ConfigLabel - ex: "ios-config", "web-config")
 * @param returnEmpty boolean - return empty objects instead of null values
 * @returns ConfigData | NullConfigData
 */
export function useConfig(label: ConfigLabel): ConfigData | NullConfigData;
export function useConfig(label: ConfigLabel, returnEmpty: true): ConfigData;
export function useConfig(
  label: ConfigLabel,
  returnEmpty = false
): ConfigData | NullConfigData {
  const config = useSelector(
    (state: RootState) => state.app.configurations?.[label]
  );

  if (config) {
    return config;
  }

  if (returnEmpty) {
    return {
      config: {},
      schema: {
        properties: {},
        type: "object",
      },
      latestVersion: 0,
      loading: true,
    };
  }

  return {
    config: null,
    schema: null,
    latestVersion: 0,
    loading: true,
  };
}
