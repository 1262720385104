import { AnyAction } from "redux";

// Actions
import { ADD_ITEM, REMOVE_ITEM } from "./actions";

// Types
import { BasketItem } from "@chowly/koala-sdk-js";

const initialState: { items: BasketItem[] } = {
  items: [],
};

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case ADD_ITEM: {
      return {
        ...state,
        items: [...state.items, action.item],
      };
    }
    case REMOVE_ITEM: {
      return {
        ...state,
        items: state.items.filter((_, index) => index !== action.index),
      };
    }
    default:
      return state;
  }
}
