import styled from "styled-components";

export const StyledUploader = styled.div`
  .ant-upload-list {
    display: none !important;
  }
`;

export const StyledFileName = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0 0;
`;
