import styled from "styled-components";

interface Props {
  medium?: boolean;
}

export const Error = styled.div.attrs({ role: "alert" })`
  color: red;
  position: absolute;
  font-size: ${(props: Props) => (props.medium ? "1em" : "0.8em")};
  padding-left: 5px;
  padding-right: 5px;
  padding-top: ${(props: Props) => (props.medium ? "8px" : "2px")};
`;
