import { Token, UserCredentials } from "@chowly/koala-sdk-js";
import { Authentication } from "@chowly/koala-sdk-js/dist/authentication/authentication";
import { GenericService } from "@chowly/koala-sdk-js/dist/utils/generic.service";
import GLOBAL_ENV from "constants/global_env";

export class DecorateUserAuthenticationWithCaptcha {
  private readonly _siteKey: string = "";

  constructor(private _auth: Authentication) {
    this._siteKey = GLOBAL_ENV.RECAPTCHA_SITE_KEY || "";
    this.grecaptchaFromWindow();
  }

  async userCredentials(credentials: UserCredentials): Promise<Token> {
    return this.authServiceProxy("login").userCredentials(credentials);
  }

  async forgotCmsPassword(username: string, app_url: string): Promise<any> {
    return this.authServiceProxy("forgot_password").forgotCmsPassword(
      username,
      app_url
    );
  }

  async resetCmsPassword(
    username: string,
    password: string,
    token: string
  ): Promise<any> {
    return this.authServiceProxy("reset_password").resetCmsPassword(
      username,
      password,
      token
    );
  }

  grecaptchaFromWindow():
    | {
        enterprise?: undefined;
        ready: (callback: () => any) => void;
        execute: (siteKey: string, options: any) => Promise<string>;
      }
    | {
        ready?: undefined;
        execute?: undefined;
        enterprise: {
          ready: (callback: () => any) => void;
          execute: (siteKey: string, options: any) => Promise<string>;
        };
      }
    | undefined {
    //V3 and Enterprise reCaptcha are similar in implementation, but the grecaptcha shape changes slightly
    return window?.grecaptcha?.enterprise || window?.grecaptcha;
  }

  private canExecuteCaptchaTokenRequest(): boolean {
    return !!this.grecaptchaFromWindow() && !!this._siteKey;
  }

  private authServiceProxy(
    action: "login" | "forgot_password" | "reset_password"
  ): Authentication {
    /**
     * This is a workaround for the fact that the CMS is stuck using an old version of the SDK.
     *
     * The Authentication class has a private property called "http" that is used to make requests. This function
     * creates a copy of the Authentication class and its "http" property, and then overrides the "request" method to
     * append the captcha token to the request headers.
     */

    //Do not mutate the original services
    const authCopy: Authentication = Object.create(this._auth);
    const httpCopy: GenericService = Object.create(this._auth["http"]);

    httpCopy.request = async (
      path: string,
      options?: RequestInit,
      skipAuth: boolean = false
    ): Promise<any> => {
      //Just to make sure reCaptcha is still available when the request is made
      if (!this.canExecuteCaptchaTokenRequest()) {
        //No reCaptcha available, just make the request through the original http service.
        return this._auth["http"].request(path, options, skipAuth);
      }

      //Fetch the reCaptcha response token, append it to the request headers, and then make the request through
      //the original http service.
      return this.grecaptchaFromWindow()!.execute!(this._siteKey, {
        action: action,
      }).then(async (captchaToken: string) => {
        if (captchaToken) {
          //Appends the captcha token to request headers
          if (!options) {
            options = {};
          }

          options.headers = {
            ...options.headers,
            "X-Captcha-Response": captchaToken,
          };
        }

        return this._auth["http"].request(path, options, skipAuth);
      });
    };

    authCopy["http"] = httpCopy;

    return authCopy;
  }
}
