import React, { ReactElement } from "react";
import styled from "styled-components";
import { Field } from "components/web-pages/fields";
import { Section } from "components/web-pages/edit/section";
import { ModulesList } from "components/web-pages/modules/list";
import { getIcon } from "components/uielements/icons";
import { modules } from "../schema/types";
import { Module, Modules, ModuleType, ModuleValue } from "../types";
import { DeleteModule } from "./delete";
import { DropTargets, useSortable, DragHandle } from "utils/useSortable";

interface Props {
  id: string;
  data: Module;
  onChange: (value: Module) => void;
  onRemove: () => void;
  level: number;
  canSort: boolean;
}

export function Item({
  id,
  data,
  onChange,
  onRemove,
  level,
  canSort = false,
}: Props) {
  const { context, dropTarget } = useSortable(
    id,
    `${DropTargets.contentModule}-${level}`
  );
  const match: ModuleType = modules[data.type];

  const updateValue = (id: string, value: ModuleValue) => {
    onChange({
      ...data,
      [id]: value,
    });
  };

  if (!match) {
    return <Error>can't find module: {data.type}</Error>;
  }

  return (
    <div ref={dropTarget}>
      <Section
        id={id}
        isDragging={context.dragItem === id}
        prepend={
          canSort && (
            <DragHandle
              id={id}
              type={`${DropTargets.contentModule}-${level}`}
            />
          )
        }
        label={match.title}
        icon={getIcon(match?.icon ?? "")}
        isStatic={
          Boolean(
            Object.keys(match.fields).filter((id) => {
              const field = match.fields[id];

              return field.fieldType === "modules";
            }).length <= 0
          ) && Boolean(level !== 0)
        }
        actions={<DeleteModule onDelete={() => void onRemove()} />}
      >
        {match && (
          <>
            <Field.List>
              {Object.keys(match.fields).map((id) => {
                const field = match.fields[id];
                let component: ReactElement | null = null;

                switch (field.fieldType) {
                  case "button":
                    component = (
                      <Field.Button
                        // @ts-expect-error
                        value={data[id]}
                        onChange={(value) => void updateValue(id, value)}
                      />
                    );
                    break;

                  case "url":
                    component = (
                      <Field.Url
                        // @ts-expect-error
                        value={data[id]}
                        onChange={(value) => void updateValue(id, value)}
                      />
                    );
                    break;
                  case "dropdown":
                    component = (
                      <Field.Dropdown
                        // @ts-expect-error
                        value={data[id]}
                        options={field?.attributes?.values ?? []}
                        onChange={(value) => void updateValue(id, value)}
                      />
                    );
                    break;
                  case "image":
                    component = (
                      <Field.Image
                        // @ts-expect-error
                        value={data[id]}
                        // @ts-expect-error
                        onRemove={() => void updateValue(id, null)}
                        onChange={(value) => void updateValue(id, value)}
                      />
                    );
                    break;
                  case "color":
                    component = (
                      <Field.Color
                        // @ts-expect-error
                        value={data[id]}
                        onChange={(value) =>
                          void updateValue(id, String(value))
                        }
                      />
                    );
                    break;
                  case "checkbox":
                    component = (
                      <Field.Checkbox
                        // @ts-expect-error
                        value={data[id]}
                        label={field.label}
                        // @ts-expect-error
                        onChange={(value) => void updateValue(id, value)}
                      />
                    );
                    break;

                  case "layout":
                    component = (
                      <Field.Layout
                        // @ts-expect-error
                        value={data[id]}
                        // @ts-expect-error
                        onChange={(value) => void updateValue(id, value)}
                        supports={field.attributes?.supports ?? []}
                      />
                    );
                    break;
                  case "modules":
                    component = (
                      <ModulesList
                        level={level + 1}
                        // @ts-expect-error
                        data={data[id]}
                        onChange={(value: Modules) =>
                          // @ts-expect-error
                          void updateValue(id, value)
                        }
                        onSort={(order) =>
                          void updateValue(id, {
                            // @ts-expect-error
                            ...data[id],
                            order,
                          })
                        }
                        addLabel={field.attributes?.action ?? "Add module"}
                        validItems={field.attributes?.accepts ?? []}
                      />
                    );
                    break;

                  case "richtext":
                    component = (
                      <Field.RichText
                        // @ts-expect-error
                        value={data[id]}
                        onChange={(value) => void updateValue(id, value)}
                        supports={field.attributes?.supports ?? []}
                      />
                    );
                    break;

                  default:
                    component = (
                      <Field.Text
                        // @ts-expect-error
                        value={data[id]}
                        onChange={(value) => void updateValue(id, value)}
                        placeholder={field.attributes?.placeholder ?? ""}
                      />
                    );
                    break;
                }

                return (
                  <Field.Container
                    key={id}
                    level={level}
                    label={
                      field.fieldType !== "checkbox" ? field.label : undefined
                    }
                  >
                    {component}
                  </Field.Container>
                );
              })}
            </Field.List>
          </>
        )}
      </Section>
    </div>
  );
}

const Error = styled.span`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid var(--color--black-900);
  color: red;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 3rem;
  margin: 0 -1rem 0 0;
`;
