export const deviceConfigAliases = {
  KIOSK_COLORS: "kiosk-colors",
  KIOSK_FONTS: "kiosk-fonts",
  GLOBAL_COLORS: "global-colors",
  FEATURES: "features",
  WEB_CONFIG: "web-config",
  CMS_CONFIG: "cms-config",
  MARKETING_CONFIG: "marketing-config",
} as const;

export const labelAliases = {
  "Kiosk Colors": "kiosk-colors",
  "Kiosk Fonts": "kiosk-fonts",
  "Global Colors": "global-colors",
  Features: "features",
  "Web Config": "web-config",
  "CMS Config": "cms-config",
  "Marketing Config": "marketing-config",
};

export const deviceConfigLabels = {
  "Kiosk Colors": "colors",
  "Kiosk Fonts": "fonts",
  Features: "features",
  "Global Colors": "global-colors",
  "Web Config": "web-config",
  "CMS Config": "cms-config",
  "Marketing Config": "marketing-config",
};

export const displayLabels = {
  logged_in_sections: "User View",
  logged_out_sections: "Guest View",
};
