import { SDK } from "services/koala.service";

// Config
import API from "constants/api_env";
import httpService from "services/generic.service";
import { AccessToken } from "storage/token";

/**
 * Fetch Persistent Templates
 */
export function fetchPersistentTemplates(magicBoxParams) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${
      API.routes.persistentTemplates
    }?${magicBoxParams.toQuery()}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Fetch Persistent Template
 */
export function fetchPersistentTemplate(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.legacy_url}${API.routes.persistentTemplates}/${id}?include[]=module`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Fetch Persistent Template
 */
export function fetchPersistentTemplateByAlias(orgId: number, alias: string) {
  return SDK.marketing.persistentTemplate.getByAlias(orgId, alias);
}

/**
 * Update Persistent Template
 */
export function updatePersistentTemplate(payload, id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.legacy_url}${API.routes.persistentTemplates}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      ...payload,
    },
  });
}

/**
 * create Persistent Template
 */
export function createPersistentTemplate(payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.legacy_url}${API.routes.persistentTemplates}/`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: {
      ...payload,
    },
  });
}
