import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

// Services
import { fetchLocationStats } from "services/locationStats.service";

// Actions
import actions from "./actions";

export function* fetchLocationStatsSaga(action) {
  try {
    const { start, end } = action.payload;
    const response = yield call(
      fetchLocationStats,
      start,
      end,
      action.organizationId
    );
    yield put(actions.locationStatsSuccess(response.data.data));
  } catch (error) {
    yield call(message.error, "There was an issue fetching daily orders");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_LOCATION_STATS, fetchLocationStatsSaga);
}
