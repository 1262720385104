import { ILocations } from "types/locations";
import { MagicBoxParams } from "utils/magicBox";
import actions from "./actions";

const initialState: ILocations = {
  loading: false,
  list: [],
  detail: null,
  meta: {},
  magicBox: new MagicBoxParams(),
  attributes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_LOCATION:
    case actions.FETCH_ACTIVE_DEVICES:
    case actions.FETCH_LOCATIONS:
    case actions.FETCH_LOCATION_ATTRIBUTES:
    case actions.POST_LOCATION_ATTRIBUTE:
    case actions.UPDATE_LOCATION_ATTRIBUTE:
    case actions.DELETE_LOCATION_ATTRIBUTE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        loading: false,
        meta: action.meta,
        magicBox: action.magicBox || new MagicBoxParams(),
      });
    case actions.CLEAR_LOCATIONS:
      return Object.assign({}, state, {
        list: [],
        loading: false,
      });
    case actions.SET_LOCATION_ATTRIBUTES:
      return Object.assign({}, state, {
        loading: false,
        attributes: action.attributes,
      });
    case actions.LOCATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
      });
    case actions.LOCATIONS_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.ACTIVE_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        activeDevices: action.activeDevices,
        magicBox: action.magicBox || new MagicBoxParams(),
      });
    case actions.CLEAR_LOCATION:
      return Object.assign({}, state, {
        loading: false,
        detail: null,
      });
    case actions.CLEAR_ACTIVE_DEVICES:
      return Object.assign({}, state, {
        loading: false,
        activeDevices: [],
      });
    default:
      return state;
  }
}
