import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Text from "antd/lib/typography/Text";
import { useMutation, useQuery } from "urql";
import { notification } from "antd";
import { Field } from "components/web-pages/fields";
import meActions from "redux/me/actions";
import {
  Section,
  SectionContext,
  Sections,
} from "components/web-pages/edit/section";
import { EditorHeader } from "components/web-pages/edit/header/header";
import { contentGetParsedError, contentGetOrderingAppUrl } from "utils/content";
import { ModulesList } from "components/web-pages/modules/list";
import { Modules } from "components/web-pages/modules/types";
import {
  PageQueryDocument,
  PageUpdateInput,
  PageUpdateDocument,
  PageDeleteDocument,
  StagedPageCreateDocument,
  StagedPageUpdateDocument,
  PageDuplicateDocument,
} from "types/content.gql";
import { ROUTES } from "constants/routes";
import { PageStatus } from "components/web-pages/types/page";
import { getIcon } from "components/uielements/icons";
import { useDebouncedListener } from "utils/useDebouncedListener";
import { Preview } from "./preview";
import { useConfig } from "hooks/use-config";
import configActions from "redux/configurations/actions";

interface Params {
  id: string;
}

export const WebPageEditor = () => {
  const { config } = useConfig("cms-config");
  const history = useHistory();

  let { id } = useParams<Params>();

  const [{ data, fetching }] = useQuery({
    query: PageQueryDocument,
    variables: { id },
  });

  const [, createStagedPage] = useMutation(StagedPageCreateDocument);
  const [, updateStagedPage] = useMutation(StagedPageUpdateDocument);
  const [, duplicatePage] = useMutation(PageDuplicateDocument);
  const [, updatePage] = useMutation(PageUpdateDocument);
  const [, deletePage] = useMutation(PageDeleteDocument);

  const [isUpdating, setIsUpdating] = useState(false);
  const [origin, setOrigin] = useState<string | null>(null);
  const [content, setContent] = useState<PageUpdateInput>({});
  const [stagedId, setStagedId] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [activeModule, setActiveModule] = useState<string>();
  const [suggestedModule, setSuggestedModule] = useState<string>();

  const slug = useSelector(
    (state: any) => state.app.orgConfig.data?.organization?.slug
  );

  const dispatch = useDispatch();
  const organization_id = useSelector(
    (state: any) => state?.app?.me?.detail?.organization_id ?? 0
  );

  // The global colors list needs to be instantiated when editing a page
  useEffect(() => {
    dispatch(configActions.fetchConfig("global-colors", organization_id));
    dispatch({ type: meActions.FETCH_ME });
  }, [dispatch, organization_id]);

  const getCurrentValue = (key: string) => {
    if (data?.page && key in data.page) {
      return data.page[key];
    }

    return null;
  };

  const getValue = (key: string) => {
    if (content && key in content) {
      return content[key];
    } else {
      return getCurrentValue(key);
    }
  };

  const updateValue = (key: string, value: unknown) => {
    setContent({
      ...content,
      [key]: value,
    });
  };

  async function setupStagedPage(
    title: string | null | undefined,
    content: any
  ) {
    const { data, error } = await createStagedPage({
      data: {
        title: title,
        content: content,
      },
    });

    if (error) {
      console.warn(error);
      return;
    }

    if (data?.createStagedPage?.id) {
      setStagedId(data?.createStagedPage?.id);
    }
  }

  useEffect(
    function () {
      if (slug) {
        setOrigin(contentGetOrderingAppUrl(slug));
      }
    },
    [slug]
  );

  // staged page setup
  useEffect(
    function () {
      // if we have fetched our page details
      if (!fetching && data && !stagedId) {
        setupStagedPage(data.page?.title, data.page?.content);
      }
    },
    [data, fetching]
  );

  const { listener: requestRefresh } = useDebouncedListener(async function (
    id,
    content
  ) {
    await updateStagedPage({
      id,
      data: {
        content,
      },
    });

    setLastUpdated(new Date());
    setShouldRefresh(true);
  },
  500);

  useEffect(
    function () {
      if (stagedId) {
        requestRefresh(stagedId, getValue("content"));
      }
    },
    [data, content]
  );

  if (!Boolean(config?.features?.commerce_plus)) {
    return null;
  }

  return (
    <Wrapper>
      <Sidebar>
        <EditorHeader
          title={getCurrentValue("title")}
          lastUpdated={lastUpdated}
          hasChanges={Boolean(Object.keys(content).length)}
          status={getCurrentValue("status")}
          isUpdating={isUpdating}
          onUpdate={async (status = PageStatus.published) => {
            setIsUpdating(true);

            const { error } = await updatePage({
              id,
              data: {
                ...content,
                status,
              },
            });

            setIsUpdating(false);

            if (error) {
              notification.error({
                message: contentGetParsedError(error.graphQLErrors),
              });

              return;
            } else {
              notification.success({
                message: "Page updated successfully!",
                duration: 2,
              });

              setContent({});
            }
          }}
          onDuplicate={async () => {
            const { error } = await duplicatePage({
              data: {
                title: `${getCurrentValue("title")} (copy)`,
                slug: `${getCurrentValue("slug")}-copy`,
                content: getCurrentValue("content"),
                excludeFromSitemap: getCurrentValue("excludeFromSitemap"),
                status: PageStatus.draft,
                seoTitle: getCurrentValue("seoTitle"),
                seoDescription: getCurrentValue("seoDescription"),
                seoImage: getCurrentValue("seoImage"),
              },
            });

            if (error) {
              notification.error({
                message: contentGetParsedError(error.graphQLErrors),
              });

              return;
            } else {
              notification.success({
                message: "Page duplicated successfully!",
                duration: 2,
              });

              history.push(ROUTES.WEB_ORDERING.PAGES);
            }
          }}
          onDelete={async () => {
            const { error } = await deletePage({ id });

            if (error) {
              notification.error({
                message: contentGetParsedError(error.graphQLErrors),
              });
            } else {
              notification.success({
                message: "Page deleted successfully!",
                duration: 2,
              });

              history.push(ROUTES.WEB_ORDERING.PAGES);
            }
          }}
        />

        <Main>
          <SectionContext.Provider
            value={{
              shouldOpen: activeModule,
              shouldHighlight: suggestedModule,
              setShouldHighlight: function (id) {
                setSuggestedModule(id);
              },
              resetOpened: function () {
                setActiveModule(undefined);
              },
            }}
          >
            <Sections>
              <Section
                label="Details"
                icon={getIcon("file")}
                iconColor="#5D6E86"
              >
                <Field.List>
                  <Field.Container label="Title">
                    <Field.Text
                      value={getValue("title")}
                      onChange={(value) => void updateValue("title", value)}
                    />
                  </Field.Container>

                  <Field.Container label="Slug">
                    <Field.Text
                      value={getValue("slug")}
                      onChange={(value) => void updateValue("slug", value)}
                    />
                  </Field.Container>

                  <Field.Container label="Homepage">
                    <Field.Checkbox
                      label="Set as homepage"
                      value={getValue("homepage")}
                      onChange={(value) => void updateValue("homepage", value)}
                    />
                  </Field.Container>
                </Field.List>
              </Section>

              <Section label="Seo" icon={getIcon("search")} iconColor="#5D6E86">
                <Field.List>
                  <Field.Container>
                    <Field.Checkbox
                      label="Exclude from sitemap"
                      value={getValue("excludeFromSitemap")}
                      onChange={(value) =>
                        void updateValue("excludeFromSitemap", value)
                      }
                    />
                  </Field.Container>

                  <Field.Container label="SEO Title">
                    <Field.Text
                      value={getValue("seoTitle")}
                      onChange={(value) => void updateValue("seoTitle", value)}
                    />
                  </Field.Container>

                  <Field.Container label="Seo Description">
                    <Field.Textarea
                      value={getValue("seoDescription")}
                      onChange={(value) =>
                        void updateValue("seoDescription", value)
                      }
                    />
                  </Field.Container>

                  <Field.Container label="SEO Image">
                    <Field.Image
                      onRemove={() => void updateValue("seoImage", null)}
                      value={{
                        uuid: "0",
                        url: getValue("seoImage") ?? "",
                        label: "",
                        type: "",
                      }}
                      onChange={(value) =>
                        void updateValue("seoImage", value.url)
                      }
                    />
                  </Field.Container>
                </Field.List>
              </Section>

              <ModulesListHeader type="secondary">Content</ModulesListHeader>

              <ModulesList
                data={getValue("content")}
                onChange={(value: Modules) => {
                  updateValue("content", value);
                }}
                onSort={(order) => {
                  updateValue("content", {
                    ...getValue("content"),
                    order,
                  });
                }}
              />
            </Sections>
          </SectionContext.Provider>
        </Main>
      </Sidebar>

      {origin && (
        <Preview
          id={stagedId}
          shouldRefresh={shouldRefresh}
          confirmRefresh={() => setShouldRefresh(false)}
          origin={origin}
          highlighted={suggestedModule}
          onShow={(id) => {
            setActiveModule(id);
          }}
          onHighlight={(id) => {
            setSuggestedModule(id);
          }}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 17.5rem 1fr;
  height: 100vh;
  width: 100vw;
`;

const Sidebar = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
`;

const Main = styled.div`
  padding: 0 1rem 2rem;
`;

const ModulesListHeader = styled(Text)`
  align-items: center;
  border-bottom: 1px solid var(--color--black-900);
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 3rem;
  letter-spacing: 0.0125em;
  line-height: 1;
  margin: 0 -1rem 0 0;
`;
