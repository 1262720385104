import { AccessToken } from "storage/token";
import { SDK } from "services/koala.service";
import { FixMeAny } from "types/fix-me-any";
import { ROUTES } from "constants/routes";

import { sentryUser } from "helpers/SentryHelpers";
import { doLogin } from "services/auth.service";
import { DecorateUserAuthenticationWithCaptcha } from "../../services/decorators/decorateUserAuthenticationWithCaptcha";

interface AuthResponse {
  ok: boolean;
  error?: string;
}
interface LoginPayload {
  username: string;
  password: string;
}

export async function login(payload: LoginPayload): Promise<AuthResponse> {
  try {
    const token = await doLogin(payload);
    AccessToken.set(token);

    // Set user in sentry
    sentryUser({
      id: payload.username,
      username: payload.username,
    });

    return {
      ok: true,
    };
  } catch (error: FixMeAny) {
    return {
      ok: false,
      error:
        error?.status === 429
          ? "Too many attempts, try again later"
          : "Invalid login credentials",
    };
  }
}

export async function forgotPassword(username: string): Promise<AuthResponse> {
  try {
    await new DecorateUserAuthenticationWithCaptcha(SDK.auth).forgotCmsPassword(
      username,
      `${window.location.origin}${ROUTES.AUTH.PASSWORD_RESET}`
    );

    return {
      ok: true,
    };
  } catch (error: FixMeAny) {
    return {
      ok: false,
      error: "Contact your administrator to reset your password",
    };
  }
}

interface ResetPasswordPayload {
  username: string;
  password: string;
  token: string;
}

export async function resetPassword({
  username,
  password,
  token,
}: ResetPasswordPayload): Promise<AuthResponse> {
  try {
    AccessToken.destroy();
    await new DecorateUserAuthenticationWithCaptcha(SDK.auth).resetCmsPassword(
      username,
      password,
      token
    );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      error: "Failed to reset password.",
    };
  }
}
