import React from "react";
import { Modules } from "../types";
import { Item } from "./item";
import { Sortable } from "utils/useSortable";

interface Props {
  data: Modules;
  onChange: (value: Modules) => void;
  onSort: (value: string[]) => void;
  level: number;
}

export function Items({ data, onChange, onSort, level }: Props) {
  return (
    <Sortable items={data.order} onSort={(items) => onSort(items)}>
      {data.order.map(function (id: string) {
        return (
          <Item
            key={id}
            id={id}
            data={data.modules[id]}
            level={level}
            canSort={Boolean(data.order.length > 1)}
            onRemove={function () {
              const { [id]: removedModule, ...filteredModules } = data.modules;

              onChange({
                ...data,
                order: data.order.filter((item) => item !== id),
                modules: filteredModules,
              });
            }}
            onChange={function (value) {
              onChange({
                ...data,
                modules: {
                  ...data.modules,
                  [id]: value,
                },
              });
            }}
          />
        );
      })}
    </Sortable>
  );
}
