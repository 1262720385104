import React from "react";
import styled from "styled-components";
import { ArrowRightOutlined, ArrowDownOutlined } from "@ant-design/icons";

export enum direction {
  "horizontal" = "horizontal",
  "vertical" = "vertical",
}

interface Props {
  onChange: (value: direction) => void;
  value: direction;
}

export function Direction({ onChange, value }: Props) {
  return (
    <Container>
      <Button
        onClick={() => onChange(direction.vertical)}
        active={value === direction.vertical}
      >
        <ArrowDownOutlined title="Vertical" />
      </Button>

      <Button
        onClick={() => onChange(direction.horizontal)}
        active={value === direction.horizontal}
      >
        <ArrowRightOutlined title="Horizontal" />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid var(--color--black-800);
  border-radius: 0.375rem;
  display: grid;
  height: 2rem;
  grid-template-columns: 1fr 1fr;
  padding: 1px;
  width: 4rem;
`;

const Button = styled.div`
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: var(--color--blue-400);
  }

  ${({ active }) => `
    ${
      active &&
      `
      background-color: var(--color--blue-400);
      color: white;

      &:hover {
        color: white;
      }
    `
    }
  `}
`;
