import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import authActions from "redux/auth/actions";

export function SignOut() {
  const dispatch = useDispatch();

  useEffect(function resetUser() {
    dispatch(authActions.logout());
  }, []);

  return <Redirect to="/login" />;
}
