import React from "react";
import { Input } from "antd";

interface Props {
  placeholder?: string;
  onChange: (value: string) => void;
  id?: string;
  value?: string;
}

export const Text = (props: Props) => {
  return <Input {...props} onChange={(e) => props.onChange(e.target.value)} />;
};
