import { ENV } from "constants/app_env";

export interface IEnv {
  SQUARE_CLIENT_ID: string;
  RECAPTCHA_SITE_KEY: string;
}

export type IEnvConfig = {
  [key in "dev" | "staging" | "sandbox" | "production"]: IEnv;
};

const ENV_CONFIG: IEnvConfig = {
  dev: {
    SQUARE_CLIENT_ID: "sq0idp-23UAM83ifdbLLzvnL6udhg",
    RECAPTCHA_SITE_KEY: process.env?.REACT_APP_RECAPTCHA_SITE_KEY || "",
  },
  staging: {
    SQUARE_CLIENT_ID: "sq0idp-23UAM83ifdbLLzvnL6udhg",
    RECAPTCHA_SITE_KEY: process.env?.REACT_APP_RECAPTCHA_SITE_KEY || "",
  },
  sandbox: {
    SQUARE_CLIENT_ID: "sq0idp-23UAM83ifdbLLzvnL6udhg",
    RECAPTCHA_SITE_KEY: process.env?.REACT_APP_RECAPTCHA_SITE_KEY || "",
  },
  production: {
    SQUARE_CLIENT_ID: "sq0idp-tUfcYhMjVXzCHh01pe35Yw",
    RECAPTCHA_SITE_KEY: process.env?.REACT_APP_RECAPTCHA_SITE_KEY || "",
  },
};

export default ENV_CONFIG[ENV];
