import React, { useState } from "react";
import { useHistory } from "react-router";
import { Container } from "./container";
import { notification } from "antd";
import { Formik, Form as FormikForm, useField } from "formik";
import { Form, Stack } from "components/ui";
import { forgotPassword } from "./service";
import { ROUTES } from "constants/routes";
import { Button } from "components/ui/button";

function Fields() {
  const [username] = useField({
    name: "username",
  });
  return (
    <>
      <Form.Input {...username} label="Email address" type="email" required />
    </>
  );
}

export function ForgotPassword() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  return (
    <Container
      title="Forgot password"
      description="Enter the email address your account is associated with below. An email will be sent containing a link to reset your password."
    >
      <Formik
        initialValues={{
          username: "",
        }}
        onSubmit={async ({ username }) => {
          if (loading) return;

          setLoading(true);

          const response = await forgotPassword(username);

          if (response.ok) {
            notification.success({
              message:
                "Please check your email for instructions to reset your password",
              duration: 2,
            });

            history.push(ROUTES.AUTH.LOGIN);
          } else {
            setError(response.error);
          }

          setLoading(false);
        }}
      >
        <FormikForm>
          <Stack direction="vertical" gap="$3">
            <Fields />

            <Button disabled={loading} type="submit" css={{ width: "100%" }}>
              Reset Password
            </Button>

            <Button
              variant="subtle"
              onClick={() => {
                history.push(ROUTES.AUTH.LOGIN);
              }}
            >
              Cancel
            </Button>

            {error && (
              <Stack
                position="center"
                css={{
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                  borderRadius: "$md",
                  color: "#750808",
                  fontSize: "$text300",
                  height: "$16",
                  lineHeight: "$tight",
                  marginTop: "$4",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {error}
              </Stack>
            )}
          </Stack>
        </FormikForm>
      </Formik>
    </Container>
  );
}
