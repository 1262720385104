import actions from "./actions";
import { TokenConfig } from "@chowly/koala-sdk-js";

const initialState = {
  loading: false,
  id: null,
  data: {},
};

export default function (
  state: {} = initialState,
  action: { type: string; loading: boolean; data: TokenConfig }
) {
  switch (action.type) {
    case actions.FETCH_ORG_CONFIG:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.ORG_CONFIG_SUCCESS:
      return {
        loading: false,
        data: action.data,
      };
    case actions.ORG_CONFIG_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_ORG_CONFIG:
      return Object.assign({}, state, {
        loading: false,
        data: null,
      });
    default:
      return state;
  }
}
