import axios from "axios";
import get from "lodash/get";
import Raven from "raven-js";
import BRAND_ENV from "constants/app_env";

function setTags() {
  Raven.setTagsContext({
    environment: BRAND_ENV.REACT_APP_ENV,
  });
}

// Requests a URL, returning a promise
export default function genericHttpService(options) {
  return axios(options).catch((error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log("error.response.data", error.response.data);
      console.log("error.response.data", error.response.status);
      console.log("error.response.headers", error.response.headers);

      const errorMessage = get(
        error,
        "response.data.error_descriptionsdfsdf",
        `Error Type: ${error.response.status} in METHOD: ${options.method} URL: ${options.url}`
      );
      setTags();
      Raven.captureMessage(errorMessage, {
        level: "info",
        tags: {
          errorCode: error.response.status,
        },
        extra: {
          code: error.response.status,
          error: error.response.headers,
          data: error.response.data,
          method: options.method,
          url: options.url,
        },
      });
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log("error.request", error.request);
      setTags();
      Raven.captureException(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error in request settings", error.message);
      setTags();
      Raven.captureException(error);
    }
    console.log("error.config", error.config);
    throw error;
  });
}
