import { put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import actions from "./actions";
import { doLogout, getAuthScopes } from "services/auth.service";
import configActions from "redux/configurations/actions";

function* doLogoutSaga() {
  // Destroy token
  try {
    yield call(doLogout);
    yield put(configActions.clearAllConfigs());
  } catch (error) {
    console.error(error);
  }

  // Push to login
  yield put(push("/login"));
}

function* getAuthScopesSaga() {
  try {
    const response = yield call(getAuthScopes);
    yield put({
      type: actions.AUTH_SCOPES_SUCCESS,
      scopes: response.data.data,
    });
  } catch (error) {}
}

// export root
export default function* rootSaga() {
  yield takeLatest(actions.LOGOUT, doLogoutSaga);
  yield takeLatest(actions.GET_AUTH_SCOPES, getAuthScopesSaga);
}
