import { SDK } from "services/koala.service";
import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";
import { AdminUser, ListResponse } from "@chowly/koala-sdk-js";

/**
 * Fetch users
 */
export function fetchUsers(magicBox): Promise<ListResponse<AdminUser>> {
  return SDK.admin.users.list({ querystring: magicBox.toQuery() });
}

/**
 * Fetch user
 */
export function fetchUser(id) {
  return SDK.admin.users.detail(id);
}

/**
 * Create user
 */
export function createUser(payload) {
  return SDK.admin.users.create(payload);
}

/**
 * Update user
 */
export function updateUser(payload) {
  return SDK.admin.users.update(payload);
}

/**
 * Delete internal user
 */
export function deleteUser(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.users}/internal/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Fetch user group scopes
 */
export function fetchUserGroupScopes(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.users}/${id}/oauth_scopes/groups`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
    },
  });
}

/**
 * Update user group scopes
 */
export function updateUserGroupScopes(payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PATCH",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.users}/${payload.id}/oauth_scopes/groups`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}
