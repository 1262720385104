const organizationActions = {
  FETCH_ORGANIZATION: "/koala-cms/organization/FETCH_ORGANIZATION",
  ORGANIZATION_SUCCESS: "/koala-cms/organization/ORGANIZATION_SUCCESS",
  UPDATE_ORGANIZATION: "/koala-cms/organization/UPDATE_ORGANIZATION",
  CLEAR_ORGANIZATION: "/koala-cms/organization/CLEAR_ORGANIZATION",

  fetchOrganization: (id: number) => ({
    type: organizationActions.FETCH_ORGANIZATION,
    id,
  }),
  updateOrganization: (payload: object, id: number) => ({
    type: organizationActions.UPDATE_ORGANIZATION,
    payload,
    id,
  }),
};

export default organizationActions;
