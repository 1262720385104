import React from "react";
import type * as Stitches from "@stitches/react";
import { styled } from "../stitches";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  children: React.ReactNode;
  css?: Stitches.CSS;
}

export function Box({ children, css = {}, ...props }: Props) {
  return (
    <Container css={css} {...props}>
      {children}
    </Container>
  );
}

const Container = styled("div", {});
