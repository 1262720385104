// Utils
import { MagicBoxParams } from "utils/magicBox";

// Actions
import actions from "./actions";

const initialState = {
  list: [],
  detail: null,
  loading: false,
  magicBox: new MagicBoxParams(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_REVISIONS:
    case actions.FETCH_REVISION:
    case actions.POST_REVISION:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.REVISIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        list: action.list,
        magicBox: action.magicBox,
      });
    case actions.REVISION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        detail: action.detail,
      });
    case actions.REVISION_FAIL:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_REVISION:
      return Object.assign({}, state, {
        detail: initialState.detail,
      });
    default:
      return state;
  }
}
