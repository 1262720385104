import {
  InboxOutlined,
  PaperClipOutlined,
  FileOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Button, Col, Popover, Row, Upload } from "antd";
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import Input from "components/uielements/input";
import ModuleLabel from "components/uielements/moduleLabel";
import { StyledFileName, StyledUploader } from "./styles";
import { assetValidation } from "./validation";
import { getAssetType } from "utils/assets";
import { ASSET_TYPES, ASSET_MIME_TYPES } from "constants/resources/assets";
import { Error } from "components/uielements/forms/error";

interface Props {
  change: (file: string, fileObj: object) => void;
  handleSubmit: any;
  onSubmit: any;
  submitText: string;
  formValues: any;
  deleteAsset?: () => void;
  selectFilesOnly?: object;
}

class AssetForm extends React.Component<Props> {
  handleDrop = ({ file, fileList }: any) => {
    // TODO - validate that there is a file AND only one file in the file list before submission
    const fileObj = file.originFileObj;

    // Update the file in redux form
    this.props.change("file", fileObj);
  };

  joinAcceptedFileTypes = () => Object.keys(ASSET_MIME_TYPES).join(", ");

  renderPreview = () => {
    const { formValues } = this.props;
    const filetype = getAssetType(formValues.mime_type);
    let icon = <FileOutlined />;

    switch (filetype) {
      case ASSET_TYPES.IMAGE:
        icon = <FileJpgOutlined />;
        break;
      case ASSET_TYPES.PDF:
        icon = <FilePdfOutlined />;
        break;
      default:
        break;
    }

    return (
      <Button
        icon={icon}
        size="large"
        onClick={() => window.open(formValues.url, "_blank")}
        style={{ marginBottom: 20 }}
      >
        Preview Asset
      </Button>
    );
  };

  renderUpload = ({ meta, input }) => {
    return (
      <StyledUploader style={{ marginBottom: "24px", position: "relative" }}>
        <Upload.Dragger
          name="file"
          multiple={false}
          onChange={this.handleDrop}
          accept={this.joinAcceptedFileTypes()}
          customRequest={({ file, onSuccess }) => {
            // @ts-expect-error Cannot invoke an object which is possibly 'undefined'.
            onSuccess({}, file);
          }}
        >
          <div style={{ padding: 10 }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag a file to this area to upload
            </p>
            {this.props.selectFilesOnly ? (
              <p className="ant-upload-hint">Supported files: .pdf</p>
            ) : (
              <p className="ant-upload-hint">
                Supported files: .jpg, .gif, .svg, .ico, .png, .pdf
              </p>
            )}
          </div>
        </Upload.Dragger>

        {meta.touched && meta.error && (
          <Error
            style={{
              fontSize: 14,
              paddingLeft: 0,
            }}
          >
            {meta.touched && meta.error}
          </Error>
        )}

        {input?.value?.name && (
          <StyledFileName>
            <Popover content="Correct file? If not, drag another file into the uploader.">
              <PaperClipOutlined />
            </Popover>
            <p style={{ marginLeft: 5 }}>{input.value.name}</p>
          </StyledFileName>
        )}
      </StyledUploader>
    );
  };

  render() {
    const {
      onSubmit,
      handleSubmit,
      formValues = {},
      deleteAsset,
      submitText,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModuleLabel />

        <Field
          addonBefore="Alt Text"
          size="large"
          name="alt_text"
          placeholder="Description of visual asset"
          component={Input}
          type="text"
        />

        {formValues.url ? (
          this.renderPreview()
        ) : (
          <Field
            addonBefore="File"
            size="large"
            name="file"
            placeholder="Description of visual asset"
            component={this.renderUpload}
            type="file"
          />
        )}

        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Button htmlType="submit" size="large" type="primary">
              {submitText}
            </Button>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: "right" }}>
            {deleteAsset && (
              <Button onClick={deleteAsset} size="large" type="primary" danger>
                Delete Asset
              </Button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default compose<any>(
  connect((state) => ({
    formValues: getFormValues("asset-form")(state),
  })),
  reduxForm({
    form: "asset-form",
    validate: assetValidation,
  })
)(AssetForm);
