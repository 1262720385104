import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import { ROUTES } from "constants/routes";
import { useHistory } from "react-router";
import { PageStatus } from "components/web-pages/types/page";
import moment from "moment";
import { PageOptions } from "./page-options";

export function getLastUpdated(date: Date = new Date()) {
  const lastUpdate = moment(date);
  const now = moment();

  return `Preview updated ${lastUpdate.from(now)}`;
}

interface Props {
  title?: string;
  lastUpdated: Date;
  status: PageStatus;
  hasChanges: boolean;
  onUpdate: (status: PageStatus) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  isUpdating?: boolean;
}

export function EditorHeader({
  title = "Edit page",
  lastUpdated,
  status = PageStatus.draft,
  hasChanges = false,
  onUpdate,
  onDuplicate,
  onDelete,
  isUpdating = false,
}: Props) {
  const history = useHistory();
  const [subtitle, setSubtitle] = useState("Preview updated just now");

  useEffect(() => {
    setSubtitle(getLastUpdated(lastUpdated));

    const interval = setInterval(() => {
      setSubtitle(getLastUpdated(lastUpdated));
    }, 60000); // update the preview last update time every 1 minute

    return () => void clearInterval(interval);
  }, [lastUpdated]);

  function getButtonText() {
    if (isUpdating) {
      return "Saving...";
    }

    if (status === PageStatus.draft) {
      return "Publish page";
    }

    return "Update page";
  }

  return (
    <Container>
      <Controls>
        <Exit
          onClick={() => {
            if (!hasChanges) {
              history.push(ROUTES.WEB_ORDERING.PAGES);
            } else {
              if (
                window.confirm(
                  "Are you sure you want to exit? Your unsaved changes will be lost"
                )
              ) {
                history.push(ROUTES.WEB_ORDERING.PAGES);
              }
            }
          }}
        >
          <ArrowLeftOutlined
            style={{
              fontSize: ".75rem",
            }}
          />
          Exit
        </Exit>

        <Dropdown.Button
          type="primary"
          trigger={["click"]}
          placement="bottomRight"
          onClick={() => onUpdate(PageStatus.published)}
          disabled={isUpdating}
          overlay={
            <PageOptions
              onUpdate={onUpdate}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
            />
          }
        >
          {getButtonText()}
        </Dropdown.Button>
      </Controls>

      <div>
        <Header>
          <Title
            level={1}
            style={{
              fontSize: "1.125rem",
              fontWeight: 600,
              margin: 0,
            }}
          >
            {title}
          </Title>
        </Header>

        {subtitle && (
          <Text type="secondary" style={{ fontSize: ".75rem" }}>
            {subtitle}
          </Text>
        )}
      </div>
    </Container>
  );
}

const Container = styled.header`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem;
  position: relative;
  z-index: 2;
`;

const Controls = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--color--black-900);
  display: flex;
  margin: 0 -1rem;
  padding: 1rem;
  justify-content: space-between;
`;

const Exit = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 0.8125rem;
  display: flex;
  gap: 0.5rem;
  line-height: 1;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
