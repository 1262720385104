import { message } from "antd";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

// Services
import {
  deleteAllergen,
  fetchAllergens,
  postAllergen,
  updateAllergen,
} from "services/allergens.service";

// Actions
import actions from "./actions";

export function* fetchAllergensSaga() {
  try {
    // @ts-expect-error Argument of type '(pageNumber: any) => Promise<AxiosResponse<any>>' is not assignable to parameter of type 'CallEffectNamedFn<{ [x: string]: Func0; }, string>'
    const response = yield call(fetchAllergens);
    yield put({
      type: actions.FETCH_ALLERGENS_SUCCESS,
      data: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ALLERGENS_FAIL });
    yield call(message.error, "There was an error fetching allergens.");
  }
}

export function* postAllergenSaga(action: AnyAction) {
  try {
    yield call(postAllergen, action.payload);
    yield put({ type: actions.FETCH_ALLERGENS });
    yield put({ type: actions.POST_ALLERGEN_SUCCESS });
    yield call(message.success, "Dietary preference successfully posted");
  } catch (error) {
    yield put({ type: actions.POST_ALLERGEN_FAIL });
    yield call(
      message.error,
      "There was an error posting a new dietary preference."
    );
  }
}

export function* deleteAllergenSaga(action: AnyAction) {
  try {
    yield call(deleteAllergen, action.id);
    yield put({ type: actions.DELETE_ALLERGEN_SUCCESS });
    yield put({ type: actions.FETCH_ALLERGENS });
    yield call(message.success, "Dietary preference successfully deleted");
  } catch (error) {
    yield put({ type: actions.DELETE_ALLERGEN_FAIL });
    yield call(
      message.error,
      "There was an error deleting the dietary preference."
    );
  }
}

export function* updateAllergenSaga(action: AnyAction) {
  try {
    yield call(updateAllergen, action.id, action.payload);
    yield put({ type: actions.UPDATE_ALLERGEN_SUCCESS });
    yield put({ type: actions.FETCH_ALLERGENS });
    yield call(message.success, "Dietary preference successfully updated");
  } catch (error) {
    yield put({ type: actions.UPDATE_ALLERGEN_FAIL });
    yield call(
      message.error,
      "There was an error updating the dietary preference."
    );
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ALLERGENS, fetchAllergensSaga);
  yield takeLatest(actions.POST_ALLERGEN, postAllergenSaga);
  yield takeLatest(actions.DELETE_ALLERGEN, deleteAllergenSaga);
  yield takeLatest(actions.UPDATE_ALLERGEN, updateAllergenSaga);
}
