import { AnyAction } from "redux";
import { MagicBoxParams } from "utils/magicBox";
import {
  resourceDefaults as rd,
  resourceKeys as rk,
} from "constants/resources/keys";
import actions from "./actions";
import { IMeta, IProductModifier } from "types/app";

export interface GlobalProductModifiersState {
  loading: boolean;
  list: IProductModifier[];
  detail: IProductModifier | null;
  meta: IMeta | {};
  magicBox: MagicBoxParams;
  allergies: Array<{}>;
}

const initialState: GlobalProductModifiersState = {
  loading: false,
  list: [],
  detail: null,
  meta: {},
  magicBox: new MagicBoxParams().setIncludes(
    rd[rk.globalProductModifiers].includes
  ),
  allergies: [],
};

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case actions.FETCH_GLOBAL_PRODUCT_MODIFIERS:
    case actions.FETCH_GLOBAL_PRODUCT_MODIFIER:
    case actions.UPDATE_GLOBAL_PRODUCT_MODIFIERS:
    case actions.UPDATE_GLOBAL_PRODUCT_MODIFIER_IMAGE:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.STOP_LOADING_STATE:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.GLOBAL_PRODUCT_MODIFIER_SUCCESS:
      return Object.assign({}, state, {
        detail: action.detail,
        loading: false,
      });
    case actions.GLOBAL_PRODUCT_MODIFIERS_SUCCESS:
      return Object.assign({}, state, {
        list: action.data.data,
        meta: action.data.meta,
        magicBox: action.magicBox,
        loading: false,
      });
    case actions.ALLERGIES_SUCCESS:
      return Object.assign({}, state, {
        allergies: action.data.data,
      });
    case actions.CLEAR_GLOBAL_PRODUCT_MODIFIER_DETAIL:
      return Object.assign({}, state, {
        detail: null,
      });
    case actions.ATTEMPT_GLOBAL_PRODUCT_MODIFIER_LIST_MODIFICATION: {
      return Object.assign({}, state, {
        loading: false,
        detail:
          !!state.detail && state.detail!["id"] === action.id
            ? Object.assign({}, state.detail, {
                [action.key]: action.value,
              })
            : state.detail,
        list: state.list.map((item: { id?: number }) => {
          if (item.id === action.id) {
            return Object.assign({}, item, {
              [action.key]: action.value,
            });
          }

          return item;
        }),
      });
    }
    default:
      return state;
  }
}
