// Constants
import { deviceConfigLabels } from "constants/deviceConfigAliases";

// Actions
import actions from "./actions";

const initialState = {
  loading: false,
  data: {
    colors: {},
    fonts: {},
    features: {},
    images: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_DEVICE_CONFIG:
    case actions.UPDATE_DEVICE_CONFIG:
      return Object.assign({}, state, {
        loading: true,
        data: state.data,
      });
    case actions.FETCH_FAILED:
    case actions.UPDATE_SUCCESS:
    case actions.UPDATE_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.FETCH_DEVICE_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: Object.assign({}, state.data, {
          [deviceConfigLabels[action.detail.data.label]]: action.detail.data,
        }),
      });
    case actions.CLEAR_DEVICE_CONFIG:
      return Object.assign({}, state, {
        loading: false,
        data: {},
      });
    default:
      return state;
  }
}
