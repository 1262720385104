import { useSelector } from "react-redux";
import { DEFAULT_CONFIG } from "redux/configurations/default-cms-config";
import { RootState } from "types/app";
import { ICmsConfig } from "types/constants/config";

/**
 * Use this hook instead of useConfig("cms-config") to acquire a CMS config
 * that adheres to the interface ICmsConfig. ICmsConfig is a modified version of
 * ICmsDeviceConfig that comes back from the API.
 */
export function useCMSConfig(): ICmsConfig {
  const cmsConfig = useSelector(
    (state: RootState) => state.app.configurations?.["cms-config"]
  );

  if (!cmsConfig) {
    return DEFAULT_CONFIG;
  }

  const { platforms, features } = cmsConfig.config;
  const newCmsConfig: ICmsConfig = {
    WEB_MARKETING: platforms.web_marketing,
    GLOBAL_MENU: {
      PRODUCTS:
        platforms.kiosk || platforms.web_ordering || platforms.mobile_ios,
      MODIFIERS:
        platforms.kiosk || platforms.web_ordering || platforms.mobile_ios,
      TAGS: platforms.kiosk || platforms.web_ordering || platforms.mobile_ios,
    },
    LOCATIONS: {
      ATTRIBUTES:
        platforms.kiosk || platforms.web_ordering || platforms.web_marketing,
      KIOSK: platforms.kiosk,
    },
    GLOBAL_CONFIG: {
      BASE:
        platforms.kiosk ||
        platforms.web_ordering ||
        platforms.web_marketing ||
        platforms.mobile_ios,
    },
    ORDERING_CONFIG: {
      WEB: platforms.web_ordering,
      MOBILE_IOS: platforms.mobile_ios,
      KIOSK: platforms.kiosk,
    },
    CROSSSELL: {
      BASE:
        features.crosssell &&
        (platforms.kiosk || platforms.web_ordering || platforms.mobile_ios),
    },
    SETTINGS: {
      BASE: platforms.kiosk || platforms.web_ordering,
      INTEGRATIONS: platforms.kiosk || platforms.web_ordering,
    },
  };

  return newCmsConfig;
}
