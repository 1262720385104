const activeDevicesActions = {
  FETCH_ACTIVE_DEVICES: "/koala-cms/active-devices/FETCH_ACTIVE_DEVICES",
  ACTIVE_DEVICES_SUCCESS: "/koala-cms/active-devices/ACTIVE_DEVICES_SUCCESS",

  fetchActiveDevices: (magicBox) => ({
    type: activeDevicesActions.FETCH_ACTIVE_DEVICES,
    magicBox,
  }),

  // Success action for list fetch
  activeDevicesSuccess: (list, listMeta, magicBox) => ({
    type: activeDevicesActions.ACTIVE_DEVICES_SUCCESS,
    list,
    listMeta,
    magicBox,
  }),
};

export default activeDevicesActions;
