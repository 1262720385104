import React, { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { UrlField } from "components/web-pages/modules/types";
import { ReactEditor } from "slate-react";
import { ControlButton } from "./controls";
import { UrlPicker } from "../url/picker";
import { EditorActions } from "../richtext/actions";

interface Props {
  editor: ReactEditor;
  onChange: (value: UrlField) => void;
  onRemove: () => void;
}

export function LinkPicker({ editor, onChange, onRemove }: Props) {
  const [isVisible, setVisible] = useState(false);
  const [url, setUrl] = useState<UrlField>();

  return (
    <>
      <ControlButton
        onClick={() => {
          const value = EditorActions.getLinkValue(editor);

          setVisible(true);
          setUrl(value);
        }}
      >
        <LinkOutlined />
      </ControlButton>

      {isVisible && (
        <UrlPicker
          visible={isVisible}
          value={url}
          onChange={(details) => {
            setVisible(false);

            if (details?.reference) {
              onChange(details);
            }
          }}
          onRemove={() => {
            setVisible(false);
            onRemove();
          }}
          onCancel={() => void setVisible(false)}
        />
      )}
    </>
  );
}
