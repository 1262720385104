import React, { ReactNode, useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { AutoComplete, Input, Typography } from "antd";
import styled from "styled-components";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import assetActions from "redux/assets/actions";
import { resourceKeys as rk } from "constants/resources/keys";
import { UrlField, UrlType } from "components/web-pages/modules/types";
import { ImageAsset } from "components/assets/manager";

type AutocompleteOption = {
  children: string | ReactNode; // the title value in <AutoComplete.Option />
  key: string;
  props?: AutocompleteOption;
  value: string;
};

interface Props {
  onChange: (value: UrlField) => void;
  value: UrlField;
}

export function Attachments({ value, onChange }: Props) {
  const { label } = value;
  const { label: referenceLabel = "", url, type } = value?.reference ?? {};
  const [searchValue, setSearchValue] = useState(label);

  const { list, magicBox }: { list: ImageAsset[]; magicBox: any } = useSelector(
    function (state: RootStateOrAny) {
      return state.app[rk.assets];
    }
  );

  const dispatch = useDispatch();

  const updateSearch = useCallback(
    debounce((searchValue: string) => {
      dispatch(
        assetActions.fetchAssets(
          magicBox
            .clone()
            .addFilter("asset_type_id", 1)
            .addFilter("label", `~${searchValue}`)
        )
      );
    }, 250),
    [dispatch, magicBox]
  );

  useEffect(
    function () {
      updateSearch(searchValue);
    },
    [searchValue]
  );

  useEffect(
    function () {
      setSearchValue(referenceLabel);
    },
    [referenceLabel]
  );

  return (
    <>
      <Typography.Text strong>Search</Typography.Text>

      <AutoComplete
        onSearch={function (value) {
          setSearchValue(value);
        }}
        onSelect={function (_: string, option: AutocompleteOption) {
          const selected = list.find((item) => item.uuid === option.key) as
            | ImageAsset
            | undefined;

          if (!selected) {
            return;
          }

          onChange({
            ...value,
            reference: {
              label: selected.url,
              url: selected.url,
              type: UrlType.attachment,
              newWindow: true,
            },
          });
        }}
        placeholder="Search assets..."
        allowClear
        autoFocus
      >
        {list.map((item) => (
          <AutoComplete.Option key={item.uuid} value={item.label}>
            <AssetResult image={item.url}>
              <div className="img-preview" />
              {item.label}
            </AssetResult>
          </AutoComplete.Option>
        ))}
      </AutoComplete>

      <div>
        <Typography.Text strong>Label</Typography.Text>
        <Input
          placeholder="Label..."
          value={label}
          onChange={function (e) {
            onChange({
              ...value,
              label: e.target.value,
            });
          }}
        />
      </div>

      {type === UrlType.attachment && url !== "" && (
        <>
          <Image src={url} />
          <Typography.Text type="secondary">{url}</Typography.Text>
        </>
      )}
    </>
  );
}

const AssetResult = styled.div`
  display: flex;
  align-items: center;
  .img-preview {
    border: 1px solid #efefef;
    background-color: #efefef;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center center;
    height: 50px;
    margin-right: 10px;
    width: 50px;
  }
`;

const Image = styled.img`
  background-color: var(--color--black-900);
  object-fit: contain;
  max-height: 200px;
  width: 100%;
`;
