import styled from "styled-components";

export const Error = styled.div`
  color: red;
  position: absolute;
  bottom: 6px;
`;

export const StyledExpandedTableRow = styled.div`
  background-image: linear-gradient(
    to bottom,
    #ffffff,
    #fefefe,
    #fcfdfc,
    #fbfbfb,
    #fafafa
  );
  padding: 0 20px 20px;
  width: 100%;
`;
