import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface Props {
  onDelete: () => void;
}
export function DeleteModule({ onDelete }: Props) {
  return (
    <DeleteButton
      onClick={() => void onDelete()}
      size="small"
      type="link"
      danger={true}
    >
      <DeleteOutlined />
    </DeleteButton>
  );
}

const DeleteButton = styled(Button)`
  margin: 0 14px;
  padding: 0;
`;
