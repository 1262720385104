import axios from "axios";
import { SDK } from "services/koala.service";
import API from "constants/api_env";
import { GROUPS } from "constants/scopes";
import { ROUTES } from "constants/routes";
import { USER } from "@chowly/koala-sdk-js/dist/constants/auth";
import { AccessToken } from "storage/token";
import { DecorateUserAuthenticationWithCaptcha } from "./decorators/decorateUserAuthenticationWithCaptcha";

/**
 * Login
 */
export function doLogin({ username, password }) {
  // Workaround to add the additional scope for user login since the CMS is stuck using an old version of the SDK
  const oldScopes = USER.SCOPES;

  USER.SCOPES = [GROUPS.ADMIN, GROUPS.USER];
  const result = new DecorateUserAuthenticationWithCaptcha(
    SDK.auth
  ).userCredentials({ username, password });
  USER.SCOPES = oldScopes;

  return result;
}

/**
 * Logout
 */
export function doLogout() {
  return SDK.auth.logout();
}

export function forgotPassword(username) {
  return new DecorateUserAuthenticationWithCaptcha(SDK.auth).forgotCmsPassword(
    username,
    `${window.location.origin}${ROUTES.AUTH.PASSWORD_RESET}`
  );
}

export function getAuthScopes() {
  const { token_type, access_token } = AccessToken.get();
  return axios({
    method: "GET",
    url: `${API.scheme}${API.urlBase}${API.routes.scopes}?per_page=50`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token_type} ${access_token}`,
    },
  });
}
