const authActions = {
  LOGOUT: "/koala-cms/auth/LOGOUT",
  GET_AUTH_SCOPES: "/koala-cms/auth/GET_AUTH_SCOPES",
  AUTH_SCOPES_SUCCESS: "/koala-cms/auth/AUTH_SCOPES_SUCCESS",

  logout: () => ({
    type: authActions.LOGOUT,
  }),
  fetchAuthScopes: () => ({
    type: authActions.GET_AUTH_SCOPES,
  }),
};
export default authActions;
