export const getRows = (supports: string[]) => {
  if (!supports.includes("vertical")) {
    return "1fr";
  }

  return "repeat(3, 1fr)";
};

export const getColumns = (supports: string[]) => {
  if (!supports.includes("horizontal")) {
    return "1fr";
  }

  return "repeat(3, 1fr)";
};
