import Ajv from "ajv";
// const Ajv = require("ajv").default;
const ajv = new Ajv({ allErrors: true, strict: true });
import modulesRenderSchema from "./schema/definitions/rendered.json";
import modulesTypeSchema from "./schema/definitions/type.json";
import { ModuleType } from "./types";

export const moduleGetRenderDefaults = (moduleDetails: ModuleType) => {
  const values = {};

  Object.keys(moduleDetails.fields).forEach((key) => {
    switch (moduleDetails.fields[key].fieldType) {
      case "button":
        values[key] = {
          style: "primary",
          url: {
            label: "",
          },
        };
        break;

      case "url":
        values[key] = {
          label: "",
        };
        break;

      case "modules":
        values[key] = {
          order: [],
          modules: {},
        };
        break;

      case "richtext":
        values[key] = "";
        break;

      case "dropdown":
        values[key] =
          moduleDetails.fields[key].attributes?.defaultValue ?? null;
        break;

      case "image":
        values[key] = {};
        break;

      default:
        values[key] =
          moduleDetails.fields[key].attributes?.defaultValue ?? null;
        break;
    }
  });

  return {
    type: moduleDetails.moduleType,
    ...values,
  };
};

const moduleTypeParser = ajv.compile(modulesTypeSchema);
const moduleRenderedParser = ajv.compile(modulesRenderSchema);

export const moduleValidateRendered = (data: unknown) => {
  const valid = moduleRenderedParser(data);

  if (!valid) {
    console.warn(ajv.errors);
    return false;
  }

  return true;
};

export const moduleValidateType = (data: unknown) => {
  const valid = moduleTypeParser(data);

  if (!valid) {
    console.warn(ajv.errors);
    return false;
  }

  return true;
};
