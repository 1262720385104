import moment from "moment";

export const resourceKeys = {
  assets: "assets",
  activeDevices: "activeDevices",
  globalProducts: "globalProducts",
  globalProductModifiers: "globalProductModifiers",
  locations: "locations",
  locationAttributePositions: "locationAttributePositions",
  users: "users",
  customers: "customers",
};

export const resourceDefaults = {
  [resourceKeys.activeDevices]: {
    includes: ["store_location"],
    filters: {
      updated_at: `>=${moment(new Date()).subtract(30, "d").format()}`,
    },
    sorts: [
      {
        id: "store_location_id",
        desc: false,
      },
    ],
  },
  [resourceKeys.assets]: {
    includes: ["status", "asset_type"],
    sorts: [
      {
        id: "created_at",
        desc: true,
      },
    ],
  },
  [resourceKeys.globalProducts]: {
    includes: [
      "verifications",
      "allergens",
      "categories",
      "tags",
      "global_product_options",
    ],
    sorts: [],
  },
  [resourceKeys.globalProductModifiers]: {
    includes: ["verifications", "allergens", "global_products"],
    sorts: [],
  },
  [resourceKeys.locations]: {
    includes: [],
    sorts: [],
  },
  [resourceKeys.users]: {
    filters: { username: "NOT_NULL", external_user_id: "NULL" },
    includes: [],
    sorts: [],
  },
  [resourceKeys.customers]: {
    filters: { username: "NULL", external_user_id: "NOT_NULL" },
    includes: [],
    sorts: [],
  },
};

export const resourceAttrs = {
  magicBox: "MB",
  loading: "Loading",
  list: "List",
  meta: "Meta",
};
