import API from "constants/api_env";
import { AccessToken } from "storage/token";
import httpService from "services/generic.service";

export function fetchAllergens(pageNumber) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "GET",
    url: `${API.scheme}${API.urlBase}/${API.version}${
      API.routes.allergies
    }?per_page=50&page=${pageNumber ?? 1}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function postAllergen(payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "POST",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.allergies}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

export function deleteAllergen(id) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "DELETE",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.allergies}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function updateAllergen(id, payload) {
  const { token_type, access_token } = AccessToken.get();
  return httpService({
    method: "PUT",
    url: `${API.scheme}${API.urlBase}/${API.version}${API.routes.allergies}/${id}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
    },
    data: payload,
  });
}
