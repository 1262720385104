import { Select } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { LAYOUT } from "koala-web-shared/constants/styles";
import { IGlobalColor } from "redux/_deprecated/deviceConfiguration/types";
import { useConfig } from "hooks/use-config";

interface PassProps {
  defaultValue?: string;
  onChange: (color: string) => void;
  useRgba?: boolean;
}
const selectOptions = (options: IGlobalColor[], useRgba?: boolean) =>
  options.map((option: IGlobalColor, index: number) => {
    const colorValue = useRgba ? option.rgba : option.hex;
    return (
      <Select.Option key={index} value={colorValue} style={{ padding: 0 }}>
        <ColorItem color={colorValue}>
          {option.name?.replace(/-/g, " ")}
        </ColorItem>
      </Select.Option>
    );
  });

export const ColorSelect: React.FC<PassProps> = ({
  defaultValue,
  onChange,
  useRgba,
}: PassProps) => {
  const { config: globalConfig } = useConfig("global-colors");

  const [currentColor, setCurrentColor] = useState(defaultValue || "#fff");
  const availableColors = globalConfig?.colors || [];

  return (
    <StyledSelect
      style={{ width: "100%" }}
      defaultValue={defaultValue}
      onChange={(color: string) => {
        onChange(color);
        setCurrentColor(color);
      }}
      currentcolor={currentColor}
    >
      {selectOptions(availableColors, useRgba)}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  ${(props: { currentcolor: string }) =>
    props.currentcolor &&
    `


    .ant-select-selection-selected-value {
      overflow: visible;

      > div {
        border: none;
        display: block;
        height: auto;
        margin-left: -11px;
        overflow: hidden;
        padding: 0;
        padding-left: ${LAYOUT.GUTTER * 2}px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `}
`;

export const ColorItem = styled.div`
  ${(props: { color: string }) => `
    align-items: center;
    border-bottom: 1px solid #efefef;
    display: flex;
    height: 32px;
    padding-left: ${LAYOUT.GUTTER * 2}px;
    position: relative;
    text-transform: capitalize;

    &:before {
      background: ${props.color?.replace("0x", "#")};
      border-right: 1px solid #d9d9d9;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 32px;
    }
  `}
`;
