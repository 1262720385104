import React, { ReactNode, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DropTargets = {
  contentModule: "contentModule",
  link: "link",
};

/*
 * Returns a new, sorted array
 */
export function resortItems(items: string[], from: number, to: number) {
  // splice is mutable and doesn't return the array, so we create a clone to work off of
  const clone = [...items];

  // splice does return any deleted items, so we can grab our temporarily ejected value(s) (as an array of items)
  const item = clone.splice(from, 1);

  // finally, we can use the append feature to go to the new index, remove 0 items, and then add our first item
  clone.splice(to, 0, item[0]);

  return clone;
}

/*
 * This is our sort context, which is used in conjunction with `DndProvider` (also a context)
 * to provide more options to make sorting easier and add additional UI feedback for users
 */
const Context = React.createContext<{
  items: string[];
  onSort: (items: string[]) => void;
  onDragItemUpdate: (sortable: string | null) => void;
  dragItem: string | null;
}>({
  items: [],
  onSort: () => undefined,
  onDragItemUpdate: () => undefined,
  dragItem: null,
});

/*
 * A custom hook that returns
 * - drag and drop refs
 * - the state of the item (ie: is it being dragged)
 * - the overall drag and drop context
 *
 * Each sortable list is wrapped in the <Sortable /> component which sets up the context
 */
export function useSortable(id: string, type: string) {
  const context = useContext(Context);
  const { onSort, items, onDragItemUpdate } = context;

  function findItem() {
    return items.findIndex((originalId) => originalId === id);
  }

  function moveItem(id: string, newIndex: number) {
    const index = items.findIndex((originalId) => originalId === id);

    onSort(resortItems(items, index, newIndex));
  }

  const [{ isDragging }, drag] = useDrag({
    type,
    item: {
      id,
      index: findItem(),
    },
    collect: function (monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: function (_, monitor) {
      const { id, index } = monitor.getItem();
      const didDrop = monitor.didDrop();

      if (!didDrop) {
        moveItem(id, index);
      }
    },
  });

  const [, drop] = useDrop({
    accept: type,
    hover: function ({ id: draggedId }: { id: string }) {
      if (draggedId !== id) {
        const overIndex = findItem();

        moveItem(draggedId, overIndex);
      }
    },
  });

  useEffect(
    function () {
      if (isDragging) {
        onDragItemUpdate(id);
      } else {
        onDragItemUpdate(null);
      }
    },
    [isDragging]
  );

  return {
    dragTarget: (node: HTMLDivElement) => drag(drop(node)),
    dropTarget: drop,
    isDragging: context.dragItem === id,
    context,
  };
}

/*
 * <Sortable />
 * required to wrap the sortable list
 */
interface SortableProps {
  children: ReactNode;
  items: string[];
  onSort: (items: string[]) => void;
}

export function Sortable({ children, items, onSort }: SortableProps) {
  const [dragItem, setDragItem] = useState<string | null>(null);

  return (
    <DndProvider backend={HTML5Backend}>
      <Context.Provider
        value={{
          items,
          onSort,
          dragItem,
          onDragItemUpdate: (id) => setDragItem(id),
        }}
      >
        {children}
      </Context.Provider>
    </DndProvider>
  );
}

/*
 * <SortableItem />
 * Pre-composed component to handle dragging states add a drag handle to a sortable list
 */
interface ItemProps {
  id: string;
  children: ReactNode;
  type: string;
}

export function Item({ children, id, type }: ItemProps) {
  const { context, dropTarget } = useSortable(id, type);

  return (
    <ItemContainer ref={dropTarget} isDragging={context.dragItem === id}>
      <DragHandle id={id} type={type} />

      <ItemContents>{children}</ItemContents>
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;

  ${({ isDragging }) => `
    background-color: ${isDragging ? "var(--color--blue-900)" : "inherit"};
  `}
`;

const ItemContents = styled.div`
  flex: 1;
`;

/*
 * <DragHandle />
 * Pre-composed component to quickly add a drag handle to a sortable list
 */
interface DragHandleProps {
  id: string;
  type: string;
}

export function DragHandle({ id, type }: DragHandleProps) {
  const { dragTarget } = useSortable(id, type);

  return (
    <Handle ref={dragTarget}>
      <svg width="8" height="13" viewBox="0 0 8 13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3ZM1.5 8C2.32843 8 3 7.32843 3 6.5C3 5.67157 2.32843 5 1.5 5C0.671573 5 0 5.67157 0 6.5C0 7.32843 0.671573 8 1.5 8ZM3 11.5C3 12.3284 2.32843 13 1.5 13C0.671573 13 0 12.3284 0 11.5C0 10.6716 0.671573 10 1.5 10C2.32843 10 3 10.6716 3 11.5ZM6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8ZM8 1.5C8 2.32843 7.32843 3 6.5 3C5.67157 3 5 2.32843 5 1.5C5 0.671573 5.67157 0 6.5 0C7.32843 0 8 0.671573 8 1.5ZM6.5 13C7.32843 13 8 12.3284 8 11.5C8 10.6716 7.32843 10 6.5 10C5.67157 10 5 10.6716 5 11.5C5 12.3284 5.67157 13 6.5 13Z"
          fill="currentColor"
        />
      </svg>
    </Handle>
  );
}

const Handle = styled.div`
  cursor: grab;

  border-radius: 0.25rem;
  color: var(--color--blue-400);
  flex: none;
  padding: 0.25rem;
  transition: all 0.2s ease;

  & svg {
    display: block;
  }
`;
