import React from "react";
import { Input } from "antd";

interface Props {
  placeholder?: string;
  onChange: (value: string) => void;
  id?: string;
  value?: string;
}

export const Textarea = (props: Props) => {
  return (
    <Input.TextArea
      {...props}
      rows={3}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
};
