// Actions
import { TOGGLE_RULE_FILTER, TOGGLE_RULE_MODAL } from "./actions";

// Const
import { ruleFiltersOptions } from "constants/crossSell/filters";

// Model
import { CrossSellGlobal } from "./types";

const initialState: CrossSellGlobal = {
  ruleModalOpen: false,
  ruleModalEditing: undefined,
  ruleModalReadOnly: false,
  ruleFilter: ruleFiltersOptions[0],
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case TOGGLE_RULE_MODAL: {
      return {
        ...state,
        ruleModalOpen: action.open,
        ruleModalEditing: action.editing,
        ruleModalReadOnly: action.readOnly,
      };
    }
    case TOGGLE_RULE_FILTER: {
      return {
        ...state,
        ruleFilter: action.filter,
      };
    }
    default:
      return state;
  }
}
