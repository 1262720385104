import { AxiosResponse } from "axios";
import API from "constants/api_env";
import { ConfigLabel, ConfigModel } from "redux/configurations/types";
import httpService from "services/generic.service";
import { AccessToken } from "storage/token";

export function fetchConfig(
  label: ConfigLabel,
  schemaVersion: number,
  organizationId: number
): Promise<AxiosResponse<ConfigModel>> {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "GET",
    url: `${API.scheme}${API.url}/schema/v${schemaVersion}/config/label/${label}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
      "X-Organization-Id": organizationId,
    },
  });
}

export function updateConfig(
  label: ConfigLabel,
  schemaVersion: number,
  organizationId: number,
  data: Record<string, unknown> | object
) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "POST",
    url: `${API.scheme}${API.url}/schema/v${schemaVersion}/config/label/${label}`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
      "X-Organization-Id": organizationId,
    },
    data,
  });
}

export function fetchLatestSchema(label: ConfigLabel, organizationId: number) {
  const { token_type, access_token } = AccessToken.get();

  return httpService({
    method: "GET",
    url: `${API.scheme}${API.url}/schema/label/${label}/latest`,
    headers: {
      Authorization: `${token_type} ${access_token}`,
      "Content-Type": "application/json",
      "X-Organization-Id": organizationId,
    },
  });
}
