import * as React from "react";

// Styles
import { StyledInput } from "./styles";
import { Error } from "../styles";

const Input = ({
  disabled,
  type,
  children,
  addonAfter,
  addonBefore,
  placeholder,
  size,
  meta: { touched, error },
  input: { onChange, value, name },
}) => (
  <div style={{ position: "relative", paddingBottom: 25 }}>
    <StyledInput
      size={size}
      placeholder={placeholder}
      id={name}
      type={type}
      value={value}
      disabled={disabled}
      onChange={onChange}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
    >
      {children}
    </StyledInput>
    <Error>{touched && error}</Error>
  </div>
);

export default Input;
