export const REGEX = {
  PHONE: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  SLUG: /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/,
  URL: /^(http|https):\/\/[^ "]+$/,
  HEXCODE: /^#[a-f0-9]{6}$/i,
  FOUR_DIGIT_NUM_CODE: /^[0-9]{4}$/,
  ALPHANUMERIC_13: /^[a-z0-9]{13}$/i,
  PAGE_SLUG: /[(\w)?(\-)?]/g,
};

export const PASSWORD = {
  LENGTH: 8,
};

export const TAGS_VALIDATION = {
  LENGTH: 30,
};

export const VALIDATION_STRINGS = {
  REQUIRED: "Required",
  EMAIL: "Invalid email address",
};
