import { SDK } from "services/koala.service";

/**
 * AccessToken is an interface for saving and interacting with the user's
 * access token.
 */
class Token {
  /**
   * Responsible for holding the access tokens information.
   *
   * @type {{access_token: string, token_type: string, expires_in: number, refresh_token: string, retrieved_at: number}}
   */
  token;

  /**
   * Constructor
   */
  constructor() {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    this.token = SDK.token.get();
  }

  /**
   * Checks if the access token has expired.
   *
   * @returns {boolean}
   */
  isExpired() {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    return !SDK.token.validToken();
  }

  /**
   * Checks if the access token is NOT expired.
   *
   * @returns {boolean}
   */
  isNotExpired() {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    return SDK.token.validToken();
  }

  /**
   * Checks if the access token is NOT null.
   *
   * @returns {boolean}
   */
  isNotNull() {
    return this.token.access_token !== "";
  }

  /**
   * Checks if the access token is an authenticated token.
   *
   * @returns {boolean}
   */
  isAuthenticated() {
    return this.isNotNull() && this.isNotExpired();
  }

  /**
   * Calculates the expired at time for the access token by adding the
   * retrieved_at and expires_in properties.
   *
   * @returns {number}
   */
  expiredAt() {
    return this.token.retrieved_at + this.token.expires_in;
  }

  /**
   * Get the token object.
   *
   * @returns {this.token}
   */
  get() {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    return SDK.token.get();
  }

  get accessToken() {
    return this.token.access_token;
  }

  get refreshToken() {
    return this.token.refresh_token;
  }

  get createdAt() {
    return this.token.retrieved_at;
  }

  get expiresIn() {
    return this.token.expires_in;
  }

  get scopes() {
    if (this.token.scopes) {
      return this.token.scopes;
    }
    return null;
  }

  get tokenType() {
    return this.token.token_type;
  }

  /**
   * Sets the token property, and runs a call back which by default will
   * persist the token to Storage.
   *
   * @param {Object}   token - The object representing the token.
   * @param {Function} cb    - The callback function to run after setting the
   *                           token. By default the callback is set to
   *                           persist the token to Storage.
   *
   * @returns {AccessToken}
   */
  set(token, cb = false) {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    this.token = SDK.token.set(token);
    if (cb) {
      // @ts-expect-error This expression is not callable. Type 'Boolean' has no call signatures.
      cb();
    }

    return this;
  }

  /**
   * Removes the token.
   */
  destroy() {
    // @ts-expect-error Property 'token' is private and only accessible within class 'KoalaSDK'
    SDK.token.destroy();
  }
}

/**
 * Apps AccessToken.
 *
 * @type {AccessToken}
 */
export const AccessToken = new Token();
export { Token };
