import React, { useState } from "react";
import AssetManager, { ImageAsset } from "components/assets/manager";
import styled from "styled-components";
import { PlusOutlined } from "@ant-design/icons";

interface Props {
  onChange: (value: ImageAsset) => void;
  onRemove: () => void;
  value?: ImageAsset;
  height?: number;
}

export const Image = ({ onChange, onRemove, value, height = 160 }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!value?.url && (
        <Add title="Add image" onClick={() => setShowModal(true)}>
          <PlusOutlined />
        </Add>
      )}

      {value?.url && (
        <ImagePreview
          height={height}
          onClick={() => setShowModal(true)}
          src={value.url}
          title="Change image"
        />
      )}

      {showModal && (
        <AssetManager
          modalType="image"
          visible={showModal}
          hasImage={Boolean(value?.url)}
          onSelect={(value: ImageAsset) => {
            onChange(value);
            setShowModal(false);
          }}
          onRemove={onRemove}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

const ImagePreview = styled.img`
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;

  gap: 0.25rem;
  justify-content: center;
  object-fit: cover;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    opacity: 0.75;
  }
`;

const Add = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid var(--color--black-800);
  border-radius: 0.375rem;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 2rem;
  gap: 0.25rem;
  justify-content: center;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    border-color: var(--color--blue-400);
    color: var(--color--blue-400);
  }
`;
