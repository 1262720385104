import { put, call, takeLatest } from "redux-saga/effects";
import { message } from "antd";
import { push } from "connected-react-router";

// Constants
import { ROUTES } from "constants/routes";

// Services
import {
  fetchPersistentTemplates,
  fetchPersistentTemplate,
  fetchPersistentTemplateByAlias,
  updatePersistentTemplate,
  createPersistentTemplate,
} from "services/persistentTemplates.service";

// Actions
import actions from "./actions";
import { FixMeAny } from "types/fix-me-any";

export function* fetchPersistentTemplatesSaga(action) {
  try {
    const response = yield call(fetchPersistentTemplates, action.magicBox);

    yield put({
      type: actions.PERSISTENT_TEMPLATES_SUCCESS,
      list: response.data.data,
      meta: response.data.meta,
      magicBox: action.magicBox,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.PERSISTENT_TEMPLATES_FAIL });
  }
}

export function* fetchPersistentTemplateSaga(action) {
  try {
    const response = yield call(fetchPersistentTemplate, action.id);

    yield put({
      type: actions.PERSISTENT_TEMPLATE_SUCCESS,
      detail: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.PERSISTENT_TEMPLATES_FAIL });
  }
}

export function* fetchPersistentTemplateByAliasSaga(action) {
  try {
    const response = yield call(
      fetchPersistentTemplateByAlias,
      action.orgId,
      action.alias
    );

    yield put({
      type: actions.PERSISTENT_TEMPLATE_SUCCESS,
      detail: response.data,
    });
  } catch (error) {
    yield put({ type: actions.PERSISTENT_TEMPLATES_FAIL });
  }
}

export function* updatePersistentTemplateSaga(action) {
  try {
    yield call(updatePersistentTemplate, action.payload, action.id);

    yield call(message.success, "Module successfully updated.");

    // Push to list
    // TODO - temp workaround
    if (!action.alias) {
      // Push to modules list
      yield put(push(ROUTES.GLOBAL_MODULES.BASE));
    } else {
      // Else refetch persistent template
      yield put(
        actions.fetchPersistentTemplateByAlias(action.orgId, action.alias)
      );
    }
  } catch (error) {
    yield put({ type: actions.PERSISTENT_TEMPLATES_FAIL });
  }
}

export function* createPersistentTemplateSaga(action) {
  try {
    yield call(createPersistentTemplate, action.payload);
    yield call(message.success, "Module successfully created.");

    // Push to list
    if (!action.noRedirect) {
      yield put(push(ROUTES.GLOBAL_MODULES.BASE));
    } else {
      window.location.reload();
    }
  } catch (error: FixMeAny) {
    yield put({ type: actions.PERSISTENT_TEMPLATES_FAIL });
    if (error.response.status === 409) {
      yield call(
        message.error,
        `${error.response.data.error_description} Please create a module with unique label`
      );
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(
    actions.FETCH_PERSISTENT_TEMPLATES,
    fetchPersistentTemplatesSaga
  );
  yield takeLatest(
    actions.FETCH_PERSISTENT_TEMPLATE,
    fetchPersistentTemplateSaga
  );
  yield takeLatest(
    actions.FETCH_PERSISTENT_TEMPLATE_BY_ALIAS,
    fetchPersistentTemplateByAliasSaga
  );
  yield takeLatest(
    actions.UPDATE_PERSISTENT_TEMPLATE,
    updatePersistentTemplateSaga
  );
  yield takeLatest(
    actions.CREATE_PERSISTENT_TEMPLATE,
    createPersistentTemplateSaga
  );
}
