import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

// Services
import { fetchActiveDevices } from "services/activeDevices.service";

// Actions
import actions from "./actions";

export function* fetchActiveDevicesSaga(action) {
  try {
    const response = yield call(fetchActiveDevices, action.magicBox);

    yield put(
      actions.activeDevicesSuccess(
        response.data.data,
        response.data.meta,
        action.magicBox
      )
    );
  } catch (error) {
    yield call(message.error, "There was an issue fetching active devices");
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ACTIVE_DEVICES, fetchActiveDevicesSaga);
}
