export interface Page {
  id: string;
  title: string;
  slug: string;
  createdAt: string;
  status: string;
  homepage: boolean;
  content: object;
  excludeFromSitemap: boolean;
  seoTitle: string;
  seoDescription: string;
  seoImage: object;
  __typename: string;
}

export enum PageStatus {
  draft = "draft",
  published = "publish",
}
