import { connectRouter } from "connected-react-router";
import { Action, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import activeDevices from "./activeDevices/reducer";
import allergens from "./allergens/reducer";
import assets from "./assets/reducer";
import auth from "./auth/reducer";
import categories from "./_deprecated/categories/reducer";
import configurations from "./configurations/reducer";
import crossSell from "./crossSell/reducer";
import crossSellCart from "./crossSellCart/reducer";
import crossSellGlobal from "./crossSellGlobal/reducer";
import customers from "./customers/reducer";
import deviceConfiguration from "./_deprecated/deviceConfiguration/reducer";
import global from "./global/reducer";
import locationStats from "./locationStats/reducer";
import me from "./me/reducer";
import modules from "./_deprecated/modules/reducer";
import organization from "./organization/reducer";
import orgConfig from "./orgConfig/reducer";
import pages from "./pages/reducer";
import persistentTemplates from "./_deprecated/persistentTemplates/reducer";
import revisions from "./revisions/reducer";
import tags from "./tags/reducer";
import users from "./users/reducer";
import globalProducts from "./globalMenu/reducer";
import globalProductModifiers from "./globalProductModifiers/reducer";
import locationAttributePositions from "./locationAttributePositions/reducer";
import locations from "./locations/reducer";
import authActions from "./auth/actions";

export interface State {
  app: any;
  form: any;
  router: any;
}

const appReducer = combineReducers({
  auth,
  activeDevices,
  configurations,
  crossSell,
  crossSellCart,
  crossSellGlobal,
  global,
  users,
  customers,
  me,
  pages,
  categories,
  assets,
  modules,
  revisions,
  persistentTemplates,
  locations,
  locationStats,
  locationAttributePositions,
  globalProducts,
  globalProductModifiers,
  deviceConfiguration,
  allergens,
  orgConfig,
  organization,
  tags,
});

// Combines all reducers to a single reducer function
const rootReducer = (history) =>
  combineReducers<State>({
    router: connectRouter(history),
    form: formReducer,
    app: (state: ReturnType<typeof appReducer>, action: Action) => {
      //Reset the state of ALL application-related reducers on logout
      return appReducer(
        action.type !== authActions.LOGOUT ? state : undefined,
        action
      );
    },
  });

export default rootReducer;
